import { NavMenuStaticType } from "./types"
import { ROUTES } from "@/utils/constants"

export const menuItems: NavMenuStaticType[] = [
  {
    title: "Каталог",
    path: ROUTES.catalog,
    isAuthGuard: false,
    showInPopupOnly: true,
  },
  {
    title: "Избранное",
    path: ROUTES.favorites,
    isAuthGuard: true,
    showInPopupOnly: true,
  },
  {
    title: "Сравнение",
    path: ROUTES.compares,
    isAuthGuard: true,
    showInPopupOnly: true,
  },
  { title: "О компании", path: ROUTES.about, isAuthGuard: false },
  { title: "Как купить", path: ROUTES.help, isAuthGuard: false },
  { title: "Магазины", path: ROUTES.stores, isAuthGuard: false },
  { title: "Контакты", path: ROUTES.contacts, isAuthGuard: false },
  {
    title: "История заказов",
    path: `${ROUTES.account}${ROUTES.historyAccount}`,
    isAuthGuard: true,
  },
  {
    title: "FAQ",
    path: "",
    isLink: false,
    subItems: [
      { title: "Помощь", path: ROUTES.help },
      { title: "Вопрос-ответ", path: ROUTES.faq },
      { title: "Условия оплаты", path: ROUTES.paymentTerm },
      { title: "Условия доставки", path: ROUTES.deliveryTerm },
      { title: "Гарантия на товар", path: ROUTES.productWarranty },
    ],
  },
]
