import { forwardRef } from "react"
import NextLink from "next/link"
import { ButtonContainer } from "@/ui/Button/ButtonContainer"
import { matching } from "@/ui/Button/constants"
import type { ButtonProps } from "./types"

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ shallow = true, ...props }, ref) => {
    const { as, href, variant = "default" } = props
    const isLink = as === "a" && href !== undefined

    const element = (
      <ButtonContainer
        ButtonComponent={matching[variant]()}
        {...props}
        ref={ref}
      />
    )

    if (isLink) {
      return (
        <NextLink href={href || ""} passHref shallow={shallow}>
          {element}
        </NextLink>
      )
    }

    return <>{element}</>
  },
)

Button.displayName = "Button"

export { Button }
