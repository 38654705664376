import { CART_STATUS } from "@/hooks/cart/constants"
import { deleteFetch, get, post, put } from "@/service/fetcher"
import {
  ChangeQtyProductResponse,
  ChangeQtyResponseClient,
  ChangeQtySampleResponse,
  FreeShippingReturnType,
} from "@/types"
import {
  CartResponse,
  ChangeQtyRequest,
  DeleteProductRequest,
  FastOrderRequest,
} from "../../contracts"

export const fetchChangeQtyProduct = (
  changeQtyData: ChangeQtyRequest,
): Promise<ChangeQtyProductResponse> =>
  post<ChangeQtyProductResponse, ChangeQtyRequest>(
    "/cart/change-qty",
    changeQtyData,
    true,
  )

export const fetchCart = (tokenCart: string): Promise<CartResponse> => {
  return get<CartResponse>(`/cart/${tokenCart}`, true)
}

export const fetchRemoveProduct = (
  request: DeleteProductRequest,
): Promise<ChangeQtyResponseClient> =>
  deleteFetch<ChangeQtyResponseClient, DeleteProductRequest>(
    `/cart/delete-product`,
    request,
    true,
  )

export const fetchChangeQtySample = (
  productData: ChangeQtyRequest,
): Promise<ChangeQtySampleResponse> =>
  post<ChangeQtySampleResponse, ChangeQtyRequest>(
    "/cart/sample",
    productData,
    true,
  )

export const fetchClearCart = (data: { uid: string }): Promise<boolean> =>
  put<boolean, { uid: string }>(`/cart/remove/${data.uid}`, data, true)

export const fetchRemoveCart = (data: { uid: string }): Promise<boolean> =>
  deleteFetch<boolean, { uid: string }>(`/cart/remove/${data.uid}`, data, true)

export const fetchBindUserCart = (data: { cart: string }): Promise<boolean> =>
  put(`/cart/${data.cart}/bind-user`, null, true)

export const fetchUpdateStatusCart = ({
  uid,
  status,
}: {
  uid: string
  status: CART_STATUS
}): Promise<{ state: number } | null> =>
  put<
    { state: number } | null,
    {
      state: CART_STATUS
    }
  >(`/cart/${uid}/status`, { state: status }, true)

export const fetchMergeUserCart = (data: {
  cart: string
}): Promise<{ cart: string }> => get(`/cart/${data.cart}/merge`, true)

export const fetchFastOrder = ({
  cart,
  ...data
}: { cart: string } & FastOrderRequest): Promise<{
  number: string
}> => put(`/fast-order/${cart}`, data, true)

export const fetchFreeShipping = (
  region: string,
): Promise<FreeShippingReturnType> => get(`/free-shipping?region=${region}`)
