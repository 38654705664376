import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FavoritesResponseType } from "@/api/favoritesAPI"
import {
  getDefaultFromGroups,
  getFavoritesGeneralProductsFormatted,
} from "@/hooks/favorites"
import { createFiltersChecked } from "@/hooks/filterCatalog/helpers"
import { StatePriceRangeType } from "@/store/reducers/catalogSlice"
import {
  FilterType,
  QueryFavoritesStringType,
  SortByCatalogAlias,
  SortByType,
} from "@/types"
import { compareSortTypesWithIcon, matchKeys } from "@/utils/common/helpers"
import { SortTypeResponse } from "../../../contracts"

export const INITIAL_SORT: SortByCatalogAlias = SortByCatalogAlias.BY_NAME

const initialState = {
  keys: null as string[] | null,
  groups: null as { name: string; uid: string; quantity: number }[] | null,
  groupsProductsIds: {} as Record<string, string[]>,
  toggleProgress: [] as string[] | [],
  filter: {
    filters: {} as Record<string, FilterType>,
    filtersChecked: {} as Record<string, string[]>,
    priceRange: {
      initial: {
        min: 0,
        max: 0,
      },
      // текущая, выбранная пользователем
      // или пришедшая из запроса фильтрованного каталога
      checked: {
        min: 0,
        max: 0,
      },
      isSelected: false,
    } as StatePriceRangeType,
    sortBy: null as SortByType,
    sortBySelected: null as SortByCatalogAlias | null,
    selectedGroup: null as string | null,
  },
}

export const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    addToFavorites(
      state,
      {
        payload: { products, group },
      }: PayloadAction<{ products: string[]; group?: string }>,
    ) {
      const productIds = matchKeys({
        stateKeys: state.keys,
        newKeys: products,
      })

      let groups = state.groups ?? []

      state.keys = productIds

      if (!!group) {
        state.groupsProductsIds[group] = productIds
        groups = groups.map((g) =>
          g.uid !== group ? g : { ...g, quantity: productIds.length },
        )
      }

      const defaultGroup = getDefaultFromGroups(state.groups)
      if (!!defaultGroup) {
        const { uid } = defaultGroup

        state.groupsProductsIds[uid] = productIds
        groups = groups.map((g) =>
          g.uid !== uid ? g : { ...g, quantity: productIds.length },
        )
      }

      state.groups = groups
    },
    removeFromFavorites(state, { payload: product }: PayloadAction<string>) {
      if (state.keys === null) {
        return
      }

      const filtered = state.keys.filter((item: string) => item !== product)

      state.keys = !!filtered.length ? filtered : null

      state.groupsProductsIds = Object.entries(state.groupsProductsIds).reduce(
        (updatedGroups: Record<string, string[]>, [groupId, data]) => {
          const filtered = data.filter((item: string) => item !== product)
          return { ...updatedGroups, [groupId]: filtered }
        },
        {},
      )
    },
    clearFavorites(state) {
      state.keys = []
      state.groupsProductsIds = {}
      state.groups = null
      state.toggleProgress = []
    },
    toggleFavoritesProgress(
      state,
      action: PayloadAction<{ isFetching: boolean; product: string }>,
    ) {
      state.toggleProgress = action.payload.isFetching
        ? [...state.toggleProgress, action.payload.product]
        : state.toggleProgress.filter((id) => id !== action.payload.product)
    },
    setFavoritesGroups(
      state,
      action: PayloadAction<{ name: string; uid: string; quantity: number }[]>,
    ) {
      state.groups = action.payload
    },
    setSortBy(state, action: PayloadAction<SortTypeResponse>) {
      state.filter.sortBy = compareSortTypesWithIcon(action.payload)
    },
    setFavorites(
      state,
      {
        payload: {
          data: { products, priceRange, filters },
          requestOptions,
        },
      }: PayloadAction<{
        requestOptions: Required<QueryFavoritesStringType>
        data: FavoritesResponseType
      }>,
    ) {
      const { group, params, price, sortby } = requestOptions

      const { keys, groupsProductsIds } =
        getFavoritesGeneralProductsFormatted.call(
          {
            initialGroups: { ...state.groupsProductsIds },
            initialKeys: [...(state.keys || [])],
          },
          {
            products,
          },
        )
      state.keys = keys
      state.groupsProductsIds = groupsProductsIds

      const favoritesPrice = {
        min: priceRange?.min || 0,
        max: priceRange?.max || 0,
      }

      state.filter.priceRange.initial = favoritesPrice
      state.filter.priceRange.checked = favoritesPrice
      // здесь считаем активированным фильтр цены, если он есть в url
      state.filter.priceRange.isSelected = !!price

      const favoritesFilters = filters.reduce((obj, filter) => {
        return { ...obj, [filter.uuid || ""]: { ...filter } }
      }, {})
      const checked = createFiltersChecked({
        filters: favoritesFilters,
        queryParams: [params]
          .join(",")
          .split(",")
          .filter((i) => !!i),
      })

      state.filter.filters = favoritesFilters
      state.filter.filtersChecked = checked

      if (!!sortby) {
        state.filter.sortBySelected = sortby
      }

      if (!!group) {
        state.filter.selectedGroup = group
      }
    },
    setGeneralFavorites(
      state,
      {
        payload: { products },
      }: PayloadAction<Pick<FavoritesResponseType, "products">>,
    ) {
      const { keys, groupsProductsIds } =
        getFavoritesGeneralProductsFormatted.call(undefined, {
          products,
        })

      state.keys = keys
      state.groupsProductsIds = groupsProductsIds
    },
  },
})

export const {
  clearFavorites,
  setFavoritesGroups,
  removeFromFavorites,
  toggleFavoritesProgress,
  addToFavorites,
  setSortBy,
  setFavorites,
  setGeneralFavorites,
} = favoritesSlice.actions
