import { ForwardedRef, forwardRef } from "react"
import { FieldProps } from "@/types"
import { IAutoComplete, InputField } from "./InputField"
import { TextareaField } from "./TextareaField"

export const Field = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  FieldProps & IAutoComplete
>(({ variant = "input", autoCompleteContent, ...props }, ref) => {
  switch (variant) {
    case "input":
      return (
        <InputField
          variant={variant}
          {...props}
          ref={ref as ForwardedRef<HTMLInputElement>}
          autoCompleteContent={autoCompleteContent}
        />
      )
    case "textarea":
      return (
        <TextareaField
          {...props}
          ref={ref as ForwardedRef<HTMLTextAreaElement>}
        />
      )
    default:
      return <></>
  }
})

Field.displayName = "Field"
