import { useCallback, useState } from "react"

type ReturnUseClipboardCopyType = {
  isCopied: boolean
  handleCopyClick: (copyText: string) => void
}
type UseClipboardCopyType = () => ReturnUseClipboardCopyType

export const useClipboardCopy: UseClipboardCopyType = () => {
  const [isCopied, setIsCopied] = useState<boolean>(false)

  // This is the function we wrote earlier
  async function _copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand("copy", true, text)
    }
  }

  const handleCopyClick = useCallback((copyText: string) => {
    _copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true)

        setTimeout(() => {
          setIsCopied(false)
        }, 1500)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return {
    isCopied,
    handleCopyClick,
  }
}
