import {
  INITIAL_IS_ENABLED,
  INITIAL_IS_FAST,
  INITIAL_PAGE,
  INITIAL_PER_PAGE,
  INITIAL_SORT,
  MANUAL_FILTER_KEYS,
  TogglePageMethod,
} from "@/hooks/filterCatalog/constants"
import { ShopType } from "@/hooks/shops/types"
import {
  CatalogRequestType,
  FilterParamType,
  FilterType,
  GetOrderFilterSortableType,
  QueryCatalogType,
} from "@/types"
import {
  getEntityBasePath,
  getOrderSortAsc,
  getOrderSortDesc,
  getUnique,
} from "@/utils/common/helpers"
import { EMPTY_DATA_PLACEHOLDER, NBSP_SYMBOL, ROUTES } from "@/utils/constants"

export const createFiltersFromShops = ({
  shops,
}: {
  shops: Record<string, ShopType>
}): Record<string, FilterType> => ({
  [MANUAL_FILTER_KEYS.store]: {
    uuid: MANUAL_FILTER_KEYS.store,
    name: "Склады",
    order: 0,
    values: Object.entries(shops).reduce(
      (o, [, { uuid, address }]) => ({
        ...o,
        [uuid]: {
          name: address || EMPTY_DATA_PLACEHOLDER,
          image: "",
          uuid: uuid,
          product_qty: undefined,
        } as FilterParamType,
      }),
      {},
    ),
  },
})
export const createFiltersChecked = ({
  queryParams,
  filters,
}: {
  queryParams: string[]
  filters: Record<string, FilterType>
}) => {
  const filtersChecked: Record<string, string[]> = {}

  if (!(queryParams.length > 0 && Object.keys(filters).length > 0)) {
    return filtersChecked
  }

  for (const [uuidFilter, filter] of Object.entries(filters)) {
    const valuesKeys = Object.values(filter.values).map((v) => v.uuid)
    const availableKeys = queryParams.filter((q) => valuesKeys.includes(q))
    if (availableKeys.length > 0) {
      filtersChecked[uuidFilter] = availableKeys
    }
  }

  return filtersChecked
}

export const convertBoolToStrNum = (v?: boolean) => (!!v ? "1" : "0")
export const convertStrNumToBool = (v?: "1" | "0" | string) => !!+(v || "")

export const normalizeSearchParamsCatalog = ({
  query: {
    params,
    page,
    per_page,
    categories,
    bestseller,
    is_enabled,
    is_fast,
    sortby,
    store,
    min_quantity,
    price,
    is_new,
  },
}: {
  query: QueryCatalogType
}): Required<CatalogRequestType> => {
  const { tag, category } = parseQueryCategories(categories || [])

  return {
    params: String(params || ""),
    page: String(page || INITIAL_PAGE),
    per_page: String(per_page || INITIAL_PER_PAGE),
    categories: category,
    bestseller: !!bestseller ? validateBooleanString(bestseller) : "",
    is_enabled: !!is_enabled
      ? validateBooleanString(is_enabled)
      : String(INITIAL_IS_ENABLED),
    is_fast: !!is_fast
      ? validateBooleanString(is_fast)
      : String(INITIAL_IS_FAST),
    sortby: String(sortby ?? INITIAL_SORT),
    store: String(store || ""),
    min_quantity: String(min_quantity || ""),
    price: String(price || ""),
    is_new: !!is_new ? validateBooleanString(is_new) : String(false),
    tag: tag,
  }
}

export const getFiltersCheckedParams: (
  filtersChecked: Record<string, string | string[]>,
  manualFiltersKeys?: typeof MANUAL_FILTER_KEYS | null,
) => string[] = (
  filtersChecked: Record<string, string | string[]>,
  manualFiltersKeys = MANUAL_FILTER_KEYS,
) =>
  getUnique(
    Object.entries(filtersChecked).reduce(
      (arr: string[], [uuidFilter, params]) =>
        !!manualFiltersKeys && Object.hasOwn(manualFiltersKeys, uuidFilter)
          ? arr
          : [...arr, ...params],
      [],
    ),
  )

const getNameFilterSortable = (str?: string): number[] => {
  const reg = new RegExp(/[\d\.]+/g)
  const num = (str || "")
    .replaceAll(",", ".")
    .replaceAll(" ", "")
    .replaceAll(NBSP_SYMBOL, "")
    .trim()
  const numArr = num.match(reg)

  return !!numArr ? numArr.filter((n) => n !== ".").map((n) => +n) : []
}
export const getOrderFilterSortable: GetOrderFilterSortableType = (
  a = "",
  b = "",
  sort = "asc",
) => {
  /*
   * name может быть след. видов:
   * 30 х 40
   * 20,5 х 24,5
   * 20,5 х 24,5 x 6,5
   * 6,7
   * 7
   * AB
   * Красный
   * Сортировка должна быть по возрастанию
   * */

  const sortMethod = sort === "asc" ? getOrderSortAsc : getOrderSortDesc

  const namesA = getNameFilterSortable(a)
  const namesB = getNameFilterSortable(b)

  const lenA = namesA.length
  const lenB = namesB.length

  let order = 0

  // если в фильтрах нет чисел хотя бы у одного
  if (lenA === 0 || lenB === 0) {
    return sortMethod(a, b)
  }

  // если сравниваемые фильтры содержат несколько групп чисел
  // и потенциально первое сравниваемое может быть одинаковым
  if (lenA === lenB && lenA > 1) {
    let i = 0
    while (i < lenA) {
      order = sortMethod(namesA[i], namesB[i])
      if (order !== 0) {
        break
      }
      i++
    }
  } else {
    order = sortMethod(namesA[0], namesB[0])
  }

  return order
}

export const getIsScrollByTogglePageMethod = (toggle: TogglePageMethod) =>
  toggle === TogglePageMethod.SWITCH

export const getCatalogPath = (alias = "", isAbsolute = false): string =>
  getEntityBasePath(`${ROUTES.catalog}/${alias}`, isAbsolute)

export const getMetaTitleCatalog: (name: string) => string = (name) =>
  `${name} – купить в Волгограде, цена на ${name} оптом и в розницу в интернет-магазине Гростер`

export const getMetaDescriptionCatalog: (
  name: string,
  options?: {
    totalProducts?: number
    lowPrice?: number
  },
) => string = (name, { totalProducts = 0, lowPrice = 0 } = {}) => {
  const pricePart = lowPrice <= 0 ? "" : `⭐ по цене от ${lowPrice ?? 0} руб.`
  const totalPart = totalProducts <= 0 ? "" : `${totalProducts ?? 0} товаров`

  return [
    `Купить ${name} в Волгограде в интернет-магазине Гростер`,
    pricePart,
    `✅ Доставка по России, качественный сервис ✅ ${name}${
      totalProducts <= 0 ? "" : ":"
    }`,
    totalPart,
    `в каталоге товаров для бизнеса`,
  ]
    .filter((item) => !!item)
    .join(" ")
}

export const getMetaTitlePagedCatalog = ({
  title = "",
  page,
  separator = "–",
}: {
  title: string
  page?: string | number
  separator?: string
}) => {
  const paged = page ? `страница ${page}` : ""
  return {
    title: [title, paged].filter((item) => !!item).join(` ${separator} `),
    paged,
  }
}

export const getMetaDescriptionPagedCatalog = getMetaTitlePagedCatalog

export const parseQueryCategories = (
  queryCategories?: string[],
): {
  category: string
  tag: string
} => {
  const categories = queryCategories || []
  return {
    category: categories[0] || "",
    tag: categories[1] || "",
  }
}

export const getStringToBoolean = (value: string) => value === "true"
export const validateBooleanString = (value: string) =>
  ["true", "false"].includes(value) ? value : "false"
