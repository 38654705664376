import { type FC } from "react";
import { IMAGE_CONFIG } from "@/components/Products";
import { Button } from "@/components/ui/Button/Button";
import { Typography } from "@/components/ui/Typography/Typography";
import { NOTIFICATION_KIND } from "@/hooks/notification/constants";
import { useNotifications } from "@/hooks/notification/notification";
import { EntityImage } from "@/ui/EntityImage/EntityImage";
import { ROUTES } from "@/utils/constants";
import { cssCloseButton, StyledNotification, StyledNotificationContent, StyledNotificationMedia } from "./StyledNotification";
type NotificationPropsType = {
  index: number;
  kind: NOTIFICATION_KIND;
  image?: string;
  list?: string;
};
const getTextFromKind = (kind: NOTIFICATION_KIND, list?: string) => {
  switch (kind) {
    case NOTIFICATION_KIND.FAVORITES:
      return "Товар добавлен в избранное";
    case NOTIFICATION_KIND.FAVORITE_LIST:
      if (!!list) {
        return `Товар добавлен в список «${list}»`;
      } else {
        return "Товар добавлен в список";
      }
    case NOTIFICATION_KIND.COMPARES:
      return "Товар добавлен к сравнению";
    case NOTIFICATION_KIND.CART:
      return "Товар добавлен в корзину";
  }
};
const getHrefFromKind = (kind: NOTIFICATION_KIND) => {
  switch (kind) {
    case NOTIFICATION_KIND.FAVORITES:
      return ROUTES.favorites;
    case NOTIFICATION_KIND.FAVORITE_LIST:
      return ROUTES.favorites;
    case NOTIFICATION_KIND.COMPARES:
      return ROUTES.compares;
    case NOTIFICATION_KIND.CART:
      return ROUTES.cart;
  }
};
const getButtonContentFromKind = (kind: NOTIFICATION_KIND) => {
  switch (kind) {
    case NOTIFICATION_KIND.FAVORITES:
      return "Перейти в избранное";
    case NOTIFICATION_KIND.FAVORITE_LIST:
      return "Перейти в избранное";
    case NOTIFICATION_KIND.COMPARES:
      return "Перейти к сравнению";
    case NOTIFICATION_KIND.CART:
      return "Перейти в корзину";
  }
};
export const Notification: FC<NotificationPropsType> = ({
  index,
  kind,
  image,
  list
}) => {
  const {
    hide
  } = useNotifications();
  return <StyledNotification>
      <Button icon={"X"} seoText="Удалить" className={cssCloseButton} onClick={() => {
      hide({
        index
      });
    }} />

      {!!image && <StyledNotificationMedia>
          <EntityImage imagePath={image} width={IMAGE_CONFIG.list.size.width} height={IMAGE_CONFIG.list.size.height} priority loading={"eager"} />
        </StyledNotificationMedia>}
      <StyledNotificationContent>
        <Typography>{getTextFromKind(kind, list)}</Typography>
        <Button variant="filled" as="a" href={getHrefFromKind(kind)}>
          {getButtonContentFromKind(kind)}
        </Button>
      </StyledNotificationContent>
    </StyledNotification>;
};