import { useQuery } from "react-query"
import { fetchProductsList } from "@/api/productsAPI"

export const useLeadhitProducts = <R extends { offer_id: string }>(
  queryKey: string[],
  fetchFn: () => Promise<R[]>,
) => {
  const { data: productsUUIDs } = useQuery(queryKey, fetchFn, {
    select(data) {
      return data.map(({ offer_id }) => offer_id)
    },
  })

  const { data: products } = useQuery(
    ["products", productsUUIDs],
    () =>
      !!productsUUIDs
        ? fetchProductsList({
            uuids: productsUUIDs.join(",") || "",
          })
        : null,
    {
      enabled: productsUUIDs != null,
    },
  )

  return products
}
