export const CHECKOUT_SAVED_LOCAL_STORAGE_KEY = "checkoutSaved"

export enum PaymentMethod {
  BANK_TRANSFER = "bank_transfer",
  CARD_ONLINE = "card_online",
  CARD_COURIER = "card_courier",
  CASH_COURIER = "cash_courier",
}

export const BUSINESS_AREA_VALUE_DEFAULT = "Другая сфера"
export const COMPARE_FIELDS: Record<
  string,
  "shipping_address_pickup" | "shipping_address_courier" | "shipping_address"
> = {
  courier: "shipping_address_courier",
  pickup: "shipping_address_pickup",
}
