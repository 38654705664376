import { get } from "@/service/fetcher"

const API_KEY = "5VJS6B47PT"
const PRODUCT_SIZE = 10
const REGION_ID = "global"
export const SORT = "DEFAULT"
export const PAGE = 1

export type DigiSortType = "DEFAULT" | "PRICE_ASC" | "PRICE_DESC"

export type DigiStItemType = {
  st: string
  amount: number
}

export type DigiTapItemType = {
  tap: string
  relatedSearch: string
}

export type DigiCategoryTypeAutoComplete = {
  direct: boolean
  id: string
  image_url: string
  link_url: string
  name: string
}

export type DigiAttributeType = Record<string, string[]>

export type DigiProductAutoCompleteType = {
  attributes: DigiAttributeType
  available: boolean
  brand: string
  categories: DigiCategoryTypeAutoComplete[]
  id: string
  image_url: string
  link_url: string
  name: string
  price: string
  score: number
}

export type DigiAutoCompleteResponseType = {
  brands: []
  categories: DigiCategoryTypeAutoComplete[]
  contents: []
  products: DigiProductAutoCompleteType[]
  query: string
  sts: DigiStItemType[]
  taps: DigiTapItemType[]
  correction?: string
}

type DigiAutoCompleteRequestType = {
  query?: string
  size?: number
  regionId?: number | string
  showUnavailable?: boolean
  withContent?: boolean
  withSku?: boolean
}

export type DigiSearchRequestType = Omit<
  DigiAutoCompleteRequestType,
  "withContent"
> & {
  sort?: DigiSortType
  filter?: string
  page?: number
}

export type DigiFacetDataType =
  | "SLIDER"
  | "DISTINCT"
  | "NUM_DISTINCT"
  | "RANGES"
  | "BOOLEAN"

export type DigiFacetValueType = {
  id: string
  name?: string
  value: number
  open: boolean
  selected: boolean
  children: DigiFacetValueType[]
}
export type DigiFacetType = {
  name: string
  dataType: DigiFacetDataType
  unit?: string
  values: DigiFacetValueType[]
}

export type DigiProductSearchType = {
  groupId?: string
  available?: boolean
  score?: number
  ids?: string[]
  id?: string
}

export type DigiSearchResponseType = {
  query: string
  correction?: string
  totalHits: number
  zeroQueries: boolean
  products: DigiProductSearchType[]
  facets?: DigiFacetType[]
  selectedFacets?: DigiFacetType[]
}

export const fetchAutoComplete = ({
  query,
  size = PRODUCT_SIZE,
  regionId = REGION_ID,
  withSku = false,
  withContent = false,
  showUnavailable = true,
}: DigiAutoCompleteRequestType): Promise<DigiAutoCompleteResponseType | null> => {
  const searchParams = new URLSearchParams()

  searchParams.set("st", String(query || ""))
  searchParams.set("apiKey", API_KEY)
  searchParams.set("strategy", "vectors_strict,zero_queries")
  searchParams.set("productsSize", String(size))
  searchParams.set("regionId", String(regionId))
  searchParams.set("forIs", String(true))
  searchParams.set("showUnavailable", String(showUnavailable))
  searchParams.set("withContent", String(withContent))
  searchParams.set("withSku", String(withSku))

  return get<DigiAutoCompleteResponseType | null>(
    `https://autocomplete.diginetica.net/autocomplete?${String(searchParams)}`,
  )
}

export const fetchSearch = ({
  query,
  size = PRODUCT_SIZE,
  regionId = REGION_ID,
  withSku = true,
  showUnavailable = true,
  sort = SORT,
  filter = "",
  page = PAGE,
}: DigiSearchRequestType): Promise<DigiSearchResponseType> => {
  const searchParams = new URLSearchParams()

  searchParams.set("st", String(query || ""))
  searchParams.set("apiKey", API_KEY)
  searchParams.set("strategy", "vectors_strict,zero_queries")
  searchParams.set("fullData", String(true))
  searchParams.set("withCorrection", String(true))
  searchParams.set("useCompletion", String(true))
  searchParams.set("withFacets", String(true))
  searchParams.set("treeFacets", String(true))
  searchParams.set("regionId", String(regionId))
  searchParams.set("useCategoryPrediction", String(true))
  searchParams.set("size", String(size))
  searchParams.set("offset", String((+page - 1) * size))
  searchParams.set("showUnavailable", String(showUnavailable))
  searchParams.set("unavailableMultiplier", String(0.2))
  searchParams.set("withSku", String(withSku))
  searchParams.set("sort", String(sort))
  searchParams.set("preview", String(false))
  searchParams.set("filter", String(filter))

  return get(`https://sort.diginetica.net/search?${String(searchParams)}`)
}
