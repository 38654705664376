import { menuItems } from "@/layouts/Default/Header/NavigationStatic/constants"

export const getMenuItems = ({
  isAuth,
  isPopup = false,
}: {
  isAuth: boolean
  isPopup?: boolean
}) =>
  menuItems.filter(
    ({ isAuthGuard = false, showInPopupOnly = false }) =>
      (isAuthGuard ? isAuth : true) && (isPopup ? true : !showInPopupOnly),
  )
