import type { FC } from "react"
import {
  fetchCart,
  fetchDetailProduct,
  fetchHistoryOrders,
  fetchMain,
  fetchNotFound,
} from "@/api/leadhitAPI"
import { useWindowSize } from "@/hooks/useWindowSize"
import { Container } from "@/styles/utils/StyledGrid"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"
import { TITLES } from "@/utils/constants"
import { useLeadhitProducts } from "./useLeadhitProducts"
import { ConfiguratedSlider } from "../Products/Slider/Configurated"

export const MainPageRecommends: FC = () => {
  const products = useLeadhitProducts(["leadhit", "main"], fetchMain)

  return (
    <Container>
      <ConfiguratedSlider products={products || []} title={TITLES.BEST_DEALS} />
    </Container>
  )
}

export const CartPageRecommends: FC = () => {
  const products = useLeadhitProducts(["leadhit", "cart"], fetchCart)

  return (
    <Container>
      <ConfiguratedSlider products={products || []} title={TITLES.BEST_DEALS} />
    </Container>
  )
}

export const HistoryOrdersPageRecommends: FC = () => {
  const products = useLeadhitProducts(["leadhit", "main"], fetchHistoryOrders)

  return (
    <Container>
      <ConfiguratedSlider products={products || []} title={TITLES.BEST_DEALS} />
    </Container>
  )
}

export const NotFoundPageRecommends: FC = () => {
  const products = useLeadhitProducts(["leadhit", "not-found"], fetchNotFound)

  return (
    <Container>
      <ConfiguratedSlider
        products={products || []}
        title={TITLES.BEST_DEALS}
        slidesPerViewLg={5}
      />
    </Container>
  )
}

export const DetailProductPageRecommends: FC = () => {
  const products = useLeadhitProducts(["leadhit", "detail-product"], () =>
    fetchDetailProduct({
      urlList: [`${location.protocol}//${location.href}`],
    }),
  )
  const { width } = useWindowSize()
  const isMoreSm =
    width !== undefined && width > getBreakpointVal(breakpoints.sm)

  return (
    <ConfiguratedSlider
      titleLeftPadding={isMoreSm}
      products={products || []}
      title={TITLES.BEST_DEALS}
      slidesPerViewLg={2}
      slidesPerViewMd={2}
    />
  )
}
