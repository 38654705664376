import { css, CSSProperties } from "@linaria/core";
import { FC, memo, SVGProps } from "react";
import { colors, IconSize } from "@/styles/utils/vars";
import { IconInner, matching } from "./Icon";
export type IconNameType = keyof typeof matching;
export type IconSizeAliasType = keyof typeof IconSize;
export interface IconPropsType {
  NameIcon: IconNameType;
  fill?: string;
  size?: IconSizeAliasType;
}
export type GetSizeSVGType = (size: (typeof IconSize)[keyof typeof IconSize]) => string | number | CSSProperties;
export const getSizeSVG: GetSizeSVGType = size => {
  return {
    width: size,
    height: size,
    minWidth: size,
    minHeight: size
  };
};
export const cssIcon = "cssIcon_cc7xf1o";
export const cssIconShoppingCart = "cssIconShoppingCart_c1bwwamh";
export const Icon: FC<IconPropsType & SVGProps<SVGSVGElement>> = memo(({
  NameIcon,
  fill,
  size = IconSize.default,
  className,
  ...props
}) => {
  const classNameCalc = `${cssIcon} ${className ? className : ""} ${size ? `${size}` : ""}`;
  const styles: CSSProperties | undefined = fill ? {
    fill: fill
  } : undefined;
  return <IconInner {...props} NameIcon={NameIcon} className={classNameCalc} style={styles} />;
});
Icon.displayName = "Icon";

require("./index.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./index.tsx");