import { fetchLogInstallPWA } from "@/api/pwaAPI"
import {
  convertBoolToStrNum,
  convertStrNumToBool,
} from "@/hooks/filterCatalog/helpers"
import {
  PWA_HAS_SEND_COOKIE_KEY,
  PWA_INSTALLED_COOKIE_KEY,
  PWA_LAST_PROMPT_COOKIE_KEY,
} from "@/hooks/usePWA/constants"
import { cookies, getExpiresByDay } from "@/utils/common/helpers"

export const getExpiresLastPromptPWA = () => getExpiresByDay(30)

export const setIsInstalledPWAToStorage = (data: boolean): void => {
  cookies.set(
    PWA_INSTALLED_COOKIE_KEY,
    JSON.stringify(convertBoolToStrNum(data)),
    {
      path: "/",
    },
  )
}

export const getIsInstalledPWAFromStorage = (): boolean => {
  const item = (cookies.get(PWA_INSTALLED_COOKIE_KEY) || "") as string
  return convertStrNumToBool(item)
}

export const setLastPromptPWAToStorage = (dateISO: string | null) => {
  const date = getExpiresLastPromptPWA()

  if (dateISO === null) {
    cookies.remove(PWA_LAST_PROMPT_COOKIE_KEY)
  } else {
    cookies.set(PWA_LAST_PROMPT_COOKIE_KEY, JSON.stringify(dateISO), {
      path: "/",
      expires: date,
    })
  }
}

export const getLastPromptPWAFromStorage = (): string | null => {
  return (cookies.get(PWA_LAST_PROMPT_COOKIE_KEY) as string) || null
}

export const setHasSendInstallToStorage = (data: boolean) => {
  cookies.set(
    PWA_HAS_SEND_COOKIE_KEY,
    JSON.stringify(convertBoolToStrNum(data)),
  )
}

export const getHasSendInstallFromStorage = (): boolean => {
  const item = (cookies.get(PWA_HAS_SEND_COOKIE_KEY) || "") as string
  return convertStrNumToBool(item)
}

export const sendMessageInstalledPWA = ({ text }: { text: string }) => {
  fetchLogInstallPWA({
    text: `${text}`,
  }).catch((err) => console.log("pwa install send message err: ", err))
}
