import { FC, memo } from "react"
import { ProductType } from "@/components/Products"
import { ProductsSlider } from "@/components/Products/Slider"
import { Typography } from "@/components/ui/Typography/Typography"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"

export interface IWatchedCard {
  products: ProductType[]
  title: string
  titleLeftPadding?: boolean
  slidesPerViewLg?: number
  slidesPerViewMd?: number
  slidesPerViewSm?: number
  slidesPerView0?: number
}

export const ConfiguratedSlider: FC<IWatchedCard> = memo(
  ({
    products,
    title,
    titleLeftPadding = false,
    slidesPerViewLg = 5,
    slidesPerViewMd = 3,
    slidesPerViewSm = 2,
    slidesPerView0 = 1,
  }) => {
    return (
      <ProductsSlider
        responsiveExtends={{
          [getBreakpointVal(breakpoints.lg)]: {
            slidesPerView: slidesPerViewLg,
          },
          [getBreakpointVal(breakpoints.md)]: {
            slidesPerView: slidesPerViewMd,
          },
          [getBreakpointVal(breakpoints.sm)]: {
            slidesPerView: slidesPerViewSm,
          },
          0: {
            slidesPerView: slidesPerView0,
          },
        }}
        products={products}
        title={{
          content: <Typography variant={"h3"}>{title}</Typography>,
          leftPadding: titleLeftPadding,
        }}
        virtual={{
          enabled: true,
          cache: true,
        }}
      />
    )
  },
)

ConfiguratedSlider.displayName = "ConfiguratedSlider"
