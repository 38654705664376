import type { FC } from "react";
import { memo, useMemo } from "react";
import { ButtonAuthGroup } from "../../StyledHeader";
import { AuthControlsWrapper, UidWrapper } from "./StyledAuthControls";
import { Uid } from "@/components/Account/Profile/Uid/Uid";
import { useAuth } from "@/hooks/auth/auth";
import { useModals } from "@/hooks/modals/useModals";
import { modalsAlias } from "@/store/reducers/commonSlice";
import { WordWithInitials } from "@/styles/utils/WordWithInitials";
import { Button } from "@/ui/Button/Button";
import { cssButtonLogout } from "@/ui/Button/StyledButton";
import { GTAG_EVENTS, ROUTES } from "@/utils/constants";
import { gtagSend } from "@/utils/common/helpers";
export const AuthControls: FC<{
  isShowButtonLogout?: boolean;
}> = memo(({
  isShowButtonLogout = true
}) => {
  const {
    user,
    logout,
    authModalRef
  } = useAuth();
  const {
    fio,
    accessToken
  } = user || {};
  const {
    show: showModal
  } = useModals();
  const profileButtonElement = useMemo(() => !fio ? <>Профиль</> : fio.split(" ").map((f, i) => <WordWithInitials key={i} word={f} />), [fio]);
  return <AuthControlsWrapper>
        {!!user?.number && <UidWrapper>
            <Uid small uid={user.number} />
          </UidWrapper>}
        <ButtonAuthGroup>
          {!accessToken ? <Button ref={authModalRef} variant={"small"} icon={"Lock"} onClick={() => {
        gtagSend({
          event: GTAG_EVENTS.CLICK_LOGIN
        });
        showModal({
          alias: modalsAlias.auth
        });
      }}>
              Вход | Регистрация
            </Button> : <>
              <Button variant={"small"} icon={"User"} as={"a"} href={ROUTES.account}>
                {profileButtonElement}
              </Button>
              {isShowButtonLogout && <Button variant={"small"} className={cssButtonLogout} onClick={() => logout()}>
                  Выход
                </Button>}
            </>}
        </ButtonAuthGroup>
      </AuthControlsWrapper>;
});
AuthControls.displayName = "AuthControls";