import { useContext } from "react"
import { ComparesContext } from "@/hooks/compares/context"
import { ComparesContentReturnType } from "@/hooks/compares/types"

export const useCompares = (): ComparesContentReturnType => {
  const comparesContext = useContext(ComparesContext)

  if (comparesContext === null) {
    throw new Error("Compares context have to be provided")
  }

  return {
    ...comparesContext,
  } as const
}
