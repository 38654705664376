import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useRouter } from "next/router"
import { NotificationType } from "@/components/Notifications/NotificationModal/NotificationModal"
import { getClientUserStorage, setUserStorage } from "@/hooks/auth/helpers"
import { ContextType, LoginHandleType, User } from "@/hooks/auth/types"
import { setCartTokenStorage } from "@/hooks/cart/helpers"
import { setCheckoutToStorage } from "@/hooks/checkout"
import { setComparesSelectedCategoryCookie } from "@/hooks/compares/helpers"
import { useModals } from "@/hooks/modals/useModals"
import { setLastOrderCollapsedToStorage } from "@/hooks/order/helpers"
import {
  setIsAuth,
  setIsInit,
  setIsLoading,
  setUserProfile,
} from "@/store/reducers/accountSlice"
import { modalsAlias } from "@/store/reducers/commonSlice"
import { clearFavorites } from "@/store/reducers/favoritesSlice"
import { scrollBodyEnable, setLeadHitStockId } from "@/utils/common/helpers"
import { useAppDispatch, useAppSelector } from "../redux"
import { useWatchedRecent } from "../watchedRecent/useWatchedRecent"

const UserContext = createContext<null | ContextType>(null)

export const Provider: FC = ({ children }) => {
  const { user, isAuth, isInit, customer, lastOrder, isLoading } =
    useAppSelector((state) => state.profile)
  const { businessAreas } = useAppSelector((state) => state.categories)
  const dispatch = useAppDispatch()
  const [notificationContent, setNotificationContent] =
    useState<NotificationType | null>(null)
  const authModalRef = useRef<HTMLButtonElement>(null)
  const { show: showModal } = useModals()
  const { save: saveWatchedRecent } = useWatchedRecent()

  const router = useRouter()

  const login: LoginHandleType = useCallback(
    (user, notification, shouldSaveWatchedRecent = false) => {
      scrollBodyEnable()
      dispatch(setUserProfile(user))
      setUserStorage(user)

      dispatch(clearFavorites())
      dispatch(setIsAuth(true))
      dispatch(setIsLoading(false))

      if (shouldSaveWatchedRecent) {
        saveWatchedRecent()
      }
      if (notification !== undefined) {
        setNotificationContent(notification)
      }
    },
    [dispatch, saveWatchedRecent],
  )

  const logout = useCallback(() => {
    setLastOrderCollapsedToStorage(null)
    setCheckoutToStorage(null)
    setComparesSelectedCategoryCookie(null)
    setCartTokenStorage(null)
    setUserStorage(null)
    scrollBodyEnable()
    dispatch(setIsLoading(false))

    void router.reload()
  }, [])

  const showModalAuth = useCallback(() => {
    showModal({
      alias: modalsAlias.auth,
    })
  }, [showModal])

  useEffect(() => {
    const storedUser = getClientUserStorage()
    if (storedUser !== null) {
      login(storedUser, undefined)
    } else {
      setCheckoutToStorage(null)
    }

    dispatch(setIsLoading(false))
    dispatch(setIsInit(true))
  }, [dispatch, login])

  useEffect(() => {
    if (!isInit || !isAuth) {
      return
    }
    if (businessAreas === null || businessAreas.length === 0 || !isAuth) {
      setLeadHitStockId(null)
      return
    }

    const _getBusinessAreaUuid = (foundName: string) => {
      const businessArea = businessAreas.find(
        (ba) => (ba.name || "").toLowerCase() === foundName.toLowerCase(),
      )
      return businessArea?.uuid || null
    }

    // в lastOrder может не быть или
    // может быть своя сфера бизнеса,
    // которая не может быть в stock id в фиде anyquery
    // + хранится название сферы бизнеса с пробелами
    const lastOrderBusinessAreaName = lastOrder?.bussiness_area || null

    setLeadHitStockId(
      lastOrderBusinessAreaName !== null
        ? _getBusinessAreaUuid(lastOrderBusinessAreaName)
        : null,
    )
  }, [businessAreas, isAuth, isInit, lastOrder?.bussiness_area])

  const updateLoading = useCallback(
    (isLoading: boolean) => {
      dispatch(setIsLoading(isLoading))
    },
    [dispatch],
  )

  const contextValue = useMemo(
    () => ({
      login,
      logout,
      user,
      isAuth,
      isInit,
      notification: notificationContent,
      authModalRef: authModalRef,
      showModalAuth,
      updateUser: (user: User | null) => {
        dispatch(setUserProfile(user))
        setUserStorage(user)
      },
      setNotification: setNotificationContent,
      customer,
      lastOrder,
      isLoading,
      updateLoading,
    }),
    [
      login,
      logout,
      user,
      isAuth,
      isInit,
      notificationContent,
      authModalRef,
      showModalAuth,
      dispatch,
      setNotificationContent,
      customer,
      lastOrder,
      updateLoading,
      isLoading,
    ],
  )

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  )
}

export const useAuth = (): ContextType => {
  const userContext = useContext(UserContext)

  if (userContext === null) {
    throw new Error("User context have to be provided")
  }

  return {
    ...userContext,
  } as const
}
