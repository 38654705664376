import { useCallback } from "react"
import { FeedbackTranslit } from "@/hooks/feedback/constants"
import { toCodeFormat } from "@/hooks/feedback/helpers"
import { useAppSelector } from "@/hooks/redux"
import { getPhoneWithCode } from "@/validations/phone"

const useUserPayload = () => {
  const { user, isAuth } = useAppSelector((state) => state.profile)

  const getUserLogPayload: (isExtended?: boolean) => {
    label: string
    value: string
  }[] = useCallback(
    (isExtended = false) => {
      if (!isAuth) {
        return [
          {
            label: "Статус",
            value: "Не авторизован",
          },
        ]
      } else {
        const extended = isExtended
          ? [
              {
                label: FeedbackTranslit.phone,
                value: toCodeFormat(getPhoneWithCode(user?.phone || "")),
              },
              {
                label: FeedbackTranslit.email,
                value: user?.email || "",
              },
              {
                label: "Корзина",
                value: toCodeFormat(user?.cart || ""),
              },
            ]
          : []

        return [
          {
            label: FeedbackTranslit.fio,
            value: user?.fio || "",
          },
          {
            label: FeedbackTranslit.id,
            value: toCodeFormat(user?.number || ""),
          },
          ...extended,
        ]
      }
    },
    [isAuth, user?.email, user?.fio, user?.number, user?.phone],
  )

  return {
    getUserLogPayload,
  }
}

export { useUserPayload }
