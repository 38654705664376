import { useCallback, useEffect } from "react"
import { useMutation } from "react-query"
import { useDispatch } from "react-redux"
import { useAppSelector } from "../redux"
import {
  getWatchedRecentProductsStorage,
  setWatchedRecentProductsStorage,
} from "./helpers"
import { AddProductToRecentFnType, UseWatchedRecentProductType } from "./types"
import { fetchSendRecentProducts } from "@/api/accountAPI"
import {
  addExcludeWatchedRecentProduct,
  addWatchedRecentProductsIds,
  removeExcludeWatchedRecentProduct,
  setWatchedRecentProductsIds,
} from "@/store/reducers/historyAccountSlice"

export const useWatchedRecent: UseWatchedRecentProductType = () => {
  const dispatch = useDispatch()
  const { isAuth, isInit: isInitApp } = useAppSelector(({ profile }) => profile)

  const {
    watchedRecentProducts: { keys: ids },
  } = useAppSelector(({ historyAccount }) => historyAccount)

  const { mutate: sendRecentProductsMutate } = useMutation(
    fetchSendRecentProducts,
    {
      onSuccess: (response) => {
        const productsIds = response?.products ?? []

        dispatch(setWatchedRecentProductsIds(productsIds))
      },
    },
  )

  // при посещении страницы товара
  // запишется в память
  const add: AddProductToRecentFnType = useCallback(
    ({ uuid }) => {
      if (!uuid || !isInitApp) {
        return
      }

      if (isAuth) {
        sendRecentProductsMutate({
          products: `${uuid}`,
        })
      } else {
        dispatch(addWatchedRecentProductsIds([`${uuid}`]))
      }
    },
    [dispatch, isAuth, sendRecentProductsMutate, isInitApp],
  )

  const saveWatched = useCallback(() => {
    const lastViewProductsUUIds = getWatchedRecentProductsStorage()

    if (isAuth) {
      sendRecentProductsMutate({
        products: lastViewProductsUUIds.join(","),
      })
    } else {
      dispatch(setWatchedRecentProductsIds(lastViewProductsUUIds || []))
    }
  }, [dispatch, isAuth, sendRecentProductsMutate])

  // при загрузке страницы
  // заинитятся данные
  const initWatched = useCallback(() => {
    if (isAuth) {
      sendRecentProductsMutate({})
    } else {
      const lastViewProductsUUIds = getWatchedRecentProductsStorage()

      dispatch(setWatchedRecentProductsIds(lastViewProductsUUIds || []))
    }
  }, [isAuth, sendRecentProductsMutate, dispatch])

  const updateIdsStorage = useCallback(() => {
    if (!isInitApp) {
      return
    }

    if (isAuth) {
      return
    }

    setWatchedRecentProductsStorage(ids)
  }, [isInitApp, isAuth, ids])

  const exclude = useCallback(
    (uuid: string) => {
      dispatch(addExcludeWatchedRecentProduct([uuid]))
    },
    [dispatch],
  )

  const include = useCallback(
    (uuid: string) => {
      dispatch(removeExcludeWatchedRecentProduct([uuid]))
    },
    [dispatch],
  )

  useEffect(() => {
    updateIdsStorage()
  }, [updateIdsStorage])

  return {
    save: saveWatched,
    init: initWatched,
    add,
    exclude,
    include,
  }
}
