import LazyHydrate, { LazyProps } from "react-lazy-hydration"
import { FC, forwardRef } from "react"
import { lazyHydrationConfig } from "@/hoc/LazyHydrate/constants"

export type LazyHydrateContainerPropsType = {
  config?: LazyProps
}

const LazyInner = forwardRef<HTMLDivElement>((props, ref) => {
  return <div {...props} ref={ref} />
})

const LazyHydrateContainer: FC<LazyProps | undefined> = ({
  children,
  whenVisible,
  whenIdle,
  ...props
}) => {
  const whenConfig = whenVisible ?? whenIdle ?? lazyHydrationConfig
  return (
    <LazyHydrate whenVisible={whenConfig} {...props}>
      <>{children}</>
    </LazyHydrate>
  )
}

LazyInner.displayName = "LazyInner"

export { LazyHydrateContainer }
