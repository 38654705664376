import {
  INITIAL_PAGE,
  INITIAL_PER_PAGE,
  INITIAL_STATE,
  MatchingFiltersNamesAlias,
} from "@/hooks/filterHistory/constants"
import { QueryFiltersHistoryStringType } from "@/hooks/filterHistory/types"
import { StateOrderDataType } from "@/hooks/order"
import { OrderStateNumber, STATE_ORDER_SET } from "@/hooks/order/constants"
import type { FilterParamType, FilterType } from "@/types"
import { getUnique } from "@/utils/common/helpers"
import type { ResponseOrdersFilters } from "../../../contracts"

type CreateFiltersFromDataFnType = (
  data: ResponseOrdersFilters,
) => Record<string, FilterType>

export const validateStateOrder = (
  value?: string | number | null,
): OrderStateNumber | undefined => {
  if (!value) {
    return undefined
  }
  const match: StateOrderDataType | undefined = STATE_ORDER_SET[+value] as
    | StateOrderDataType
    | undefined
  return !!match ? match.number : undefined
}

export const createFiltersFromData: CreateFiltersFromDataFnType = (data) => {
  const filters: Record<string, FilterType> = {}

  for (const [alias, payload] of Object.entries(data)) {
    const values = getUnique(payload)

    if (values.length <= 0) {
      continue
    }

    filters[alias] = {
      uuid: alias,
      name: MatchingFiltersNamesAlias[alias] || alias,
      order: 0,
      values: values.reduce(
        (o: Record<string, FilterParamType>, value, index) => ({
          ...o,
          [`${index}`]: {
            name: value,
            image: "",
            uuid: encodeURIComponent(value),
            product_qty: undefined,
          } as FilterParamType,
        }),
        {},
      ),
    }
  }

  return filters
}

export const normalizeSearchParamsHistory = ({
  query: {
    page,
    per_page,
    payers,
    years,
    state,
    category,
    pickup,
    delivery,
    search,
    from,
    to,
  },
}: {
  query: QueryFiltersHistoryStringType
}): Required<QueryFiltersHistoryStringType> => ({
  page: String(page || INITIAL_PAGE),
  per_page: String(per_page || INITIAL_PER_PAGE),
  payers: String(payers || ""),
  years: String(years || ""),
  state: String(state || INITIAL_STATE),
  category: String(category || ""),
  delivery: String(delivery || ""),
  pickup: String(pickup || ""),
  search: String(search || ""),
  from: String(from || ""),
  to: String(to || ""),
})

// в истории заказов используется другой вид фильтров чем в каталоге
// разделенный по полям фильтров
// в каждом из полей может быть значение другого поля,
// поэтому алгоритм "createFiltersChecked" каталога с едиными params не подходит
export const createFiltersChecked = ({
  queryGroupParams,
  filters,
}: {
  queryGroupParams: Record<string, string[]>
  filters: Record<string, FilterType>
}) => {
  const filtersChecked: Record<string, string[]> = {}

  if (!(Object.keys(filters).length > 0)) {
    return filtersChecked
  }

  for (const [filterQuery, valuesQuery] of Object.entries(queryGroupParams)) {
    const availableFilter = filters[filterQuery]
    if (!availableFilter) {
      continue
    }

    const valuesKeys = Object.values(availableFilter.values).map((v) => v.uuid)
    filtersChecked[filterQuery] = valuesQuery.filter((q) =>
      valuesKeys.includes(q),
    )
  }

  return filtersChecked
}

export const decodeURIComponentArr = (arr: string[]) =>
  arr.map((i) => decodeURIComponent(i))
