import React, { FC, memo, ReactNode, useEffect } from "react"
import dynamic from "next/dynamic"
import { LazyHydrateContainer } from "@/hoc/LazyHydrate"
import { useModals } from "@/hooks/modals/useModals"
import { useAppSelector } from "@/hooks/redux"
import { setLastPromptPWAToStorage } from "@/hooks/usePWA/helpers"
import { modalsAlias } from "@/store/reducers/commonSlice"
import { Modal } from "@/ui/Modal"
import { PLATFORM } from "@/utils/constants"

const IosPrompt = dynamic(
  () => import("components/PWA/PromptIOS").then(({ PromptIOS }) => PromptIOS),
  { ssr: false },
)

const AndroidPrompt = dynamic(
  () =>
    import("components/PWA/PromptAndroid").then(
      ({ PromptAndroid }) => PromptAndroid,
    ),
  { ssr: false },
)

const matchingElement: Record<PLATFORM | string, () => ReactNode> = {
  [PLATFORM.IOS]: () => <IosPrompt />,
  [PLATFORM.ANDROID]: () => <AndroidPrompt />,
}

const PromptModal: FC<{
  platform: PLATFORM
}> = memo(({ platform }) => {
  const isShowPWAModal = useAppSelector(
    (state) => state.common.modals[modalsAlias.pwa].isShow,
  )

  const { hide: hideModal, show } = useModals()

  const contentElement: () => ReactNode | undefined = matchingElement[platform]

  useEffect(() => {
    if (!!contentElement) {
      show({
        alias: modalsAlias.pwa,
      })
    }
  }, [contentElement, show])

  const onCloseHandle = () => {
    setLastPromptPWAToStorage(new Date().toISOString())
    hideModal({
      alias: modalsAlias.pwa,
    })
  }

  if (!contentElement) {
    return null
  }

  return (
    <Modal
      isShowModal={isShowPWAModal}
      closeMode="destroy"
      hideOnClickOutside={false}
      onClose={onCloseHandle}
      isStickyTitle={false}
    >
      <LazyHydrateContainer>
        <>{contentElement()}</>
      </LazyHydrateContainer>
    </Modal>
  )
})
PromptModal.displayName = "PromptModal"

export { PromptModal }
