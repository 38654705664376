import { cx } from "@linaria/core";
import { forwardRef, memo } from "react";
import { cssSeoText } from "@/styles/utils/Utils";
import { cssButtonClean } from "@/ui/Button/StyledButton";
import { BaseHTMLButtonType, ButtonContainerPropsType } from "@/ui/Button/types";
import { Icon } from "@/ui/Icon";
import { Loader } from "@/ui/Loaders/ComponentUiLoader/Loader";
import { Typography } from "@/ui/Typography/Typography";
const ButtonContainer = memo(forwardRef<HTMLButtonElement, ButtonContainerPropsType & BaseHTMLButtonType>(({
  ButtonComponent,
  icon,
  iconPosition,
  fillIcon,
  children,
  size = "medium",
  hideTextOnBreakpoint,
  isFetching,
  withArea,
  isHiddenBg,
  withOutOffset,
  seoText,
  withWrapperContent,
  ...props
}, ref) => {
  const isVisibleWrapper = withWrapperContent ?? (!!icon && !!children);
  return <ButtonComponent {...props} ref={ref} data-icon-position={iconPosition} data-size={size} data-text-hide-breakpoints={hideTextOnBreakpoint} data-is-fetched={isFetching || undefined} data-with-area={withArea} data-hidden-bg={isHiddenBg} data-is-offset={withOutOffset} className={cx(props.className, props.isClean && cssButtonClean)}>
          {isFetching && <Loader />}
          {!!icon && <Icon NameIcon={icon} fill={fillIcon} />}
          {isVisibleWrapper ? <Typography variant={"span"}>{children}</Typography> : children}
          {!!seoText && <span className={cssSeoText}>{seoText}</span>}
        </ButtonComponent>;
}));
ButtonContainer.displayName = "ButtonContainer";
export { ButtonContainer };