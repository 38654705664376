import { EnhancedStore } from "@reduxjs/toolkit/dist/configureStore"
import { AppContext } from "next/app"
import { setLocation } from "@/store/reducers/appSlice"
import { RootStateType } from "@/store/store"
import { getExpireOneYear, makeLocationSSR } from "@/utils/common/helpers"

const getServerSideProps =
  (reduxStore: EnhancedStore<RootStateType>) =>
  async (payload: Pick<AppContext["ctx"], "res" | "req">) => {
    const { res, req } = payload
    let madeCookies = res?.getHeader("Set-Cookie") || ""

    madeCookies += `host=${encodeURIComponent(
      req?.headers.host || "",
    )}; Path=/; Expires=${getExpireOneYear().toUTCString()}`

    if (!req) {
      return {}
    }

    const locationPayload = await makeLocationSSR({
      req,
      asPath: req.url,
    })

    if (!locationPayload) {
      return {}
    }

    const { location, cookieLocation } = locationPayload

    reduxStore.dispatch(setLocation(location))

    if (!!cookieLocation) {
      madeCookies += cookieLocation
    }

    res?.setHeader("Set-Cookie", madeCookies)

    return {
      // data
    }
  }

export { getServerSideProps as locationServerProps }
