import clsx from "clsx";
import { FC, memo } from "react";
import { cssIsActive } from "@/styles/utils/Utils";
import { cssPopoverAngle } from "@/ui/Popover/StyledPopover";
import { NavigationTitle } from "./NavigationTitle";
import { cssNavigationItemWithSub, StyledNav } from "./StyledNavigation";
import { NavigationItemPropsType } from "./types";
import { cssListVertical, StyledList, StyledListItem } from "../../List/StyledList";
import { Button } from "../Button/Button";
import { Icon } from "../Icon";
import { Popover } from "../Popover/Popover";
const NavigationItem: FC<NavigationItemPropsType> = memo(({
  title,
  path,
  subItems = [],
  variant,
  activePath,
  as = "li",
  isScroll = true,
  isLink = true,
  isStylingIconDisclosure = true,
  currentPath,
  qty,
  isShowQty
}) => {
  if (!title && !path) {
    return null;
  }
  const isActiveClass = activePath === path ? cssIsActive : activePath?.split("/").filter((p, i) => i < 3).join("/").replace("//", "/") === path ? cssIsActive : "";
  const isLinkProp = isLink && path !== currentPath;
  return <StyledListItem as={as} className={clsx(isActiveClass, subItems.length > 0 && cssNavigationItemWithSub)}>
        {!subItems.length ? <NavigationTitle title={title} path={path} variant={variant} isScroll={isScroll} isLink={isLinkProp} qty={qty} isShowQty={isShowQty} /> : <Popover placement={"bottom-start"} withHover isStylingIconDisclosure={isStylingIconDisclosure} offset={[0, 0]} aria-label={title || "popoverNav"} disclosure={<Button>
                <span>
                  <NavigationTitle title={title} path={path} variant={variant} isScroll={isScroll} isLink={isLinkProp} qty={qty} isShowQty={isShowQty} />
                  <Icon NameIcon={"AngleBottom"} className={cssPopoverAngle} />
                </span>
              </Button>}>
            <StyledNav>
              <StyledList className={cssListVertical}>
                {subItems.map((si, i) => <NavigationItem key={i} title={si.title} path={si.path} isScroll={isScroll} variant={variant} qty={si.qty} isShowQty={isShowQty} />)}
              </StyledList>
            </StyledNav>
          </Popover>}
      </StyledListItem>;
});
NavigationItem.displayName = "NavigationItem";
export { NavigationItem };