import { FC } from "react";
import { cssButtonSelectCity } from "../StyledHeader";
import { LocationPopover, useLocationPopover } from "./LocationPopover";
import { StyledSelectLocation } from "./Styled";
import { useApp } from "@/hooks/app/app";
import { Button } from "@/ui/Button/Button";
export const SelectLocation: FC = () => {
  const {
    location
  } = useApp();
  const locationPopoverProps = useLocationPopover();
  const {
    showModal
  } = locationPopoverProps;
  return <StyledSelectLocation>
      <LocationPopover {...locationPopoverProps} />

      <Button variant={"small"} icon={"AngleBottom"} iconPosition={"right"} className={cssButtonSelectCity} aria-label={"Текущее местоположение"} type="button" role="button" onClick={showModal}>
        {!!location?.city ? location.city : "Город"}
      </Button>
    </StyledSelectLocation>;
};
export { LocationPopover };