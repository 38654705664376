import { forwardRef, memo, useState } from "react"
import Image, { ImageProps } from "next/image"
import { getImagePath } from "@/utils/common/helpers"
import { NoImage } from "./NoImage"
import { StyledEntityImage, StyledEntityNoImage } from "./Styled"
import { BaseLoader } from "../Loaders/BaseLoader/BaseLoader"

export type ImagePropsType = Omit<ImageProps, "src" | "draggable"> & {
  imagePath?: string
  imageAlt?: string
  withPreloader?: boolean
}

const EntityImage = memo(
  forwardRef<HTMLDivElement, ImagePropsType>(
    (
      {
        imagePath,
        imageAlt = "",
        priority,
        width,
        height,
        withPreloader,
        loading,
        ...props
      },
      ref,
    ) => {
      const [isLoading, setIsLoading] = useState(!!withPreloader)
      const isNoImage = !imagePath

      if (isNoImage) {
        return (
          <StyledEntityNoImage
            {...props}
            width={width}
            height={height}
            ref={ref}
          >
            <NoImage />
          </StyledEntityNoImage>
        )
      }

      const src = getImagePath(imagePath)
      return (
        <StyledEntityImage {...props} ref={ref}>
          {isLoading && <BaseLoader />}
          <Image
            src={src}
            {...props}
            draggable={false}
            alt={imageAlt}
            priority={priority}
            width={width}
            height={height}
            loading={loading}
            onLoadingComplete={() => {
              setIsLoading(false)
            }}
            onError={() => {
              setIsLoading(false)
            }}
          />
        </StyledEntityImage>
      )
    },
  ),
)

EntityImage.displayName = "EntityImage"

export { EntityImage }
