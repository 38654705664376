import { useContext } from "react"
import { ModalsContext } from "@/hooks/modals/context"
import { ModalsContextReturnType } from "@/hooks/modals/types"

const useModals = (): ModalsContextReturnType => {
  const modalsContext = useContext(ModalsContext)

  if (modalsContext === null) {
    throw new Error("Modal context have to be provided")
  }

  return {
    ...modalsContext,
  }
}

export { useModals }
