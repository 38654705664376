import {
  fetchAddFavoritesGroup,
  fetchAddToFavorites,
  fetchFavorites,
  fetchFavoritesGroups,
  fetchRemoveAllFavorites,
  fetchRemoveFavoritesGroup,
  fetchRemoveFromFavorites,
} from "@/api/favoritesAPI"
import { fetchProductById } from "@/api/productsAPI"
import { useAuth } from "@/hooks/auth/auth"
import {
  getFavoritesStorage,
  setFavoritesStorage,
} from "@/hooks/favorites/helpers"
import { ContextPropsType } from "@/hooks/favorites/types"
import { NOTIFICATION_KIND } from "@/hooks/notification/constants"
import { useNotifications } from "@/hooks/notification/notification"
import { useAppDispatch, useAppSelector } from "@/hooks/redux"
import { useWindowSize } from "@/hooks/useWindowSize"
import {
  addToFavorites,
  clearFavorites,
  removeFromFavorites,
  setFavoritesGroups,
  setGeneralFavorites,
  toggleFavoritesProgress,
} from "@/store/reducers/favoritesSlice"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"
import { getImagePath, readBooleanFromStorage } from "@/utils/common/helpers"
import {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useMutation, useQuery } from "react-query"

export const FavoritesContext = createContext<null | ContextPropsType>(null)

export const Provider: FC = ({ children }) => {
  const dispatch = useAppDispatch()
  const { keys: favoritesKeys, toggleProgress: toggleProgressList } =
    useAppSelector((state) => state.favorites)

  const favoritesGroups = useAppSelector((state) => state.favorites.groups)

  const { isAuth, isInit, isLoading: isLoadingAuth } = useAuth()
  const [isShowToLogin, setIsShowToLogin] = useState(false)
  const { push: pushNotification } = useNotifications()
  const { width } = useWindowSize()
  const isMoreSm =
    width !== undefined && width > getBreakpointVal(breakpoints.sm)

  const { isFetching: isFetchingGroups, refetch: refetchFavoritesGroup } =
    useQuery(["favoritesGroups", isAuth, isInit], fetchFavoritesGroups, {
      enabled: isInit && isAuth,
      refetchOnWindowFocus: true,
      onSuccess(response) {
        dispatch(setFavoritesGroups(response || []))
      },
    })

  useQuery(["favorites", isInit, isAuth], () => fetchFavorites(), {
    refetchOnWindowFocus: true,
    enabled: isInit && isAuth,
    onSuccess: (response) => {
      if (!!response) {
        dispatch(
          setGeneralFavorites({
            products: response.products || [],
          }),
        )
      }

      bindFavorites()
    },
  })

  const { mutate: addToFavoritesMutate } = useMutation(fetchAddToFavorites, {
    onMutate: (request) => {
      dispatch(
        toggleFavoritesProgress({
          isFetching: true,
          product: request.products,
        }),
      )
    },
    onSuccess: (_, { products, group }) => {
      addFavoritesToState(products.split(","), group)

      if (!!group) {
        void refetchFavoritesGroup()
      }
    },
    onSettled: (_, error, request) => {
      dispatch(
        toggleFavoritesProgress({
          isFetching: false,
          product: request.products,
        }),
      )
    },
  })

  const { mutate: removeFromFavoritesMutate } = useMutation(
    fetchRemoveFromFavorites,
    {
      onMutate: (request) => {
        dispatch(
          toggleFavoritesProgress({
            isFetching: true,
            product: request.product,
          }),
        )
      },
      onSuccess: (response, request) => {
        removeFavoritesFromState(request.product)

        void refetchFavoritesGroup()
      },
      onSettled: (data, error, request) => {
        dispatch(
          toggleFavoritesProgress({
            isFetching: false,
            product: request.product,
          }),
        )
      },
    },
  )

  const { mutate: addFavoritesGroupMutate } = useMutation(
    fetchAddFavoritesGroup,
    {
      onSuccess() {
        void refetchFavoritesGroup()
      },
    },
  )

  const { mutate: removeFavoritesGroupMutate } = useMutation(
    fetchRemoveFavoritesGroup,
    {
      onSuccess() {
        void refetchFavoritesGroup()
      },
    },
  )

  const { mutate: removeAllFavoritesMutate } = useMutation(
    fetchRemoveAllFavorites,
    {
      onSuccess: () => {
        dispatch(clearFavorites())

        void refetchFavoritesGroup()
      },
    },
  )

  const addFavoritesToState = useCallback(
    (products: string[], group?: string) => {
      dispatch(addToFavorites({ products, group }))
    },
    [dispatch],
  )

  const removeFavoritesFromState = useCallback(
    (product: string) => {
      dispatch(removeFromFavorites(product))
    },
    [dispatch],
  )

  const flashNotification = useCallback(
    (productUUIDs: string[], groupId?: string) => {
      for (const uuid of productUUIDs) {
        fetchProductById({
          server: false,
          uuid,
          fields: ["images"],
        })
          .then(({ images }) => {
            const [image] = images ?? []

            if (!!groupId) {
              const group =
                !!favoritesGroups &&
                favoritesGroups.find((g) => g.uid === groupId)

              if (!!group) {
                pushNotification({
                  kind: NOTIFICATION_KIND.FAVORITE_LIST,
                  image: getImagePath(image),
                  list: group.name,
                })

                return
              }
            }

            pushNotification({
              kind: NOTIFICATION_KIND.FAVORITES,
              image: getImagePath(image),
              replace: true,
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    [pushNotification, favoritesGroups],
  )

  const addToFavoritesHandler: (
    productUUIDs: string[],
    group?: string,
    withNotification?: boolean,
    withOffer?: boolean,
  ) => void = useCallback(
    (productUUIDs, group, withNotification = true, withOffer = true) => {
      let isOfferToLogIn = withOffer

      if (isAuth) {
        addToFavoritesMutate({
          products: productUUIDs.join(","),
          group,
        })
      } else {
        addFavoritesToState(productUUIDs)

        // Старт если юзер не авторизован и добавил товар в избранное, после 1 клика предлагаем ему авторизоваться
        isOfferToLogIn = withOffer
          ? readBooleanFromStorage(sessionStorage, "offerToLogInFavorites")
          : false

        if (!isOfferToLogIn) {
          sessionStorage.setItem("offerToLogInFavorites", "true")
          setIsShowToLogin(true)
        }
        // Конец
      }

      if (withNotification && isMoreSm && isOfferToLogIn) {
        flashNotification(productUUIDs, group)
      }
    },
    [
      isAuth,
      isMoreSm,
      flashNotification,
      addFavoritesToState,
      addToFavoritesMutate,
    ],
  )

  const removeFromFavoritesHandler = useCallback(
    (productUUID: string, group?: string) => {
      if (isAuth) {
        removeFromFavoritesMutate({ product: productUUID, group })
      } else {
        removeFavoritesFromState(productUUID)
      }
    },
    [isAuth, removeFromFavoritesMutate, removeFavoritesFromState],
  )

  const bindFavorites = () => {
    const favoritesBeforeAuth = getFavoritesStorage()
    if (favoritesBeforeAuth !== null && favoritesBeforeAuth.length > 0) {
      addToFavoritesHandler(favoritesBeforeAuth, undefined, false, false)
      setFavoritesStorage([])
    }
  }

  const quantity = favoritesKeys !== null ? favoritesKeys.length : 0

  const hideToLogin = () => {
    setIsShowToLogin(false)
  }

  const clear = useCallback(() => {
    if (!isInit) return

    if (isAuth) {
      removeAllFavoritesMutate()
    } else {
      dispatch(clearFavorites())
      setFavoritesStorage([])
    }
  }, [dispatch, isAuth, isInit, removeAllFavoritesMutate])

  const addGroup = useCallback(
    (groupName: string) => {
      addFavoritesGroupMutate({ name: groupName })
    },
    [addFavoritesGroupMutate],
  )

  const removeGroup = useCallback(
    (uid: string) => {
      removeFavoritesGroupMutate({ uid })
    },
    [removeFavoritesGroupMutate],
  )

  useEffect(() => {
    if (isLoadingAuth) {
      return
    }
    if (isInit) {
      if (!isAuth) {
        setFavoritesStorage(favoritesKeys)
      }
    }
  }, [favoritesKeys, isAuth, isInit, isLoadingAuth])

  useEffect(() => {
    if (isLoadingAuth) {
      return
    }
    if (isInit) {
      if (!isAuth) {
        addFavoritesToState(getFavoritesStorage() || [])
      }
    }
  }, [addFavoritesToState, dispatch, isAuth, isInit, isLoadingAuth])

  const contextValue = useMemo(
    () =>
      ({
        add: addToFavoritesHandler,
        remove: removeFromFavoritesHandler,
        favoritesKeys,
        quantity,
        fetchingUUIds: toggleProgressList,
        isShowToLogin,
        hideToLogin,
        clear,
        isFetchingGroups,
        addGroup,
        removeGroup,
        refetchFavoritesGroup: async () => {
          await refetchFavoritesGroup()
        },
      } as ContextPropsType),
    [
      addToFavoritesHandler,
      removeFromFavoritesHandler,
      favoritesKeys,
      toggleProgressList,
      quantity,
      isShowToLogin,
      clear,
      isFetchingGroups,
      addGroup,
      removeGroup,
      refetchFavoritesGroup,
    ],
  )

  return (
    <FavoritesContext.Provider value={contextValue}>
      {children}
    </FavoritesContext.Provider>
  )
}
