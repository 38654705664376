import { StateOrderDataType } from "@/hooks/order/types"

export const enum OrderStateString {
  ACTIVE = "active",
  COMPLETED = "completed",
  CANCELED = "canceled",
  NEW = "new",
  COURIER = "courier",
}

export const enum OrderStateNumber {
  NEW = 2,
  COMPLETED = 3,
  CANCELED = 4,
  ACTIVE = 5,
  COURIER = 6,
}

export const STATE_ORDER_SET: Record<OrderStateNumber, StateOrderDataType> = {
  [OrderStateNumber.NEW]: {
    number: OrderStateNumber.NEW,
    name: OrderStateString.NEW,
    message: "Новый",
  },
  [OrderStateNumber.COMPLETED]: {
    number: OrderStateNumber.COMPLETED,
    name: OrderStateString.COMPLETED,
    message: "Завершен",
  },
  [OrderStateNumber.CANCELED]: {
    number: OrderStateNumber.CANCELED,
    name: OrderStateString.CANCELED,
    message: "Отменен",
  },
  [OrderStateNumber.ACTIVE]: {
    number: OrderStateNumber.ACTIVE,
    name: OrderStateString.ACTIVE,
    message: "В обработке",
  },
  [OrderStateNumber.COURIER]: {
    number: OrderStateNumber.COURIER,
    name: OrderStateString.COURIER,
    message: "Передан курьеру",
  },
}
export const ORDER_CANCEL_STATUS_NUMBER = OrderStateNumber.CANCELED
export const TRANSLATE_ORDER = {
  payer: "Плательщик",
  pickupAddress: "Адрес самовывоза",
  year: "Год",
  shippingAddress: "Адрес доставки",
  shippingDate: "Дата доставки",
  shippingMethod: "Способ получения",
  paymentMethod: "Способ оплаты",
  paid: "Статус оплаты",
}

export const ORDER_COLLAPSED_LOCAL_STORAGE_KEY = "orderCollapsed"

export const enum PaidStatusString {
  PAID = "Оплачен",
  NOT_PAID = "Не оплачен",
}
