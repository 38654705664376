import { createContext, FC } from "react"
import { ModalsContextReturnType } from "@/hooks/modals/types"
import { useAppDispatch, useAppSelector } from "@/hooks/redux"
import {
  ComputedModalsAlias,
  setIsShowModal,
} from "@/store/reducers/commonSlice"

export const ModalsContext = createContext<ModalsContextReturnType | null>(null)

export const Provider: FC = ({ children }) => {
  const dispatch = useAppDispatch()
  const modals = useAppSelector((state) => state.common.modals)
  const _toggledShow = (payload: {
    alias: ComputedModalsAlias
    isShow: boolean
  }) => {
    dispatch(setIsShowModal(payload))
  }

  const show = ({ alias }: { alias: ComputedModalsAlias }) => {
    _toggledShow({
      alias,
      isShow: true,
    })
  }

  const hide = ({ alias }: { alias: ComputedModalsAlias }) => {
    _toggledShow({
      alias,
      isShow: false,
    })
  }

  const getIsOpen = ({ alias }: { alias: ComputedModalsAlias }) => {
    return modals[alias]?.isShow ?? false
  }

  const value = {
    getIsOpen,
    show,
    hide,
  }

  return (
    <ModalsContext.Provider value={value}>{children}</ModalsContext.Provider>
  )
}
