import { cx } from "@linaria/core";
import type { Swiper as SwiperType } from "swiper";
import type { SwiperProps, SwiperRef } from "swiper/react";
import { Swiper } from "swiper/react";
import type { NavigationOptions } from "swiper/types/modules/navigation";
import { FC, Ref, useRef, useState } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pages } from "@/components/Swiper/Pages";
import { Button } from "@/ui/Button/Button";
import { IconNameType } from "@/ui/Icon";
import type { SwiperPaginationSequencePropsType } from "./PaginationSequence";
import { cssCustomSwiper, cssIsAbsoluteNav, cssNavNext, cssNavPrev } from "./StyledSwiper";
const SwiperPaginationSequence = dynamic(((() => import("./PaginationSequence").then(mod => mod.SwiperPaginationSequence)) as DynamicOptions<SwiperPaginationSequencePropsType>), {
  ssr: false
});
export const enum PLACEMENT_ARROW {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}
export const enum VARIANT_ARROW {
  ARROW = "arrow",
  ANGLE = "angle",
}
const matching: Record<VARIANT_ARROW, [IconNameType, IconNameType]> = {
  [VARIANT_ARROW.ARROW]: ["ArrowLeft", "ArrowRight"],
  [VARIANT_ARROW.ANGLE]: ["AngleLeft", "AngleRight"]
};
export type NavigationInitPropsType = {
  swiper: SwiperType;
} & Pick<NavigationOptions, "prevEl" | "nextEl">;
type NavigationInitType = (props: NavigationInitPropsType) => void;
export type SwiperWrapperPropsType = SwiperProps & {
  paginationSequence?: boolean;
  path?: string;
  withNavigation?: boolean;
  variantArrow?: VARIANT_ARROW;
  placementArrow?: PLACEMENT_ARROW;
  isAbsoluteNav?: boolean;
  withPages?: boolean;
} & SwiperPaginationSequencePropsType & {
  ref?: Ref<SwiperRef>;
};
export const navigationInit: NavigationInitType = ({
  swiper,
  nextEl,
  prevEl
}) => {
  if (swiper.params.navigation !== undefined) {
    swiper.params.navigation["prevEl"] = prevEl;
    swiper.params.navigation["nextEl"] = nextEl;
  }
  swiper.navigation.init();
  swiper.navigation.update();
};
export const navigationDestroy = ({
  swiper
}: {
  swiper: SwiperType;
}): void => {
  swiper.navigation.destroy();
};
export const SwiperWrapper: FC<SwiperWrapperPropsType> = ({
  paginationSequence = false,
  path,
  count,
  className,
  variantArrow = VARIANT_ARROW.ARROW,
  placementArrow = PLACEMENT_ARROW.VERTICAL,
  withNavigation = false,
  children,
  onInit,
  isAbsoluteNav = false,
  withPages = false,
  ...props
}) => {
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const onInitNavigation = (swiper: SwiperType) => {
    navigationInit({
      swiper,
      prevEl: prevRef.current || undefined,
      nextEl: nextRef.current || undefined
    });
  };
  const onInitHandle = (swiper: SwiperType) => {
    if (withNavigation) {
      onInitNavigation(swiper);
    }
    if (!!onInit) {
      onInit(swiper);
    }
  };

  // обход нужен тк при синхронизации двух слайдеров
  // происходит рассинхрон между цифрами активного слайда
  const [page, setPage] = useState(0);
  return <Swiper {...props} onInit={onInitHandle} className={cx(cssCustomSwiper, isAbsoluteNav && cssIsAbsoluteNav, className)} data-variant-arrow={variantArrow} data-placement-arrow={placementArrow} onActiveIndexChange={swiper => {
    setPage(swiper.activeIndex);
    if (props.onActiveIndexChange) {
      props.onActiveIndexChange(swiper);
    }
  }}>
      {children}
      {withNavigation && <>
          <Button variant={"arrow"} icon={matching[variantArrow][0]} ref={prevRef} className={cssNavPrev} />
          <Button variant={"arrow"} icon={matching[variantArrow][1]} ref={nextRef} className={cssNavNext} />
        </>}
      {paginationSequence && <SwiperPaginationSequence count={count} path={path} />}
      {withPages && <Pages page={page} />}
    </Swiper>;
};