import React from "react"

export type FontType = "font/eot" | "font/woff" | "font/woff2"

export type FontItemType = {
  href: string
  type: FontType
  isPreload?: boolean
}

const FONTS: FontItemType[] = [
  {
    href: "/fonts/Montserrat/Montserrat-Medium.eot",
    type: "font/eot",
  },
  {
    href: "/fonts/Montserrat/Montserrat-Medium.woff",
    type: "font/woff",
    isPreload: true,
  },
  {
    href: "/fonts/Montserrat/Montserrat-Medium.woff2",
    type: "font/woff2",
    isPreload: true,
  },
  {
    href: "/fonts/Montserrat/Montserrat-SemiBold.eot",
    type: "font/eot",
  },
  {
    href: "/fonts/Montserrat/Montserrat-SemiBold.woff",
    type: "font/woff",
  },
  {
    href: "/fonts/Montserrat/Montserrat-SemiBold.woff2",
    type: "font/woff2",
  },
  {
    href: "/fonts/Montserrat/Montserrat-Bold.eot",
    type: "font/eot",
  },
  {
    href: "/fonts/Montserrat/Montserrat-Bold.woff",
    type: "font/woff",
  },
  {
    href: "/fonts/Montserrat/Montserrat-Bold.woff2",
    type: "font/woff2",
  },
]

const Fonts = () => {
  return (
    <>
      {FONTS.map(({ href, isPreload = false }) => (
        <link
          href={href}
          key={href}
          rel={isPreload ? "preload" : undefined}
          as={isPreload ? "font" : undefined}
          crossOrigin=""
        />
      ))}
    </>
  )
}

export { Fonts }
