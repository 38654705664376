import React, { FC, memo } from "react";
import { DigiProductAutoCompleteType } from "@/api/digineticaAPI";
import { Product } from "./Product";
import { StyledProducts } from "./Product/StyledProduct";
import { cssProducts, StyledResult, StyledResultTitle } from "../StyledAutoComplete";
export type ProductsPropsType = {
  products: DigiProductAutoCompleteType[];
};
const Products: FC<ProductsPropsType> = memo(({
  products,
  children
}) => {
  return <StyledResult className={cssProducts}>
      {!!products.length ? <>
          <StyledResultTitle>Популярные товары</StyledResultTitle>
          <StyledProducts>
            {products.map(p => <Product product={p} key={p.id} />)}
          </StyledProducts>
          {children}
        </> : <StyledResultTitle>
          Ничего не найдено по вашему запросу
        </StyledResultTitle>}
    </StyledResult>;
});
Products.displayName = "ProductsSearch";
export { Products };