import { FC, memo, useMemo } from "react"
import Navigation from "../../../../components/ui/Navigation/Navigation"
import { useAppSelector } from "@/hooks/redux"
import { getMenuItems } from "@/layouts/Default/Header/NavigationStatic/helpers"
import { LinkVariants } from "@/ui/Link/constants"

const NavigationStatic: FC<{
  isPopup?: boolean
}> = memo(({ isPopup }) => {
  const { isAuth } = useAppSelector(({ profile }) => profile)

  const items = useMemo(
    () =>
      getMenuItems({
        isAuth,
        isPopup,
      }),
    [isAuth, isPopup],
  )

  return <Navigation items={items} variant={LinkVariants.GRAY_DARK_TO_BLACK} />
})

NavigationStatic.displayName = "NavigationStatic"

export { NavigationStatic }
