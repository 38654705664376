import React, { FC, memo } from "react";
import { useClipboardCopy } from "@/hooks/clipboardCopy";
import { colors } from "@/styles/utils/vars";
import { Button } from "@/ui/Button/Button";
import { ButtonSizeAlias } from "@/ui/Button/constants";
import { Popover } from "@/ui/Popover/Popover";
import { Typography } from "@/ui/Typography/Typography";
import { cssBtnUid } from "./StyledUid";
const Uid: FC<{
  uid: string | null;
  small?: boolean;
}> = memo(({
  uid,
  small = false
}) => {
  const {
    isCopied,
    handleCopyClick
  } = useClipboardCopy();
  if (!uid) {
    return null;
  }
  const copy = () => {
    if (!uid) {
      return;
    }
    handleCopyClick(uid);
  };
  return <Popover disclosure={<Button onClick={copy} size={small ? ButtonSizeAlias.SMALL : undefined} isClean className={cssBtnUid}>
          Id: {uid}
        </Button>} isShow={isCopied} isStylingIconDisclosure={false} size={"small"}>
      <Typography variant={"p13"} color={colors.green}>
        Идентификатор скопирован!
      </Typography>
    </Popover>;
});
Uid.displayName = "Uid";
export { Uid };