import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  MouseEvent,
  ReactElement,
  ReactNode,
} from "react"
import { NextPage } from "next"
import { AppProps } from "next/app"
import { ParamType } from "@/components/Feed/Payload/types"
import { ViewProductsType } from "@/components/Products/types"
import { ShippingMethod } from "@/hooks/shippings/constants"
import { FetcherBasePropsType } from "@/service/fetcher"
import { ViewPageProductsType } from "@/store/reducers/catalogSlice"
import { ButtonVariantsType } from "@/ui/Button/types"
import { IconNameType } from "@/ui/Icon"
import { LinkVariants } from "@/ui/Link/constants"
import { ROUTES } from "@/utils/constants"
import {
  CatalogResponse,
  Category,
  ChangeQtyResponse,
  Param,
  Payer,
  ProductSpecificationType,
  RequestBanner,
  Tag,
} from "../../contracts/contracts"

export interface VariantPropsType<T> {
  variant?: T
  children?: ReactNode
  maxLine?: number | undefined
}

export type ButtonTagsType = "a" | "button" | "div"

export type RadioGroupItemsType = {
  value: string | number
  message: string
}

export type UnitMeasureType = string
export type PropertiesType = {
  name?: string
  value?: string
  uuidParent?: string
  uuidValue?: string
}[]

export type IconPositionType = "left" | "right"

export interface ListItemType {
  id?: number | string
  title: string
  path: string
  qty?: number
  image?: string
}

export interface NavItemType extends ListItemType {
  subItems?: ListItemType[]
  isScroll?: boolean
  isLink?: boolean
}

export interface NavType {
  title: string
  items: NavItemType[]
}

export type LinkItemType = {
  icon?: IconNameType
  path: string
  title?: string | ReactNode
  target?: string
  fillIcon?: string
  variant?: LinkVariants
}

export type SequenceItemType = {
  uuid?: string
  id?: number
}

export interface ICategoryTreeItem extends Category {
  children: Record<string, ICategoryTreeItem>
}

export interface CategoriesTreeStateType {
  treeCompared: Record<string, Record<number, SequenceItemType[]>> | null
  treeSorted: ICategoryTreeItem[]
}

export interface CategoriesStateType extends CategoriesTreeStateType {
  fetched: Record<string, Category> | null
  treeSorted: ICategoryTreeItem[]
}

export type CategoryItemPropsType = {
  updateCurrentCategoryId?: UpdateCurrentCategoryPopupIdType
  currentId: null | string
}

export const enum SortByCatalogAlias {
  PRICE_ASC = "price_asc",
  PRICE_DESC = "price_desc",
  BY_NAME = "name",
  POPULAR = "popular",
  BY_NEW = "new",
}

export type SortType<V = string> = {
  value: V
  name: string
  icon?: IconNameType
}

export type SortByType = Record<string, SortType> | null
export type SortBySelectedType = string | null

export type CoordsType = {
  latitude: string
  longitude: string
}

export interface ChangeQtyResponseClient
  extends Omit<ChangeQtyResponse, "discount"> {
  discount: number | null
}

export interface ChangeQtyProductResponse extends ChangeQtyResponseClient {
  addedQty: number
}

export interface ChangeQtySampleResponse extends ChangeQtyResponseClient {
  quantity: number
}

export interface SpecificationItemType extends ProductSpecificationType {
  isRemoved?: boolean
  isSampleRemoved?: boolean
  parent?: string | null
  child?: Record<string, SpecificationItemType>
  isAnimate?: boolean
  kit?: string[]
}

export type WithViewProductVariantType = {
  viewProductsVariant?: ViewProductsType
}

export type PatternType = {
  value: RegExp
  message: string
}

export type ValidateRuleType = {
  value: number
  message: string
}

export type PayerListItemType = Payer

export type FieldVariantsType = "input" | "textarea"

export interface FieldVariantsPropsType
  extends VariantPropsType<FieldVariantsType> {
  name?: string
  id?: string
  placeholder?: string
  errorMessage?: string
  withAnimatingLabel?: boolean
  withButton?: boolean
  iconButton?: IconNameType
  iconField?: IconNameType
  textButton?: string
  typeButton?: ButtonHTMLAttributes<HTMLButtonElement>["type"]
  isFetching?: boolean
  iconPositionButton?: IconPositionType
  variantButton?: ButtonVariantsType
  onClickButton?: (e: MouseEvent<HTMLButtonElement>) => void
  disabledButton?: boolean
  hint?: string
  classNameButton?: string
  seoTextButton?: string
  withClean?: boolean
  cleanCb?: () => void
  required?: boolean
  isVisibleSelectedHint?: boolean
  selectedValue?: string
}

export type FieldProps = FieldVariantsPropsType &
  DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >

export type ReplacementListItem = {
  id: number
  title: string
  description: string
}

export type NextPageWithLayout<P = never> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode
}

export type OfferOrderThankType = {
  url: string
  name: string
  price: number
  count: number
  currency: "RUB"
}

export type OrderThankType = {
  uuid?: string
  id?: string
  fio?: string
  phone?: string
  email?: string
  paymentMethod?: string
  paymentAlias?: string
  totalCost?: number
  promocode?: string | null
  discount?: number
  shippingMethod?: string
  shippingCost?: number
  productsCost?: number
  shippingAddress?: string
  shippingDate?: string
  shippingAlias?: ShippingMethod
  contact?: string
  offers?: OfferOrderThankType[]
  shippingCoords: CoordsType | null
}

export type LocationType = {
  region_type: string | null
  region: string | null
  city_type: string | null
  city: string | null
  region_kladr_id: string | null
  is_guessed?: boolean
  city_en?: string
}

export type LocationExtendsType = LocationType & {
  city_full: string | null
  region_full: string | null
}

export type LastOrderReturnType = {
  fio: string
  email: string
  phone: string
  bussiness_area: string
  shipping_method?: ShippingMethod | null
  payer?: string | null
  shipping_address?: string | null
  payment_method?: string | null
  region?: string | null
  replacement?: number | null
}

export type PriceRangeItemType = { min: number; max: number }

export type VariantSortType = "asc" | "desc"

export type FilterParamType = {
  uuid?: string
  name?: string
  product_qty?: number
  image?: string
}

export type FilterType = Omit<Param, "values"> & {
  values: Record<string, FilterParamType>
}

export type ProductParamType = {
  uuid?: string
  name?: string
  image?: string
  type?: string
}

export const enum FeedParamType {
  KEYWORDS = "keywords",
  PROPERTIES = "properties",
  PARAMS = "params",
  CODE = "code",
  STATUS = "status",
}

export type CatalogResponseCategoryType = Category

export type CatalogResponseType = Omit<
  CatalogResponse,
  "filters" | "category"
> & {
  filters?: Record<string, FilterType>
  category: CatalogResponseCategoryType | null
}

export type CatalogRequestType = {
  params?: string
  price?: string
  is_enabled?: string
  is_fast?: string
  sortby?: string
  min_quantity?: string
  per_page?: string
  page?: string
  store?: string
  is_new?: string
  bestseller?: string
  categories?: string
  tag?: string
}

export type SetCategoryType = Required<CatalogResponseType["category"]>

export type SetCatalogPayloadType = {
  category: CatalogResponseType
  requestOptions: Required<CatalogRequestType>
}

export type ResponseOrderListProducts = {
  products?: string[]
  total?: number
}

export type ClearItemFilterPropsType = {
  keyFilter: string[]
  checked: boolean
  name?: string
}

export type TagType = Tag & {
  uuid?: string
  image?: string
  qty?: number
  selected?: boolean
}

export type QueryCatalogType = {
  params?: string | string[]
  price?: string
  is_enabled?: string
  is_fast?: string
  sortby?: SortByCatalogAlias
  min_quantity?: number
  per_page?: number
  page?: number
  categories?: string[]
  store?: string | string[]
  is_new?: string
  bestseller?: string
}

export type QueryFavoritesNumberType = {
  group?: string
  params?: string[]
  price?: PriceRangeItemType
  sortby?: SortByCatalogAlias
}

export type QueryFavoritesStringType = {
  group?: string
  params?: string
  price?: string
  sortby?: SortByCatalogAlias
}

export type ShortCategoryType = Pick<Category, "uuid" | "name">

export type UpdateCurrentCategoryPopupIdType = (props: {
  id: string | null
  parent?: number
}) => void

export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export type ProductOfferType = {
  uuid: string
  name: string
  description: string
  image: string
  images: string[]
  categories: string[]
  params: ProductParamType[]
  properties: PropertiesType
  is_bestseller: boolean
  is_kit: boolean
  is_kit_child: boolean
  is_new: boolean
  total_qty: number
  alias: string
  code: string
  article: string
  price: number
  keywords: string | null
  business_areas: string[]
  paramsFormat: ParamType[]
  url: string
  priceRub: number
  isAvailable: boolean
  categoryId: string
  categoryIds: string[]
  categoryUUId: string
  onlyUnit: {
    name: string
    priceRub: number
  }
}

export type FormConfigExtendsType = {
  successMessage?: string
  cbOnSubmit?: () => void
}

export type FiltersBasePropsType = {
  viewProducts: ViewPageProductsType
  updateViewProducts: (view: ViewPageProductsType) => void
}
export type RequiredAuth = { requireAuth: boolean }
export type NextPageWithRequiredAuth = NextPageWithLayout<unknown> &
  RequiredAuth
export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithRequiredAuth
}
export type PagePropsType = {
  dehydratedState: unknown
}

export type CloseModeType = "hide" | "destroy"

export type GetOrderFilterSortableType = (
  a?: string,
  b?: string,
  sort?: VariantSortType,
) => number

export type FreeShippingReturnType = { order_min_cost: number } | null

export type BannerStringTypes =
  | "main_slider"
  | "main_single"
  | "catalog_products_single"
  | "catalog_under_products_single"
export type StoreBannersType = Record<BannerStringTypes, RequestBanner[]>

export type RoutePageAliasType = keyof typeof ROUTES

export type CatalogFetcherPropsType = {
  data: CatalogRequestType
  options?: {
    isSearch?: boolean
    fields?: string[]
  }
} & FetcherBasePropsType
