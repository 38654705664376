import { cx } from "@linaria/core";
import { BaseHTMLAttributes, forwardRef, InputHTMLAttributes, MouseEvent, ReactElement, useMemo } from "react";
import { FieldVariantsPropsType } from "@/types";
import { ButtonSizeAlias } from "@/ui/Button/constants";
import { SelectedHint } from "@/ui/Select/SelectedHint";
import { cssButtonFieldClear, Placeholder, StyledFieldInput, StyledFieldWrapper, StyledHint, StyledInput } from "./StyledField";
import { Button } from "../Button/Button";
import { Icon } from "../Icon";
import { ErrorMessageField } from "../Typography/Typography";
export interface IAutoComplete {
  autoCompleteContent?: ReactElement | null;
}
export const InputField = forwardRef<HTMLInputElement, FieldVariantsPropsType & IAutoComplete & BaseHTMLAttributes<HTMLInputElement> & InputHTMLAttributes<HTMLInputElement>>(({
  autoCompleteContent = null,
  name,
  errorMessage,
  withAnimatingLabel = true,
  placeholder,
  iconButton,
  withButton,
  textButton,
  iconPositionButton = "right",
  variantButton = "box",
  isFetching,
  onClickButton,
  disabledButton,
  iconField,
  hint,
  classNameButton,
  className,
  withClean = false,
  cleanCb,
  required = false,
  isVisibleSelectedHint = false,
  selectedValue,
  seoTextButton,
  typeButton = "button",
  ...props
}, ref) => {
  const {
    value
  } = props;
  const ariaLabel = useMemo(() => variantButton === "box" ? (props || {})["aria-label"] || placeholder : textButton, [variantButton, textButton, placeholder]);
  return <StyledFieldWrapper data-animating-label={withAnimatingLabel} data-with-button={withButton} data-with-clean={withClean} data-filled={!!value} data-is-error={!!errorMessage} data-required={required}>
        <StyledFieldInput>
          <StyledInput name={name} ref={ref} placeholder={withAnimatingLabel ? " " : placeholder} className={className} {...props} />

          {withAnimatingLabel && <Placeholder htmlFor={props.id}>{placeholder}</Placeholder>}
          {withClean && !!value && <Button variant={"box"} icon={"X"} type={"button"} size={ButtonSizeAlias.SMALL} className={cx(cssButtonFieldClear)} aria-label={"Очистить"} seoText={"Очистить"} onClick={() => {
        cleanCb?.();
      }} />}
          {withButton && <Button variant={variantButton} icon={iconButton} iconPosition={iconPositionButton} isFetching={isFetching} disabled={disabledButton} className={classNameButton} onClick={(e: MouseEvent<HTMLButtonElement>) => {
        if (onClickButton) {
          onClickButton(e);
        }
      }} aria-label={ariaLabel} seoText={seoTextButton} type={typeButton}>
              {textButton}
            </Button>}
          {iconField !== undefined && <Icon NameIcon={iconField} />}

          {autoCompleteContent}
        </StyledFieldInput>

        {hint && <StyledHint>{hint}</StyledHint>}

        {!!errorMessage && <ErrorMessageField>{errorMessage}</ErrorMessageField>}

        {isVisibleSelectedHint && <SelectedHint itemSelected={selectedValue !== undefined ? {
      value: selectedValue,
      name: selectedValue
    } : undefined} />}
      </StyledFieldWrapper>;
});
InputField.displayName = "InputField";