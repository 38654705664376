import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  DigiCategoryTypeAutoComplete,
  DigiFacetDataType,
  DigiFacetType,
  DigiProductAutoCompleteType,
  DigiProductSearchType,
  DigiSortType,
  DigiStItemType,
  DigiTapItemType,
} from "@/api/digineticaAPI"
import type { ProductDetailListType } from "../../../contracts/contracts"

export type SearchHistoryItemType = {
  query: string
}

export type PayloadDistinctType = {
  name: string
  value: number | string | null
  checked: boolean
}

export type PayloadSliderType = {
  name: string
  value: {
    min: string | number
    max: string | number
  } | null
}

export type UpdateFilterPropsType<P = PayloadDistinctType> = {
  type: DigiFacetDataType
  payload: P
  isOnce?: boolean
}

export type FullScreenSearchType = {
  isShow: boolean
}

const initialState = {
  currentQuery: null as string | null,
  history: [] as SearchHistoryItemType[] | [],
  correction: null as string | null,
  query: null as string | null,
  autoComplete: {
    isShow: false as boolean,
    isNotResult: false as boolean,
    brands: [],
    query: null as string | null,
    categories: [] as DigiCategoryTypeAutoComplete[] | [],
    contents: [],
    products: [] as DigiProductAutoCompleteType[] | [],
    sts: [] as DigiStItemType[] | [], // часто ищут
    taps: [] as DigiTapItemType[] | [], // уточнения
  },
  products: [] as DigiProductSearchType[] | [],
  zeroQueries: false as boolean | false,
  facets: [] as DigiFacetType[] | [],
  selectedFacets: [] as DigiFacetType[] | [],
  total: null as number | null,
  page: null as number | null,
  productsData: [] as ProductDetailListType | [],
  filter: null as Record<string, string[]> | null,
  sort: null as DigiSortType | null,
  isUpdate: false as boolean,
  fullScreen: {
    isShow: false as boolean,
  },
}

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setCurrentQuery: (state, action: PayloadAction<string | null>) => {
      state.currentQuery = action.payload
    },
    setQuery: (state, action: PayloadAction<string | null>) => {
      state.query = action.payload
    },
    setQueryAutoComplete: (state, action: PayloadAction<string | null>) => {
      state.autoComplete.query = action.payload
    },
    setBrandsAutoComplete: (state, action: PayloadAction<[]>) => {
      state.autoComplete.brands = action.payload
    },
    setContentsAutoComplete: (state, action: PayloadAction<[]>) => {
      state.autoComplete.contents = action.payload
    },
    setCategoriesAutoComplete: (
      state,
      action: PayloadAction<DigiCategoryTypeAutoComplete[]>,
    ) => {
      state.autoComplete.categories = action.payload
    },
    setProductsAutoComplete: (
      state,
      action: PayloadAction<DigiProductAutoCompleteType[]>,
    ) => {
      state.autoComplete.products = action.payload
    },
    setStsAutoComplete: (state, action: PayloadAction<DigiStItemType[]>) => {
      state.autoComplete.sts = action.payload
    },
    setTapsAutoComplete: (state, action: PayloadAction<DigiTapItemType[]>) => {
      state.autoComplete.taps = action.payload
    },
    setHistory: (state, action: PayloadAction<SearchHistoryItemType[]>) => {
      state.history = action.payload
    },
    setCorrection: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      state.correction = action.payload || null
    },
    setProducts: (state, action: PayloadAction<DigiProductSearchType[]>) => {
      state.products = action.payload
    },
    appendProducts: (state, action: PayloadAction<DigiProductSearchType[]>) => {
      state.products = [...state.products, ...action.payload]
    },
    setTotal: (state, action: PayloadAction<number | null>) => {
      state.total = action.payload
    },
    setZeroQueries: (state, action: PayloadAction<boolean>) => {
      state.zeroQueries = action.payload
    },
    setFacets: (state, action: PayloadAction<DigiFacetType[]>) => {
      state.facets = action.payload
    },
    setSelectedFacets: (state, action: PayloadAction<DigiFacetType[]>) => {
      state.selectedFacets = action.payload
    },
    setPage: (state, action: PayloadAction<number | null>) => {
      state.page = action.payload
    },
    setProductsData: (state, action: PayloadAction<ProductDetailListType>) => {
      state.productsData = action.payload
    },
    appendProductsData: (
      state,
      action: PayloadAction<ProductDetailListType>,
    ) => {
      state.productsData = [...state.productsData, ...action.payload]
    },
    setFilter: (
      state,
      action: PayloadAction<Record<string, string[]> | null>,
    ) => {
      state.filter = action.payload
    },
    updateFilter: (
      state,
      action: PayloadAction<
        UpdateFilterPropsType<PayloadDistinctType | PayloadSliderType>
      >,
    ) => {
      const copiedFilter = { ...state.filter }

      if (action.payload.payload.value === null) {
        if (!!copiedFilter[action.payload.payload.name]) {
          delete copiedFilter[action.payload.payload.name]
        }
      } else {
        switch (action.payload.type) {
          case "DISTINCT":
          case "NUM_DISTINCT": {
            const payloadValue: PayloadDistinctType = action.payload
              .payload as PayloadDistinctType
            if (payloadValue.value === null) {
              if (!!copiedFilter[payloadValue.name]) {
                delete copiedFilter[payloadValue.name]
              }
            } else {
              if (!!action.payload.isOnce) {
                copiedFilter[payloadValue.name] = [`${payloadValue.value}`]
              } else {
                if (payloadValue.checked) {
                  copiedFilter[payloadValue.name] = [
                    ...(copiedFilter[payloadValue.name] || []),
                    `${payloadValue.value}`,
                  ]
                } else {
                  copiedFilter[payloadValue.name] = (
                    copiedFilter[payloadValue.name] || []
                  ).filter((val) => val !== `${payloadValue.value}`)
                }
              }
            }
            break
          }
          case "SLIDER": {
            const payloadValue: PayloadSliderType = action.payload
              .payload as PayloadSliderType
            copiedFilter[payloadValue.name] = [
              `${payloadValue.value?.min}`,
              `${payloadValue.value?.max}`,
            ]
            break
          }
        }
      }

      Object.keys(copiedFilter).map((key) => {
        if (copiedFilter[key].length === 0) {
          delete copiedFilter[key]
        }
      })

      state.filter = copiedFilter
    },
    setSort: (state, action: PayloadAction<DigiSortType | null>) => {
      state.sort = action.payload
    },
    setIsUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    },
    setIsShowAutoComplete: (state, { payload }: PayloadAction<boolean>) => {
      state.autoComplete.isShow = payload
    },
    setIsShowFullScreenSearch: (state, { payload }: PayloadAction<boolean>) => {
      state.fullScreen.isShow = payload
    },
    setIsNotResultAutoComplete: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.autoComplete.isNotResult = payload
    },
  },
})

export const {
  setQuery,
  setProductsAutoComplete,
  setCategoriesAutoComplete,
  setTapsAutoComplete,
  setStsAutoComplete,
  setQueryAutoComplete,
  setHistory,
  setCorrection,
  setCurrentQuery,
  setProducts,
  setTotal,
  setZeroQueries,
  setFacets,
  setProductsData,
  setPage,
  setFilter,
  updateFilter,
  setSelectedFacets,
  setSort,
  appendProductsData,
  appendProducts,
  setIsUpdate,
  setIsShowAutoComplete,
  setIsShowFullScreenSearch,
  setIsNotResultAutoComplete,
} = searchSlice.actions
