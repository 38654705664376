import { ActionTypeGTM, EventGTM } from "@/utils/analytics/constants"
import { PageViewPayloadGTMType, ProductGTMType } from "@/utils/analytics/types"
import { Currency } from "@/utils/constants"

export const initialGTM = () => {
  window["dataLayer"] = window["dataLayer"] ? window["dataLayer"] : []
}

export const trackGTM = {
  trackPageView: (payload: Omit<PageViewPayloadGTMType, "event">) => {
    initialGTM()
    window["dataLayer"].push({
      event: EventGTM.PAGEVIEW,
      ...payload,
    })
  },
  trackProductDetail: ({ product }: { product: ProductGTMType }) => {
    initialGTM()
    window["dataLayer"].push({
      ecommerce: {
        currencyCode: Currency.RUB,
        [ActionTypeGTM.DETAIL]: {
          products: [product],
        },
      },
    })
  },
  trackProductAddToCart: ({ product }: { product: ProductGTMType }) => {
    initialGTM()
    window["dataLayer"].push({
      ecommerce: {
        currencyCode: Currency.RUB,
        [ActionTypeGTM.ADD]: {
          products: [product],
        },
      },
    })
  },
  trackProductRemoveFromCart: ({ product }: { product: ProductGTMType }) => {
    initialGTM()
    window["dataLayer"].push({
      ecommerce: {
        currencyCode: Currency.RUB,
        [ActionTypeGTM.REMOVE]: {
          products: [product],
        },
      },
    })
  },
  trackPurchase: ({
    products,
    orderId,
  }: {
    products: ProductGTMType[]
    orderId: number | string
  }) => {
    initialGTM()
    window["dataLayer"].push({
      ecommerce: {
        currencyCode: Currency.RUB,
        actionField: {
          id: orderId,
        },
        [ActionTypeGTM.PURCHASE]: {
          products: products,
        },
      },
    })
  },
}
