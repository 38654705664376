import { SwiperOptions } from "swiper/types"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"

export const COMPARES_SELECTED_CATEGORY_NAME_COOKIE_KEY =
  "comparesSelectedCategory"

export const COMPARES_PRODUCTS_COOKIE_KEY = "comparesProducts"
export const COMPARES_EXPIRES_PRODUCTS_COOKIE_KEY = "comparesExpiresProducts"
export const BREAKPOINTS: {
  [p: number]: SwiperOptions
  [p: string]: SwiperOptions
} = {
  [getBreakpointVal(breakpoints.xl)]: {
    slidesPerView: 4,
    allowTouchMove: false,
  },
  [getBreakpointVal(breakpoints.md)]: {
    slidesPerView: 3,
    allowTouchMove: true,
  },
  0: {
    slidesPerView: "auto",
    allowTouchMove: true,
  },
}
export const BREAKPOINTS_NOT_AUTH: {
  [p: number]: SwiperOptions
  [p: string]: SwiperOptions
} = {
  [getBreakpointVal(breakpoints.xl)]: {
    slidesPerView: 5,
    allowTouchMove: false,
  },
  [getBreakpointVal(breakpoints.lg)]: {
    slidesPerView: 4,
    allowTouchMove: false,
  },
  [getBreakpointVal(breakpoints.md)]: {
    slidesPerView: 3,
    allowTouchMove: true,
  },
  0: {
    slidesPerView: "auto",
    allowTouchMove: true,
  },
}
