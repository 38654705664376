import { useContext } from "react"
import { CartContext } from "@/hooks/cart/context"
import { CartContextType } from "./types"

export const useCart = (): CartContextType => {
  const cartContext = useContext(CartContext)

  if (cartContext === null) {
    throw new Error("Cart context have to be provided")
  }

  return {
    ...cartContext,
  } as const
}
