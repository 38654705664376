import React, { FC } from "react"
import { Icon } from "@/ui/Icon"
import { Typography } from "@/ui/Typography/Typography"
import { HistoryTapItemPropsType } from "./types"
import { StyledResultItem, StyledResultItemInner } from "../StyledAutoComplete"

const TapItem: FC<HistoryTapItemPropsType> = ({
  tap,
  selectItem,
  query = "",
}) => {
  const text = tap.relatedSearch.replace(query, `<b>${query}</b>`)
  return (
    <StyledResultItem>
      <StyledResultItemInner
        onClick={(e) => {
          e.preventDefault()
          selectItem(tap.relatedSearch)
        }}
      >
        <Icon NameIcon={"Search"} size={"mediumM"} />
        <Typography variant={"p14"}>
          <span
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </Typography>
      </StyledResultItemInner>
    </StyledResultItem>
  )
}

export default TapItem
