import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  ComparesResponseReturnType,
  ProductDetailListType,
} from "../../../contracts"
import { getValidatedSelectedCategory } from "@/hooks/compares/helpers"
import {
  ComparePropertiesType,
  ComparesPayloadType,
} from "@/hooks/compares/types"

const initialStateProducts = {
  uuids: [],
  properties: null,
  data: [],
  visible: [],
  isLoading: false,
}

const initialState = {
  payload: null as ComparesPayloadType | null,
  products: {
    uuids: [] as string[],
    properties: null as ComparePropertiesType | null,
    data: [] as ProductDetailListType,
    visible: [] as string[],
    isLoading: false,
  },
  selectedCategory: null as string | null,
  showDiff: false as boolean,
  storageExpiresDate: null as string | null,
}

export const comparesSlice = createSlice({
  name: "compares",
  initialState,
  reducers: {
    setComparesPayload: (
      state,
      {
        payload: { response, selectedCategory },
      }: PayloadAction<{
        response: ComparesResponseReturnType[]
        selectedCategory?: string
      }>,
    ) => {
      const data = {}
      let products: string[] = []

      if (!response.length) {
        state.payload = data
        state.products = { ...initialStateProducts, uuids: products }
        state.selectedCategory = null
        return
      }

      for (const item of response) {
        data[item.uuid] = { ...item }
        products = [...products, ...item.products]
      }

      state.payload = data
      state.products.uuids = products.reduce((uniq: string[], item) => {
        return uniq.includes(item) ? uniq : [...uniq, item]
      }, [])
      state.selectedCategory = getValidatedSelectedCategory({
        comparesPayload: state.payload,
        // по-умолчанию активируем последнюю категорию из энпоинта
        // (она же будет первой, тк выводим на фронте через reverse)
        category:
          selectedCategory || response[response.length - 1].uuid || null,
      })
    },
    updateComparesPayloadByProduct: (
      state,
      {
        payload,
      }: PayloadAction<{
        product: string
        category: {
          uuid: string
          name: string
          selected?: string
        }
      }>,
    ) => {
      const {
        product: uuidProduct,
        category: {
          uuid: uuidCategory,
          name: nameCategory,
          selected: selectedCategory,
        },
      } = payload

      if (!state.payload) {
        state.payload = {}
      }

      state.payload[uuidCategory] = {
        ...(state.payload[uuidCategory] || {}),
        uuid: uuidCategory,
        name: nameCategory,
        products: [
          ...(state.payload[uuidCategory]?.products || []),
          uuidProduct,
        ],
      }
      state.products.uuids = [...state.products.uuids, uuidProduct].reduce(
        (uniq: string[], item) => {
          return uniq.includes(item) ? uniq : [...uniq, item]
        },
        [],
      )
      state.selectedCategory = getValidatedSelectedCategory({
        comparesPayload: state.payload,
        // по-умолчанию активируем последнюю категорию из энпоинта
        // (она же будет первой, тк выводим на фронте через reverse)
        category: selectedCategory || null,
      })
    },
    addProductUUID: (state, { payload }: PayloadAction<string[]>) => {
      state.products.uuids = [...state.products.uuids, ...payload].reduce(
        (uniq: string[], item) => {
          return uniq.includes(item) ? uniq : [...uniq, item]
        },
        [],
      )
    },
    removeProductUUID: (state, { payload }: PayloadAction<string[]>) => {
      const selectedCategory = state.selectedCategory

      if (!!selectedCategory && !!state.payload) {
        const currentPayloadCategory = state.payload[selectedCategory]
        const stPayload = { ...state.payload }
        const products = currentPayloadCategory.products.filter(
          (uuid) => !payload.includes(uuid),
        )

        if (!!products.length) {
          stPayload[currentPayloadCategory.uuid] = {
            ...currentPayloadCategory,
            products: products,
          }
        } else {
          delete stPayload[currentPayloadCategory.uuid]
        }

        const newSelectedCategory = getValidatedSelectedCategory({
          comparesPayload: stPayload,
          category: selectedCategory,
        })
        state.payload = newSelectedCategory === null ? {} : stPayload
        state.selectedCategory = newSelectedCategory
      }

      state.products.uuids = state.products.uuids.filter(
        (uuid) => !payload.includes(uuid),
      )
      state.products.data = state.products.data.filter(
        (p) => !payload.includes(p.uuid || ""),
      )
      state.products.visible = state.products.visible.filter(
        (p) => !payload.includes(p),
      )
    },
    setSelectedCategory: (
      state,
      { payload: category }: PayloadAction<string | null>,
    ) => {
      state.selectedCategory = getValidatedSelectedCategory({
        comparesPayload: state.payload,
        category,
      })
    },
    setProperties: (
      state,
      {
        payload,
      }: PayloadAction<{
        products: ProductDetailListType | null
        uuidsVisible?: string[]
      }>,
    ) => {
      const { products = [], uuidsVisible = [] } = payload

      if (!products?.length) {
        state.products.properties = null
        return
      }

      const matchingProductParams: Record<
        string,
        Record<
          string,
          {
            product: string
            parent?: string
            name?: string
          }
        >
      > = {}

      // хранение названий фильтров (Эффект, ЦВет, Материал ... )
      //собираем сопоставление товаров
      // с параметрами и их родителями (фильтрами)

      const filterTypes: Set<string> = new Set()

      products.forEach(({ uuid: uuidProd = "", params: paramsProd = {} }) => {
        matchingProductParams[uuidProd] = Object.entries(paramsProd).reduce(
          (
            o,
            [, { type: typeParam = "", uuid: uuidParam = "", name = "" }],
          ) => {
            if (!typeParam || !uuidParam) {
              return o
            }

            filterTypes.add(typeParam)

            return {
              ...o,
              [uuidParam]: {
                product: uuidProd,
                parent: typeParam,
                name: name,
              },
            }
          },
          {},
        )
      })

      // собираем главную структуру
      // - сами фильтры (Цвет, Размер и тп)
      // - товары, конкретной категории для этих фильтров
      // (с учетом их наличия и разного количества в одном товаре)
      const unionProductsFilters: ComparePropertiesType = {}

      for (const filterType of filterTypes) {
        if (!filterType) {
          continue
        }

        // для каждого товара собираем сопоставление
        // товар - фильтры к нему подходящие
        const productsArr: {
          product: string
          params: { uuid: string; name: string }[]
        }[] = Object.entries(matchingProductParams).map(
          ([uuidProduct, paramsProduct]) => {
            // массив для ситуаций когда могут быть
            // несколько параметров для одного типа фильтра
            // (например - несколько Цветов, Материалов ... )
            const paramsComputed = Object.entries(paramsProduct)
              .filter(([, { parent = "" }]) => parent === filterType)
              .map(([, { name = "", parent = "" }]) => ({
                uuid: parent,
                name: name,
              }))

            return {
              product: uuidProduct,
              params: paramsComputed,
            }
          },
        )

        let productsVisible: {
          product: string
          params: { uuid: string; name: string }[]
        }[] = []
        let diff = false
        let diffVisible = false

        const productsObj = productsArr.reduce(
          (o, itemProduct, currentIndex, sourceArray) => {
            if (!diff && currentIndex > 0) {
              diff = !(
                itemProduct.params.map((p) => p.name).join(",") ===
                sourceArray[currentIndex - 1].params
                  .map((p) => p.name)
                  .join(",")
              )
            }

            if (uuidsVisible.includes(itemProduct.product)) {
              productsVisible = [...productsVisible, itemProduct]
            }

            return { ...o, [itemProduct.product]: itemProduct.params }
          },
          {},
        )

        productsVisible.reduce(
          (acc, itemProduct, currentIndex, sourceArray) => {
            if (!diffVisible && currentIndex > 0) {
              diffVisible = !(
                itemProduct.params.map((p) => p.name).join(",") ===
                sourceArray[currentIndex - 1].params
                  .map((p) => p.name)
                  .join(",")
              )
            }

            return []
          },
          [],
        )

        unionProductsFilters[filterType] = {
          uuid: filterType,
          name: filterType,
          products: productsObj,
          diff: diff,
          diffVisible: diffVisible,
        }
      }

      state.products.properties = unionProductsFilters
    },
    setShowDiff: (state, { payload }: PayloadAction<boolean>) => {
      state.showDiff = payload
    },
    clearCompares: (state) => {
      state.payload = {}
      state.products = initialStateProducts
      state.selectedCategory = null
      state.storageExpiresDate = null
    },
    setProductsData: (
      state,
      { payload }: PayloadAction<ProductDetailListType>,
    ) => {
      state.products.data = payload
    },
    setProductsVisible: (state, { payload }: PayloadAction<string[]>) => {
      state.products.visible = payload
    },
    setProductsIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.products.isLoading = payload
    },
    setStorageExpiresDate: (
      state,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.storageExpiresDate = payload
    },
  },
})

export const {
  setComparesPayload,
  updateComparesPayloadByProduct,
  addProductUUID,
  removeProductUUID,
  setSelectedCategory,
  setProperties,
  setShowDiff,
  clearCompares,
  setProductsData,
  setProductsVisible,
  setProductsIsLoading,
  setStorageExpiresDate,
} = comparesSlice.actions
