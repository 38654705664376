import { parseCookies } from "universal-cookie/lib/utils"
import { ServerResponse } from "http"
import { GetServerSidePropsContext } from "next"
import { COOKIE_USER_KEY } from "@/hooks/auth/constants"
import { User } from "@/hooks/auth/types"
import { cookies, getExpireOneYear } from "@/utils/common/helpers"

export const getServerUser = ({
  req,
}: Pick<GetServerSidePropsContext, "req">): User | null => {
  const cookie = parseCookies(req.headers.cookie)
  const user = cookie[COOKIE_USER_KEY]
  return !!user ? (typeof user === "string" ? JSON.parse(user) : user) : null
}

export const getClientUserStorage = (): User | null => {
  const user = cookies.get(COOKIE_USER_KEY)
  return user || null
}

export const setUserStorage = (
  user: User | null,
  res?: ServerResponse,
): void => {
  if (user === null) {
    if (res !== undefined) {
      res.setHeader("Set-Cookie", `${COOKIE_USER_KEY}=null; Path=/; Max-Age=0`)
    } else {
      cookies.remove(COOKIE_USER_KEY, { path: "/" })
    }
  } else {
    if (res !== undefined) {
      res.setHeader(
        "Set-Cookie",
        `${COOKIE_USER_KEY}=${encodeURIComponent(
          JSON.stringify(user),
        )}; Path=/; Expires=${getExpireOneYear().toUTCString()}`,
      )
    } else {
      cookies.set(COOKIE_USER_KEY, JSON.stringify(user), {
        path: "/",
        expires: getExpireOneYear(),
      })
    }
  }
}
