import { cx } from "@linaria/core";
import NumberFormat from "react-number-format";
import { memo, useEffect, useState } from "react";
import type { ElementType, FC } from "react";
import { cssSeoText } from "@/styles/utils/Utils";
import { Span } from "@/ui/Typography/Typography";
import { CURRENCY, CURRENCY_SHORT_NAME } from "@/utils/constants";
import { convertPennyToRub } from "@/utils/common/helpers";
import { cssPriceClean, cssPriceWithBg, StyledPrice, StyledPriceContainer, StyledPriceWrapper, StyledSpanPriceWrapper, StyledTotalPriceWrapper, StyledUnitPriceWrapper, Unit } from "./StyledPrice";
type PriceVariantType = "unit" | "total" | "span";
type PricePropsType = {
  variant?: PriceVariantType;
  price: number;
  unitMeasure?: string | null;
  messageImportant?: string;
  isClean?: boolean;
  isSale?: boolean;
  prefix?: string;
  withBg?: boolean;
  as?: ElementType;
};
const matching: Record<PriceVariantType, () => typeof StyledSpanPriceWrapper | typeof StyledUnitPriceWrapper> = {
  unit: () => StyledUnitPriceWrapper,
  total: () => StyledTotalPriceWrapper,
  span: () => StyledSpanPriceWrapper
};
export const Price: FC<PricePropsType> = memo(({
  variant = "unit",
  price,
  unitMeasure,
  messageImportant,
  isClean,
  isSale,
  prefix,
  withBg,
  as,
  children
}) => {
  const [msg, setMsg] = useState(messageImportant || "");
  const [Wrapper, setWrapper] = useState<typeof StyledPriceWrapper | typeof StyledSpanPriceWrapper>(matching[variant]());
  useEffect(() => {
    if (variant === "unit") {
      setMsg(messageImportant === undefined ? "" : messageImportant);
    }
    setWrapper(matching[variant]());
  }, [messageImportant, variant]);
  const isVisiblePrice = price > 0 || isSale;
  return <Wrapper className={cx(isClean && cssPriceClean, withBg && cssPriceWithBg)} as={as}>
        <StyledPriceContainer>
          <StyledPrice>
            {isVisiblePrice ? <>
                <NumberFormat prefix={prefix !== undefined ? prefix : isSale && price < 0 ? `−` : undefined} value={convertPennyToRub(price)} displayType={"text"} thousandSeparator={" "} renderText={(formattedValue: string) => <Span>
                      {formattedValue}{" "}
                      <Span className={cssSeoText}>{CURRENCY_SHORT_NAME}</Span>
                    </Span>} />
                <Unit>
                  {CURRENCY} {unitMeasure && ` / ${unitMeasure}`}
                </Unit>
              </> : <>{msg}</>}
          </StyledPrice>
          {children}
        </StyledPriceContainer>
      </Wrapper>;
});
Price.displayName = "Price";