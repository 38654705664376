import { CONTACTS, TITLE_SITE_RU } from "@/utils/constants"

export const META_PRODUCT = {
  partTitleEnd: `купить в интернет-магазине ${TITLE_SITE_RU} по выгодной цене в Волгограде`,
  partDescriptionEnd: `купить в Волгограде ✔ Оптом и в розницу ✔ Бесплатная доставка ✔ Более 15000 товаров ✔ Индивидуальные предложения для клиентов ☎  ${CONTACTS.phone.title}`,
}

export const META_CATALOG = {
  partTitleEnd: `купить по цене интернет-магазина ${TITLE_SITE_RU} в Волгограде`,
  partDescriptionEnd: `в Волгограде в интернет-магазине «${TITLE_SITE_RU}». ✔ Широкий ассортимент. ✔ Доставка по РФ. ✔ Индивидуальные предложения для клиентов. ☎  ${CONTACTS.phone.title}`,
}

export const META_BLOG = {
  partTitleEnd: ` - Блог ${TITLE_SITE_RU}`,
  partDescriptionEnd: ` - Блог ${TITLE_SITE_RU}`,
}

export const META_TITLE_DEFAULT = `${TITLE_SITE_RU} - товары для бизнеса оптом по низким ценам`
export const META_DESCRIPTION_DEFAULT = `Товары для бизнеса ${META_CATALOG.partDescriptionEnd}`
