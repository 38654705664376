import { useCallback } from "react"
import {
  FeedbackMesageFormatType,
  FeedbackTranslit,
  toBoldFormat,
  toMessageFormatPayload,
  toPayloadFormatUser,
  useUserPayload,
} from "@/hooks/feedback"
import { useAppSelector } from "@/hooks/redux"
import { post } from "@/service/fetcher"
import { SITE_URL } from "@/utils/constants"
import { getPlatformDevice } from "@/utils/navigator"

export const fetchDevLog = async ({
  text,
  isSiteUrl,
}: {
  text: string
  isSiteUrl?: boolean
}) =>
  post(
    `${isSiteUrl ? SITE_URL : ""}/api/devlog/send`,
    {
      text,
    },
    false,
    undefined,
    undefined,
    undefined,
  )

export const sendMessageDevLog = async ({
  text,
  isSiteUrl,
}: {
  text: string
  isSiteUrl?: boolean
}) =>
  fetchDevLog({
    text: `${text}`,
    isSiteUrl,
  }).catch((err) => console.log("send devlog message err: ", err))

const useDevLog = () => {
  const { isInit } = useAppSelector((state) => state.profile)

  const { getUserLogPayload } = useUserPayload()

  const sendLogMessage = useCallback(
    (data: Pick<FeedbackMesageFormatType, "hash" | "topic" | "payload">) => {
      if (!isInit) {
        return
      }

      const userString = toPayloadFormatUser(getUserLogPayload(true))

      const { hash, payload = [], topic } = data

      const dateNow = new Date()

      sendMessageDevLog({
        text: toMessageFormatPayload({
          hash: hash || "devlog",
          topic: topic || "devlog",
          payload: [
            {
              label: "Время",
              value: `${dateNow.toLocaleDateString()} ${dateNow.toLocaleTimeString()}`,
            },
            ...payload,
            {
              label: "Платформа",
              value: getPlatformDevice(),
            },
          ],
          customMessage: `${toBoldFormat(
            `\n${FeedbackTranslit.user}:`,
          )}\n${userString}`,
        }),
      }).catch((err) => console.log("sendMessageDevLog error ", err))
    },
    [getUserLogPayload, isInit],
  )

  return {
    sendLogMessage,
  }
}

export default useDevLog
