import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { ProductType } from "@/components/Products/types"
import { TogglePageMethod } from "@/hooks/filterCatalog/constants"
import {
  INITIAL_PAGE,
  INITIAL_PER_PAGE,
  INITIAL_STATE,
  LIMIT_LAST_WATCHED,
  ViewMode,
} from "@/hooks/filterHistory/constants"
import {
  createFiltersChecked,
  validateStateOrder,
} from "@/hooks/filterHistory/helpers"
import { QueryFiltersHistoryStringType } from "@/hooks/filterHistory/types"
import { OrderStateNumber } from "@/hooks/order/constants"
import { RootStateType } from "@/store/store"
import { FilterType } from "@/types"
import { getUniqueByKeys, matchKeys } from "@/utils/common/helpers"
import { OrderType, ProductDetailListType } from "../../../contracts"
import { OrderList } from "../../../contracts/src/api/account"

const initialState = {
  isInit: false as boolean,
  orderDetail: null as OrderType | null,
  orders: {
    total: 0 as number,
    items: null as Record<string, OrderType> | null,
  },
  products: {
    total: 0 as number,
    items: null as Record<string, ProductType> | null,
  },
  filter: {
    filters: {} as Record<string, FilterType>,
    filtersChecked: {} as Record<string, string[]>,
    state: null as (OrderStateNumber | typeof INITIAL_STATE) | null,
    category: null as string | null,
    from: null as string | null,
    to: null as string | null,
  },
  search: null as string | null,
  page: INITIAL_PAGE as number,
  itemsPerPage: INITIAL_PER_PAGE as number,
  togglePageMethod: TogglePageMethod.SWITCH as TogglePageMethod,
  viewMode: ViewMode.ORDERS as ViewMode,
  watchedRecentProducts: {
    keys: null as string[] | null,
    products: null as ProductDetailListType | null,
    exclude: [] as string[],
  },
}

export const historyAccountSlice = createSlice({
  name: "historyAccount",
  initialState,
  reducers: {
    setTotalOrders(state, action: PayloadAction<number>) {
      state.orders.total = action.payload
    },
    setTogglePageMethod(state, action: PayloadAction<TogglePageMethod>) {
      state.togglePageMethod = action.payload
    },
    setWatchedRecentProductsIds(state, { payload }: PayloadAction<string[]>) {
      state.watchedRecentProducts.keys = payload
    },
    addWatchedRecentProductsIds(state, { payload }: PayloadAction<string[]>) {
      state.watchedRecentProducts.keys = matchKeys({
        stateKeys: state.watchedRecentProducts.keys,
        newKeys: payload,
        limit: LIMIT_LAST_WATCHED,
      })
    },
    addWatchedRecentProducts(
      state,
      { payload }: PayloadAction<ProductDetailListType>,
    ) {
      state.watchedRecentProducts.products = !!state.watchedRecentProducts
        .products
        ? getUniqueByKeys(
            [...state.watchedRecentProducts.products, ...payload],
            ["uuid"],
          )
        : payload
    },
    addExcludeWatchedRecentProduct(
      state,
      { payload }: PayloadAction<string[]>,
    ) {
      state.watchedRecentProducts.exclude = [
        ...state.watchedRecentProducts.exclude,
        ...payload,
      ]
    },
    removeExcludeWatchedRecentProduct(
      state,
      { payload }: PayloadAction<string[]>,
    ) {
      state.watchedRecentProducts.exclude =
        state.watchedRecentProducts.exclude.filter(
          (uuid) => !payload.includes(uuid),
        )
    },
    setTotalProducts(state, action: PayloadAction<number>) {
      state.products.total = action.payload
    },
    setHistoryProducts(
      state,
      action: PayloadAction<{
        products: ProductType[] | null
        queueKeys: string[]
      }>,
    ) {
      // при выборе Категории в фильтрах
      // товары из этой категории должны выводиться первыми
      // получили нужны порядок ключей и хаотичный порядок товаров
      // сопоставляем порядок и данные товаров
      if (action.payload.products !== null) {
        const items = {}
        let products = [...action.payload.products]
        for (const uuid of action.payload.queueKeys) {
          const foundProduct = products.find((p) => p.uuid === uuid)
          if (!foundProduct) {
            continue
          }
          items[uuid] = { ...foundProduct }
          products = products.filter((p) => p.uuid !== uuid)
        }

        if (products.length > 0) {
          for (const prod of products) {
            if (!prod.uuid) {
              continue
            }
            items[prod.uuid] = { ...prod }
          }
        }

        state.products.items = items
      } else {
        state.products.items = null
      }
    },
    updateOrderState: (
      state,
      action: PayloadAction<{ uid: string; state: OrderStateNumber }>,
    ) => {
      if (state.orders.items !== null) {
        const order = state.orders.items[action.payload.uid]
        if (order !== undefined) {
          state.orders.items[action.payload.uid].state = action.payload.state
        }
      }
    },
    setOrders: (state, action: PayloadAction<OrderList[]>) => {
      if (action.payload !== null) {
        const orders = {}
        for (const order of action.payload) {
          if (order.uid == undefined) {
            continue
          }
          orders[order.uid] = { ...order }
        }
        state.orders.items = orders
      } else {
        state.orders.items = action.payload
      }
    },
    setHistory: (
      state,
      {
        payload: { extendsFilters, query },
      }: PayloadAction<{
        extendsFilters: Record<string, FilterType>
        query: Required<QueryFiltersHistoryStringType>
      }>,
    ) => {
      // предполагаем что все что находится в урле кроме деструктуризированных переменных
      // является фильтрами - filtersParams
      const {
        page,
        per_page,
        state: stateOrder,
        category,
        search,
        from,
        to,
        ...filtersParams
      } = query

      const params: Record<string, string[]> = Object.entries(
        filtersParams,
      ).reduce((prev, [filter, values]) => {
        return { ...prev, [filter]: values.split(",").filter((i) => !!i) }
      }, {})

      state.page = +page
      state.itemsPerPage = +per_page

      state.filter.filters = extendsFilters
      state.filter.filtersChecked = createFiltersChecked({
        filters: extendsFilters,
        queryGroupParams: params,
      })

      state.filter.state = validateStateOrder(stateOrder) ?? INITIAL_STATE

      state.filter.category = category || null

      state.filter.from = from || null
      state.filter.to = to || null

      state.search = search

      state.isInit = true
    },
    setViewMode: (
      state,
      {
        payload: { view },
      }: PayloadAction<{
        view: ViewMode
      }>,
    ) => {
      state.viewMode = view
    },
    setOrderDetail: (state, { payload }: PayloadAction<OrderType | null>) => {
      state.orderDetail = payload
    },
  },
  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      return {
        ...state,
        orderDetail: action.payload.historyAccount
          .orderDetail as RootStateType["historyAccount"]["orderDetail"],
      }
    })
  },
})

export const {
  setHistoryProducts,
  setTotalProducts,
  setTotalOrders,
  setOrders,
  updateOrderState,
  setHistory,
  setViewMode,
  setOrderDetail,

  setWatchedRecentProductsIds,
  addWatchedRecentProductsIds,
  addWatchedRecentProducts,
  addExcludeWatchedRecentProduct,
  removeExcludeWatchedRecentProduct,
} = historyAccountSlice.actions
