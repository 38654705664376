import {
  deleteFetch,
  FetcherBasePropsType,
  get,
  getAbsolutePath,
  post,
} from "@/service/fetcher"
import {
  ProductDetailListType,
  ProductDetailResponse,
} from "../../contracts/contracts"

export const fetchProductsList = ({
  uuids,
  server,
  mockError,
}: FetcherBasePropsType & {
  uuids: string | null
  mockError?: boolean
}) =>
  get<ProductDetailListType>(
    `${getAbsolutePath(server)}/product/lis${mockError ? "" : "t"}?uuid=${
      uuids || ""
    }`,
  )

export const fetchProductById = ({
  server,
  uuid,
  fields,
  req,
  res,
}: FetcherBasePropsType & {
  uuid: string
  fields?: (keyof ProductDetailResponse)[]
}): Promise<ProductDetailResponse> =>
  get<ProductDetailResponse>(
    `${getAbsolutePath(server)}/product/${uuid}${
      !!fields ? `?fields=${fields.join(",")}` : ""
    }`,
    false,
    req,
    res,
  )

export const fetchProductWaiting = ({
  uuid,
  ...data
}: {
  uuid: string
  email: string
}): Promise<null> =>
  post<null, { email: string }>(`/product/waiting/${uuid}`, data, true)

export const fetchProductsWaiting = (): Promise<string[]> =>
  get(`/account/waiting-products`, true)

export const fetchProductWaitingRemove = (data: {
  product: string
}): Promise<null> =>
  deleteFetch<
    null,
    {
      product: string
    }
  >(`/account/waiting-products`, data, true)
