import { cx } from "@linaria/core";
import { FC } from "react";
import { cssTagRectification } from "@/components/Tags/StyledTags";
import { TagDefault } from "@/components/Tags/TagDefault";
import { ButtonProps } from "@/ui/Button/types";
const TagRectification: FC<Omit<ButtonProps, "ref">> = ({
  className,
  ...props
}) => {
  return <TagDefault className={cx(cssTagRectification, className)} {...props} />;
};
export { TagRectification };