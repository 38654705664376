import { FC } from "react"
import { PromptModal } from "@/components/PWA/Prompt/PromptModal"
import { getLastPromptPWAFromStorage } from "@/hooks/usePWA/helpers"
import { usePWA } from "@/hooks/usePWA/usePWA"
import { PLATFORM } from "@/utils/constants"
import { getPlatformDevice } from "@/utils/navigator"

const PromptPWA: FC = () => {
  const { isShowPrompt } = usePWA()

  if (!isShowPrompt || !!getLastPromptPWAFromStorage()) {
    return null
  }

  const platform = getPlatformDevice()

  if (platform === PLATFORM.UNKNOWN) {
    return null
  }

  return <PromptModal platform={platform} />
}

export { PromptPWA }
