import { FC, memo } from "react"
import { Icon, IconSizeAliasType } from "@/ui/Icon"
import { StyledSpin } from "./StyledSpin"

export const Spin: FC<{ size?: IconSizeAliasType }> = memo(({ size }) => {
  return (
    <StyledSpin>
      <Icon NameIcon={"CircleLoading"} size={size} />
    </StyledSpin>
  )
})

Spin.displayName = "Spin"
