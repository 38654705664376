import { EnhancedStore } from "@reduxjs/toolkit/dist/configureStore"
import { QueryClient } from "react-query"
import { fetchCategories } from "@/api/catalogAPI"
import {
  setBusinessAreas,
  setCategories,
} from "@/store/reducers/categoriesSlice"
import { RootStateType } from "@/store/store"

const getServerSideProps =
  (reduxStore: EnhancedStore<RootStateType>) => async () => {
    const queryClient = new QueryClient()

    const categoriesData = await queryClient.fetchQuery(["categories"], () =>
      fetchCategories({
        server: true,
      }),
    )

    if (!!categoriesData) {
      const { categories, bussinessAreas } = categoriesData
      reduxStore.dispatch(setCategories(categories || []))
      reduxStore.dispatch(setBusinessAreas(bussinessAreas || []))
    }

    return {
      // data
    }
  }

export { getServerSideProps as categoriesServerProps }
