import { cx } from "@linaria/core";
import type { BaseHTMLAttributes, FC } from "react";
import NextLink, { LinkProps } from "next/link";
import { cssTextIsHidden } from "@/ui/Link/StyledLink";
import { LinkVariants, matching } from "./constants";
export type LinkPropsType = Omit<LinkProps, "passHref"> & {
  variant?: LinkVariants;
  textIsHidden?: boolean;
} & BaseHTMLAttributes<HTMLAnchorElement>;
export const Link: FC<LinkPropsType> = ({
  href,
  children,
  variant = LinkVariants.DEFAULT,
  className,
  textIsHidden = false,
  ...rest
}) => {
  const LinkComponent = matching[variant];
  return <NextLink href={href} {...rest} className={className} passHref>
      <LinkComponent aria-label={rest["aria-label"]} title={rest.title} target={rest.target} className={cx(textIsHidden && cssTextIsHidden, className)}>
        {children}
      </LinkComponent>
    </NextLink>;
};