import { cx } from "@linaria/core";
import type { FC } from "react";
import { memo } from "react";
import { BaseLoaderPropsType } from "@/ui/Loaders/BaseLoader/types";
import { Typography } from "@/ui/Typography/Typography";
import { cssIsFixed, cssIsRelative, StyledLoaderContainer, StyledLoaderContent, StyledLoaderOverlay } from "./StyledBaseLoader";
import { Spin } from "../Spin";
export const BaseLoader: FC<BaseLoaderPropsType> = memo(({
  isFixed = false,
  isRelative = false,
  message,
  className,
  orientation = "column",
  spinSize = "largeXL",
  ...rest
}) => {
  return <StyledLoaderContainer {...rest} className={cx(isFixed && cssIsFixed, isRelative && cssIsRelative, className)}>
        {message !== undefined ? <StyledLoaderContent orientation={orientation}>
            <Spin size={spinSize} />
            <Typography variant={"p14"}>{message}</Typography>
          </StyledLoaderContent> : <Spin size={spinSize} />}
        <StyledLoaderOverlay />
      </StyledLoaderContainer>;
});
BaseLoader.displayName = "BaseLoader";