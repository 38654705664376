import { post } from "@/service/fetcher"

export const fetchLogInstallPWA = ({ text }: { text: string }) =>
  post(
    `/api/pwa/installed`,
    {
      text,
    },
    false,
    undefined,
    undefined,
    undefined,
  )
