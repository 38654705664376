import { fetchLogFeedback } from "@/api/feedbackAPI"
import { ProductType } from "@/components/Products"
import {
  FeedbackMesageFormatType,
  FeedbackPayloadType,
} from "@/hooks/feedback/types"
import { getProductPath } from "@/hooks/product/helpers"

// @see: https://core.telegram.org/api/entities

export const toCodeFormat = (content: string) => `<code>${content}</code>`
export const toBoldFormat = (content: string) => `<b>${content}</b>`

export const toPayloadFormatUser = (
  payload: { label: string; value: string }[],
  separator = "\n",
) =>
  payload.map(({ label, value }) => [label, value].join(": ")).join(separator)

export const toPayloadFormatProducts = (
  products: ProductType[],
): FeedbackPayloadType[] => {
  const startPoint: FeedbackPayloadType = {
    label: `\n${toBoldFormat("Товар")}`,
    value: "",
  }
  const productsPayload: FeedbackPayloadType[] = products.reduce(
    (acc: FeedbackPayloadType[], { name = "", alias = "", uuid = "" }) => [
      ...acc,
      {
        label: "Название",
        value: name,
      },
      {
        label: "Ссылка",
        value: toCodeFormat(getProductPath(alias, true)),
      },
      {
        label: "UUID",
        value: toCodeFormat(uuid),
      },
    ],
    [],
  )

  return [startPoint, ...productsPayload]
}

export const toMessageFormatPayload = ({
  hash,
  topic,
  payload = [],
  customMessage = "",
}: FeedbackMesageFormatType) => {
  const hashMsg = !!hash ? `#${hash}\n` : ""
  const payloadMsg = toPayloadFormatUser(payload)
  const topicMsg = toBoldFormat(`Тема: ${topic}`)

  return [hashMsg, topicMsg, payloadMsg, customMessage]
    .filter((m) => !!m)
    .join("\n")
}

export const sendMessageFeedback = ({ text }: { text: string }) => {
  fetchLogFeedback({
    text: `${text}`,
  })
    .then((res) => console.log("loggerForm res: ", res))
    .catch((err) => console.log("loggerForm err: ", err))
}
