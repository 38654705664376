import { ROUTES } from "@/utils/constants"

const COOKIE_CART_TOKEN_KEY = "cart"

const ROUTES_ENABLE_STATUS_ORDERING = [ROUTES.checkout]

export { COOKIE_CART_TOKEN_KEY, ROUTES_ENABLE_STATUS_ORDERING }

/**
 * `1` - в процессе добавления товаров *
 * `8` - в процессе оформления заказа
 */
export const enum CART_STATUS {
  IN_PROCESS_ADD_PRODUCTS = 1,
  IN_PROCESS_ORDERING = 8,
}
