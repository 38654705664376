import { useCallback, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../redux"
import {
  NotificationHidePropsType,
  NotificationPushPropsType,
  UseNotificationReturnType,
} from "@/hooks/notification/types"
import {
  hideNotification as hideNotificationAction,
  hideNotification,
  pushNotification as pushNotificationAction,
  replaceNotification as replaceNotificationAction,
} from "@/store/reducers/notificationsSlice"

export const useNotifications = (): UseNotificationReturnType => {
  const dispatch = useAppDispatch()
  const notifications = useAppSelector(
    ({ notifications }) => notifications.notifications,
  )

  const setNotificationTimer = useCallback(
    (index: number, hideTimeout?: number | null) => {
      if (hideTimeout != null) {
        return setTimeout(() => {
          dispatch(hideNotificationAction({ index }))
        }, hideTimeout)
      }
    },
    [dispatch],
  )

  const push = useCallback(
    ({
      kind,
      replace = false,
      image,
      list,
      hideTimeout = 3000,
    }: NotificationPushPropsType) => {
      if (replace) {
        const index = notifications.length - 1

        if (index === -1) {
          dispatch(
            pushNotificationAction({
              kind,
              image,
              list,
              t: setNotificationTimer(index, hideTimeout),
            }),
          )
        } else {
          const existingNotification = notifications[index]

          if (existingNotification.kind === kind) {
            const { t } = existingNotification

            clearTimeout(t)

            dispatch(
              replaceNotificationAction({
                index,
                kind,
                image,
                list,
                t: setNotificationTimer(index, hideTimeout),
              }),
            )
          } else {
            dispatch(
              pushNotificationAction({
                kind,
                image,
                list,
                t: setNotificationTimer(index, hideTimeout),
              }),
            )
          }
        }
      } else {
        const index = notifications.length

        dispatch(
          pushNotificationAction({
            kind,
            image,
            list,
            t: setNotificationTimer(index, hideTimeout),
          }),
        )
      }
    },
    [notifications, dispatch, setNotificationTimer],
  )

  const hide = useCallback(
    ({ index }: NotificationHidePropsType) => {
      dispatch(hideNotification({ index }))
    },
    [dispatch],
  )

  return useMemo(
    () =>
      ({
        push: push,
        hide: hide,
      } as const),
    [hide, push],
  )
}
