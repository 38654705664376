import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react"
import { useRouter } from "next/router"
import { Category } from "../../../contracts"
import { useAuth } from "../auth"
import { useAppDispatch, useAppSelector } from "../redux"
import { NotificationType } from "@/components/Notifications/NotificationModal/NotificationModal"
import { useApp } from "@/hooks/app/app"
import { useModals } from "@/hooks/modals/useModals"
import { useScrollDirection } from "@/hooks/scrollDirection"
import { setPopupIsShow } from "@/store/reducers/catalogSlice"
import { popupsAlias } from "@/store/reducers/commonSlice"
import { ROUTES } from "@/utils/constants"
import { scrollBodyDisable, scrollBodyEnable } from "@/utils/common/helpers"

type UseHeaderReturnType = {
  isShowPopup: boolean
  isShowMenu: boolean
  isShowMiniCart: boolean
  catalogShowedToggle: (on?: boolean) => void
  menuHide: () => void
  menuShow: () => void
  businessAreas: Category[] | null
  notificationAuth: NotificationType | null
  setNotificationAuth: (notification: NotificationType | null) => void
}

export type HeaderRectType = {
  offsetTop: number
  clientHeight: number
}

export type UseLayoutHeaderReturnType = {
  enabledOverlay: boolean
  isOverlay: boolean
  isFixed: boolean
  setEnabledOverlay: (val: boolean) => void
  setIsOverlay: (val: boolean) => void
  setIsFixed: (val: boolean) => void
  setRect: (val: HeaderRectType | null) => void
  rect: HeaderRectType | null
}

type HeaderContextPropsType = UseHeaderReturnType

const HeaderContext = createContext<null | HeaderContextPropsType>(null)

export function Provider({
  children,
}: {
  children?: ReactNode
  host?: string
}): JSX.Element {
  const {
    menuHeader: { isShow: isShowMenu },
    miniCartHeader: { isShow: isShowMiniCart },
  } = useAppSelector(({ common }) => common.modals)

  const { show, hide } = useModals()

  const {
    notification: notificationAuth,
    setNotification: setNotificationAuth,
  } = useAuth()

  const { businessAreas } = useAppSelector(({ categories }) => categories)
  const {
    popup: { isShow: catalogPopupIsShow },
    currentCategory,
  } = useAppSelector(({ catalog }) => catalog)
  const dispatch = useAppDispatch()

  const router = useRouter()

  const menuHide = useCallback(() => {
    hide({
      alias: popupsAlias.menuHeader,
    })
    scrollBodyEnable()
  }, [hide])

  const menuShow = useCallback(() => {
    scrollBodyDisable()
    show({
      alias: popupsAlias.menuHeader,
    })
  }, [show])

  const catalogShowedToggle = useCallback(
    (on?: boolean) => {
      dispatch(setPopupIsShow(on === undefined ? !catalogPopupIsShow : on))
    },
    [catalogPopupIsShow, dispatch],
  )

  const {
    layouts: {
      header: { setIsOverlay, setIsFixed, setEnabledOverlay },
    },
  } = useApp()

  const scroll = useScrollDirection()

  useEffect(() => {
    const cleanShowedDialogs = () => {
      menuHide()
      hide({
        alias: popupsAlias.miniCartHeader,
      })
    }

    const routeChangeHandle = () => {
      dispatch(setPopupIsShow(false))
      cleanShowedDialogs()
    }

    router.events.on("routeChangeComplete", routeChangeHandle)

    return () => {
      router.events.off("routeChangeComplete", routeChangeHandle)
    }
  }, [router.events, dispatch, menuHide, hide])

  useEffect(() => {
    const enabledOverlay =
      !!currentCategory?.desktop &&
      router.pathname.includes(ROUTES.catalog) &&
      router.pathname !== ROUTES.catalog
    setIsFixed(enabledOverlay)
    setEnabledOverlay(enabledOverlay)
    setIsOverlay(enabledOverlay ? scroll === null : false)
  }, [
    scroll,
    setIsOverlay,
    setIsFixed,
    setEnabledOverlay,
    currentCategory?.desktop,
    router.pathname,
  ])

  const contextValue = useMemo(
    () => ({
      businessAreas: businessAreas,
      isShowMenu: isShowMenu,
      isShowMiniCart: isShowMiniCart,
      isShowPopup: catalogPopupIsShow,
      catalogShowedToggle: catalogShowedToggle,
      notificationAuth: notificationAuth,
      menuHide: menuHide,
      menuShow: menuShow,
      setNotificationAuth,
    }),
    [
      businessAreas,
      isShowMenu,
      isShowMiniCart,
      catalogPopupIsShow,
      catalogShowedToggle,
      notificationAuth,
      menuHide,
      menuShow,
      setNotificationAuth,
    ],
  )

  return (
    <HeaderContext.Provider value={contextValue}>
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeader = (): UseHeaderReturnType & HeaderContextPropsType => {
  const headerContext = useContext(HeaderContext)

  if (headerContext === null) {
    throw new Error("Header context have to be provided")
  }

  return {
    businessAreas: headerContext.businessAreas,
    isShowMenu: headerContext.isShowMenu,
    isShowMiniCart: headerContext.isShowMiniCart,
    isShowPopup: headerContext.isShowPopup,
    catalogShowedToggle: headerContext.catalogShowedToggle,
    notificationAuth: headerContext.notificationAuth,
    menuHide: headerContext.menuHide,
    menuShow: headerContext.menuShow,
    setNotificationAuth: headerContext.setNotificationAuth,
  } as const
}
