export const enum FeedbackTopicHash {
  feedback = "feedback",
  findCheaper = "findCheaper",
  notFindNeed = "notFindNeed",
  recall = "recall",
  subscribe = "subscribe",
  reportAdmission = "reportAdmission",
  videoPresentation = "videoPresentation",
}

export const enum FeedbackTranslit {
  link = "Ссылка",
  phone = "Телефон",
  email = "Email",
  name = "Имя",
  fio = "ФИО",
  id = "ID",
  message = "Сообщение",
  question = "Вопрос",
  user = "Пользователь",
  monthQty = "Разовое количество (шт.)",
  onceQty = "Количество на месяц (шт.)",
}
