import React from "react"
import {
  BoxShadowPopover,
  breakpoints,
  colors,
  fontDefault,
  fontSizeDefault,
} from "@/styles/utils/vars"
import { getBoxSize } from "@/ui/Button/helpers"

const SPINNER_SIZE = 44

const GlobalStyles = () => {
  return (
    <>
      <style jsx global={true}>{`
        #__next {
          height: 100%;
          position: relative;
        }

        html {
          font-family: ${fontDefault};
          color: ${colors.black};
          font-size: ${fontSizeDefault};
          height: 100%;
        }

        html,
        body {
          font-size: 16px;
          line-height: 187%;
          font-weight: 500;
          padding: 0;
          margin: 0;
          -webkit-text-size-adjust: none;
          text-rendering: optimizeSpeed;
          // обязательно width 100% при открытии модальных окон
          // - body становится fixed, на ios все ломается
          width: 100%;
          scroll-behavior: smooth;
        }

        html #nprogress .bar {
          background: ${colors.brand.purple};
          height: 8px;
        }

        html #nprogress .peg {
          box-shadow: 0 0 10px ${colors.brand.purple},
            0 0 5px ${colors.brand.purpleDarken};
        }

        html #nprogress .spinner-icon {
          border-top-color: ${colors.brand.purple};
          border-left-color: ${colors.brand.purple};
          width: 100%;
          height: 100%;
          border-width: 2px;
        }

        html #nprogress .spinner-box {
          ${getBoxSize(SPINNER_SIZE)};
          background: white;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.6em;
          border: 2px solid ${colors.gray};
          box-shadow: ${BoxShadowPopover};
        }

        html #nprogress .spinner {
          right: 50%;
          margin-right: -${SPINNER_SIZE / 2}px;
          top: 20px;
          display: none;
        }

        @media (max-width: ${breakpoints.md}) {
          html #nprogress .bar {
            background: ${colors.white};
            display: none;
          }

          html #nprogress .peg {
            box-shadow: 0 0 10px ${colors.white}, 0 0 5px ${colors.white};
          }

          html #nprogress .spinner {
            display: block;
          }
        }

        body {
          padding-bottom: env(safe-area-inset-bottom);
          -webkit-overflow-scrolling: touch;
          position: relative;
          top: 0;
        }

        *,
        *:before,
        *:after {
          box-sizing: border-box;
          outline: none;
        }

        * {
          outline: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }

        img {
          max-width: 100%;
        }

        a {
          text-decoration: none;
          color: ${colors.brand.purple};
          -webkit-tap-highlight-color: transparent;
        }

        a:hover,
        a:active {
          color: ${colors.brand.purpleDarken};
        }

        label {
          -webkit-tap-highlight-color: transparent;
        }

        .grecaptcha-badge {
          z-index: 10;
          display: none !important;
        }
        input:focus,
        textarea:focus,
        select:focus,
        button:focus,
        img:focus {
          outline: none;
        }

        jdiv[class^="wrap"],
        jdiv[class^="globalClass"] > jdiv {
          z-index: 100 !important;
        }

        jdiv.__jivoMobileButton {
          margin-bottom: 100px !important;
        }
      `}</style>
    </>
  )
}

export { GlobalStyles }
