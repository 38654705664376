const LAST_VIEWED_KEY = "lastViewedProducts"

export const getWatchedRecentProductsStorage = (): string[] => {
  if (typeof localStorage === "undefined" || !localStorage) {
    return []
  }

  // получить uuid продуктов у не авторизованного
  const arrUUIDKeys = localStorage.getItem(LAST_VIEWED_KEY)
  return !!arrUUIDKeys ? JSON.parse(arrUUIDKeys) : []
}

export const setWatchedRecentProductsStorage = (ids: string[] | null) => {
  if (ids == null) {
    return
  }

  localStorage.setItem(LAST_VIEWED_KEY, JSON.stringify(ids))
}
