import { httpClient } from "@/api/api"
import { FetcherBasePropsType, get, getAbsolutePath } from "@/service/fetcher"
import { CatalogFetcherPropsType, CatalogResponseType } from "@/types"
import { getSearchParamsCatalogByRequest } from "@/utils/common"
import {
  CategoryByAreaResponse,
  CategoryResponse,
  ProductCatalogType,
  RequestHelpPageCategory,
  ResponseHelpPageCategory,
  SortTypeResponse,
  Tag,
  TagResponse,
} from "../../contracts"

export const fetchCategories = (
  data?: FetcherBasePropsType,
): Promise<CategoryResponse> =>
  get<CategoryResponse>(`${getAbsolutePath(data?.server)}/catalog/categories`)

export const fetchTags = (data?: FetcherBasePropsType): Promise<Tag[]> =>
  get<TagResponse>(`${getAbsolutePath(data?.server)}/catalog/tags`)

export const fetchCategoriesByBusinessArea = (
  uuidArea: string | null,
): Promise<CategoryByAreaResponse> =>
  get<CategoryByAreaResponse>(
    `/catalog/bussiness-area${!!uuidArea ? `/${uuidArea}` : ""}`,
  )

type HitsQueryType = {
  category?: string | number
}

type NewsQueryType = {
  category?: string | number
}

export const fetchCatalog = ({
  options,
  data,
  server,
}: CatalogFetcherPropsType): Promise<CatalogResponseType> => {
  const searchParams = getSearchParamsCatalogByRequest({
    data,
    options,
  })

  const url = `${getAbsolutePath(server)}/catalog?${String(searchParams)}`

  return httpClient
    .get<CatalogResponseType>(url, {
      timeout: 60 * 1000,
    })
    .then((res) => res.data)
}

export const fetchCatalogFilters = ({
  options,
  data,
}: CatalogFetcherPropsType): Promise<Pick<CatalogResponseType, "filters">> => {
  const searchParams = getSearchParamsCatalogByRequest({
    data,
    options,
  })

  const url = `/catalog/filters?${String(searchParams)}`

  return httpClient
    .get<Pick<CatalogResponseType, "filters">>(url)
    .then((res) => res.data)
}

export const fetchSortTypes = (): Promise<SortTypeResponse> =>
  get<SortTypeResponse>(`/catalog/sort-type`)

export const fetchHits = (
  data?: HitsQueryType & FetcherBasePropsType,
): Promise<ProductCatalogType[]> => {
  let url = `${getAbsolutePath(data?.server)}/hits`

  if (data?.category) {
    url += `?category=${data?.category}`
  }

  return get<ProductCatalogType[]>(url)
}

export const fetchNews = (
  data?: NewsQueryType & FetcherBasePropsType,
): Promise<ProductCatalogType[]> => {
  let url = `${getAbsolutePath(data?.server)}/news`

  if (data?.category) {
    url += `?category=${data?.category}`
  }

  return get<ProductCatalogType[]>(url)
}

export const fetchPriceList = ({
  categories,
  email,
}: {
  categories: string[]
  email?: string
}): Promise<{ link: string }> =>
  get<{ link: string }>(
    `/catalog/pricelist?categories=${categories.join(",")}&email=${
      email || ""
    }`,
  )

export const fetchHelpPage = ({
  id,
  server,
}: RequestHelpPageCategory &
  FetcherBasePropsType): Promise<ResponseHelpPageCategory> =>
  get<ResponseHelpPageCategory>(
    `${getAbsolutePath(server)}/catalog/category/help-page/${id}`,
  )
