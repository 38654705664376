import {
  ComparesResponseReturnType,
  V1ComparesAddCreatePayload,
  V1ComparesDeleteDeletePayload,
} from "../../contracts"
import {
  deleteFetch,
  FetcherBasePropsType,
  get,
  getAbsolutePath,
  post,
} from "@/service/fetcher"

export const fetchCompares = (data?: FetcherBasePropsType) =>
  get<ComparesResponseReturnType[]>(
    `${getAbsolutePath(data?.server)}/compares`,
    true,
    data?.req,
    data?.res,
  )

export const fetchAddCompares = (data: V1ComparesAddCreatePayload) =>
  post<{ category: string } | null, V1ComparesAddCreatePayload>(
    "/compares/add",
    data,
    true,
  )

export const fetchRemoveCompares = (data: V1ComparesDeleteDeletePayload) =>
  deleteFetch<null, V1ComparesDeleteDeletePayload>(
    "/compares/delete",
    data,
    true,
  )

export const fetchRemoveAllCompares = () =>
  deleteFetch<null, null>("/compares/delete-all", null, true)
