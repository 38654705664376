import React, { FC, memo } from "react"
import { PushCounter } from "@/components/PushCounter"
import { NavigationTitlePropsType } from "./types"
import { Link } from "../Link"

const NavigationTitle: FC<NavigationTitlePropsType> = memo(
  ({ title, path, variant, isLink, isScroll, qty, className, isShowQty }) => {
    if (!isLink) {
      return <>{title}</>
    }

    return (
      <Link
        variant={variant}
        href={path}
        scroll={isScroll}
        title={title}
        className={className}
      >
        {title}
        {isShowQty && !!qty && (
          <>
            &nbsp;
            <PushCounter qty={qty} />
          </>
        )}
      </Link>
    )
  },
)

NavigationTitle.displayName = "NavigationTitle"
export { NavigationTitle }
