import React, { FC, memo } from "react";
import { Typography } from "@/ui/Typography/Typography";
import { LIMIT_SHOW_HISTORY, LIMIT_SHOW_HISTORY_HINTS } from "./constants";
import HistoryItem from "./HistoryItem";
import TapItem from "./TapItem";
import { HistoryPropsType } from "./types";
import { cssHistory, StyledResult, StyledResultList, StyledResultTitle } from "../StyledAutoComplete";
const History: FC<HistoryPropsType> = memo(({
  history,
  selectItem,
  query,
  clearHistory,
  removeFromHistory,
  taps
}) => {
  return <>
        {!!query ? <StyledResult className={cssHistory}>
            <StyledResultList>
              {[...history].reverse().filter(item => item.query.includes(query)).filter((_, i) => i < LIMIT_SHOW_HISTORY_HINTS).map((item, i) => <HistoryItem key={i} removeFromHistory={removeFromHistory} selectItem={selectItem} item={item} query={query} />)}

              {taps.filter((_, i) => i < LIMIT_SHOW_HISTORY_HINTS).map((t, i) => <TapItem key={i} query={query} selectItem={selectItem} tap={t} />)}
            </StyledResultList>
          </StyledResult> : <>
            {!!history.length && <StyledResult className={cssHistory}>
                <StyledResultTitle>
                  <Typography variant={"span"}>История</Typography>
                  <Typography variant={"p12"} onClick={clearHistory}>
                    Очистить
                  </Typography>
                </StyledResultTitle>
                <StyledResultList>
                  {[...history].reverse().filter((item, i) => i < LIMIT_SHOW_HISTORY).map((item, i) => <HistoryItem key={i} removeFromHistory={removeFromHistory} selectItem={selectItem} item={item} />)}
                </StyledResultList>
              </StyledResult>}
          </>}
      </>;
});
History.displayName = "History";
export { History };