import { useEffect, useState } from "react"

export type DirectionScrollType = "down" | "up" | null
type UseScrollDirectionType = (
  props?:
    | {
        offset?: number
      }
    | undefined,
) => DirectionScrollType
const OFFSET = 100

export const useScrollDirection: UseScrollDirectionType = (props = {}) => {
  const { offset = OFFSET } = props
  const [scrollDirection, setScrollDirection] =
    useState<DirectionScrollType>(null)

  useEffect(() => {
    let lastScrollY = window.scrollY

    const updateScrollDirection = () => {
      const scrollY = window.scrollY

      if (scrollY === 0) {
        setScrollDirection(null)
        lastScrollY = 0
        return
      }

      const direction =
        scrollY > offset ? (scrollY > lastScrollY ? "down" : "up") : null
      if (direction !== scrollDirection) {
        setScrollDirection(direction)
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
    }

    window.addEventListener("scroll", updateScrollDirection) // add event listener

    return () => {
      window.removeEventListener("scroll", updateScrollDirection) // clean up
    }
  }, [offset, scrollDirection])

  return scrollDirection
}
