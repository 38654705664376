import { FC, memo } from "react";
import { Button } from "@/ui/Button/Button";
import { ButtonSizeAlias } from "@/ui/Button/constants";
import { Icon } from "@/ui/Icon";
import { Typography } from "@/ui/Typography/Typography";
import { HistoryItemPropsType } from "./types";
import { cssWithIcon, StyledResultItem, StyledResultItemInner } from "../StyledAutoComplete";
const HistoryItem: FC<HistoryItemPropsType> = memo(({
  selectItem,
  removeFromHistory,
  item,
  query,
  icon = "RefreshTime"
}) => {
  const text = !!query ? item.query.replace(query, `<b>${query}</b>`) : item.query;
  return <StyledResultItem className={cssWithIcon}>
        <StyledResultItemInner onClick={e => {
      e.preventDefault();
      selectItem(item.query);
    }}>
          <Icon NameIcon={icon} size={"medium"} />
          <Typography variant={"p14"}>
            <span dangerouslySetInnerHTML={{
          __html: text
        }} />
          </Typography>
        </StyledResultItemInner>
        <Button variant={"box"} size={ButtonSizeAlias.SMALL} icon={"X"} onClick={e => {
      e.preventDefault();
      removeFromHistory({
        query: item.query
      });
    }} />
      </StyledResultItem>;
});
HistoryItem.displayName = "HistoryItem";
export default HistoryItem;