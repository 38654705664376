import { PLATFORM } from "@/utils/constants"

export const getIsStandalone = () => {
  return (
    ("standalone" in window.navigator && window.navigator.standalone) ||
    window.matchMedia("(display-mode: standalone)").matches
  )
}
export const getIsBot = (userAgent?: string) => {
  if (!userAgent) {
    return true
  }

  // Check if the user agent contains any bot indicators
  return /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex|zabbix|GeedoProductSearch|GeedoBot|Amazonbot|Bytespider|SeopultContentAnalyzer|SeekportBot|DataForSeoBot|Barkrowler|BLEXBot|SemrushBot|MJ12bot|AhrefsBot|bingbot|DotBot|PetalBot|LinkpadBot|SputnikBot|statdom.ru|MegaIndex.ru|WebDataStats|Jooblebot|Baiduspider|BackupLand|NetcraftSurveyAgent|openstat.ru|thesis-research-bot|fidget-spinner-bot|facebookexternalhit/i.test(
    userAgent,
  )
}
export const getPlatformDevice = (): PLATFORM => {
  if (typeof navigator === "undefined") {
    return PLATFORM.UNKNOWN
  }

  const userAgent = window.navigator.userAgent.toLowerCase()

  const isiOS = /iphone|ipad|ipod/.test(userAgent)
  const isiPadOS =
    navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1

  if (isiOS || isiPadOS) {
    return PLATFORM.IOS
  }

  const isAndroid = /android/.test(userAgent)

  if (isAndroid) {
    return PLATFORM.ANDROID
  }

  return PLATFORM.UNKNOWN
}
