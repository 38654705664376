export const enum EventGTM {
  PAGEVIEW = "Pageview",
}

export const enum ActionTypeGTM {
  DETAIL = "detail", // просмотр товара;
  ADD = "add", // добавление товара в корзину;
  REMOVE = "remove", // удаление товара из корзины;
  PURCHASE = "purchase", // покупка;
}
