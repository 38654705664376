import { useQueries } from "react-query"
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useRouter } from "next/router"
import { fetchBanners } from "@/api/bannersAPI"
import { fetchFreeShipping } from "@/api/cartAPI"
import { fetchShippingMethods } from "@/api/checkoutAPI"
import { fetchSettingsApp } from "@/api/settingsAPI"
import {
  HeaderRectType,
  UseLayoutHeaderReturnType,
} from "@/hooks/header/header"
import {
  setBanners,
  setIsLoadingPage,
  setLocation,
  setSettings,
  setShippingsMethods,
  SettingsAppType,
} from "@/store/reducers/appSlice"
import { setFreeShipping } from "@/store/reducers/cartSlice"
import {
  FreeShippingReturnType,
  LinkItemType,
  LocationExtendsType,
  LocationType,
  StoreBannersType,
} from "@/types"
import { trackGTM } from "@/utils/analytics"
import {
  getLocationCookie,
  getLocationRegion,
  scrollBodyEnable,
  setLocationCookie,
} from "@/utils/common/helpers"
import { BannerApiType, FrontDeliveryMethod } from "../../../contracts"
import { Settings } from "../../../contracts/src/api/settings"
import { useAppDispatch, useAppSelector } from "../redux"
import { useShops } from "../shops/shops"
import { useWatchedRecent } from "../watchedRecent/useWatchedRecent"

const { trackPageView } = trackGTM

export type AppContextPropsType = {
  location: LocationExtendsType | null
  settings: SettingsAppType | null
  banners: StoreBannersType | null
  updateLocation: (location: LocationType | null) => void
  updateBanners: (banners: BannerApiType[] | undefined) => void
  socials: null | Record<string, LinkItemType>
  updateIsLoadingPage: (value: boolean) => void
  isLoadingPage: boolean
  isDateToOnlyCompany: boolean
  layouts: {
    header: UseLayoutHeaderReturnType
  }
}
export type UseAppReturnType = AppContextPropsType

const AppContext = createContext<null | AppContextPropsType>(null)

export function Provider({ children }: { children?: ReactNode }): JSX.Element {
  const { location, settings, banners, isLoadingPage, isDateToOnlyCompany } =
    useAppSelector(({ app }) => app)
  const dispatch = useAppDispatch()
  const router = useRouter()

  const pathname = router.asPath.split("?")[0]

  // header layout
  const [enabledOverlay, setEnabledOverlay] = useState<boolean>(false)
  const [isOverlay, setIsOverlay] = useState<boolean>(false)
  const [isFixed, setIsFixed] = useState<boolean>(false)
  const [rect, setRect] = useState<HeaderRectType | null>(null)
  // header layout

  const socials = useMemo(() => {
    const socials: Record<string, LinkItemType> = {}
    if (!settings) {
      return socials
    }

    const { viber, telegram, whatsApp } = settings

    if (!!viber && viber.length > 0) {
      socials["viber"] = {
        icon: "Viber",
        path: viber,
      }
    }
    if (!!whatsApp && whatsApp.length > 0) {
      socials["whatsApp"] = {
        icon: "WhatsApp",
        path: whatsApp,
      }
    }
    if (!!telegram && telegram.length > 0) {
      socials["telegram"] = {
        icon: "Telegram",
        path: telegram,
      }
    }

    return socials
  }, [settings])

  // инициирует первоначальный запрос магазинов
  useShops()

  const { init: initWatchedRecent } = useWatchedRecent()

  const locationRegion = useMemo(
    () => getLocationRegion(location) || "",
    [location],
  )

  useQueries([
    {
      queryKey: ["settings"],
      queryFn: () => fetchSettingsApp(),
      onSuccess: (data: Settings) => {
        dispatch(setSettings(data || null))
      },
    },
    {
      queryKey: ["banners"],
      queryFn: () => fetchBanners(),
      enabled: !banners,
      onSuccess: (data: BannerApiType[]) => {
        updateBanners(data)
      },
    },
    {
      queryKey: ["shippingMethods", locationRegion],
      queryFn: () =>
        fetchShippingMethods({
          regions: locationRegion,
        }),
      enabled: !!location,
      onSuccess: (data: FrontDeliveryMethod[]) => {
        dispatch(setShippingsMethods(data))
      },
    },
    {
      queryKey: ["freeShipping", locationRegion],
      queryFn: () => fetchFreeShipping(locationRegion),
      enabled: !!locationRegion,
      onSuccess: (data: FreeShippingReturnType) => {
        dispatch(setFreeShipping(data))
      },
    },
  ])

  const updateBanners = useCallback(
    (banners: BannerApiType[] | undefined) => {
      dispatch(setBanners(banners || null))
    },
    [dispatch],
  )

  const updateLocation = useCallback(
    (location: LocationType | null) => {
      dispatch(setLocation(location))
      setLocationCookie(location)
    },
    [dispatch],
  )

  const updateIsLoadingPage = useCallback(
    (value: boolean) => {
      dispatch(setIsLoadingPage(value))
    },
    [dispatch],
  )

  useEffect(() => {
    // на статических страницах
    // нет доступа к записи location в redux
    // временная дополнительная проверка
    console.log("location ", location)
    console.log("getLocationCookie ", getLocationCookie())
    if (!location) {
      dispatch(setLocation(getLocationCookie() ?? null))
    }
  }, [dispatch, location])

  useEffect(() => {
    initWatchedRecent()
  }, [initWatchedRecent])

  useEffect(() => {
    trackPageView({
      pageTitle:
        (document.querySelector("h1")?.textContent || document.title) ?? "",
      pagePath: pathname,
    })
  }, [pathname])

  useEffect(() => {
    const onRouteChangeStart = () => {
      updateIsLoadingPage(true)
    }

    const onRouteChangeComplete = () => {
      updateIsLoadingPage(false)
      scrollBodyEnable()
    }

    router.events.on("routeChangeStart", onRouteChangeStart)
    router.events.on("routeChangeComplete", onRouteChangeComplete)

    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart)
      router.events.off("routeChangeComplete", onRouteChangeComplete)
    }
  }, [])

  const contextValue: AppContextPropsType = useMemo(
    () => ({
      location: location,
      settings,
      updateLocation: updateLocation,
      banners,
      updateBanners: updateBanners,
      socials,
      isLoadingPage,
      updateIsLoadingPage: updateIsLoadingPage,
      isDateToOnlyCompany: isDateToOnlyCompany,
      layouts: {
        header: {
          isOverlay: isOverlay,
          isFixed: isFixed,
          rect,
          enabledOverlay,
          setIsOverlay,
          setIsFixed,
          setRect: setRect,
          setEnabledOverlay,
        },
      },
    }),
    [
      location,
      settings,
      updateLocation,
      banners,
      updateBanners,
      socials,
      isLoadingPage,
      updateIsLoadingPage,
      isDateToOnlyCompany,
      isOverlay,
      isFixed,
      rect,
      enabledOverlay,
    ],
  )

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  )
}

export const useApp = (): UseAppReturnType => {
  const appContext = useContext(AppContext)

  if (appContext === null) {
    throw new Error("App context have to be provided")
  }

  return {
    ...appContext,
  } as const
}
