import {
  ButtonBase,
  ButtonBox,
  ButtonFilled,
  ButtonLink,
  ButtonOutline,
  ButtonSliderArrow,
  ButtonSmall,
  ButtonTranslucent,
} from "@/ui/Button/StyledButton"

export const enum ButtonSizeAlias {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
}

export const ButtonSize = {
  [ButtonSizeAlias.LARGE]: 60,
  [ButtonSizeAlias.MEDIUM]: 48,
  [ButtonSizeAlias.SMALL]: 30,
}
export const matching = {
  filled: () => ButtonFilled,
  outline: () => ButtonOutline,
  small: () => ButtonSmall,
  link: () => ButtonLink,
  translucent: () => ButtonTranslucent,
  box: () => ButtonBox,
  arrow: () => ButtonSliderArrow,
  default: () => ButtonBase,
}
