import { ResponseOrdersFilters } from "../../../contracts"
import { TRANSLATE_ORDER } from "@/hooks/order/constants"

export const MatchingFiltersNamesAlias: Record<
  keyof ResponseOrdersFilters | string,
  string
> = {
  delivery: TRANSLATE_ORDER.shippingAddress,
  payers: TRANSLATE_ORDER.payer,
  pickup: TRANSLATE_ORDER.pickupAddress,
  years: TRANSLATE_ORDER.year,
}

export const enum ViewMode {
  PRODUCTS = "products",
  ORDERS = "orders",
  RECOMMENDS = "recommendations",
}

export const INITIAL_PAGE = 1
export const INITIAL_PER_PAGE = 12
export const LIMIT_LAST_WATCHED = 31
export const INITIAL_STATE = -1
