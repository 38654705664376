import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ShippingPaymentMethodType } from "@/api/checkoutAPI"
import { ProductPrintType } from "@/components/Cart/Print/types"
import { ProductType, ProductWithChildType } from "@/components/Products/types"
import { CART_STATUS } from "@/hooks/cart/constants"
import { setCartTokenStorage } from "@/hooks/cart/helpers"
import {
  FreeShippingReturnType,
  OrderThankType,
  SpecificationItemType,
} from "@/types"
import {
  configPrintProducts,
  configPrintSamples,
  getDateToStore,
} from "@/utils/common/helpers"
import { ApiError } from "../../../contracts"

const initialState = {
  isInit: false as boolean,
  // итоговое значение из бэка без учета стоимости доставки
  totalCost: 0 as number,
  productCost: 0 as number,
  token: null as string | null,
  specification: null as Record<string, SpecificationItemType> | null,
  datasource: null as Record<string, ProductType> | null,
  promocode: null as string | null,
  discount: 0 as number,
  productsFetching: [] as string[] | [],
  order: null as OrderThankType | null,
  shipping: {
    minDate: {
      iso: null as string | null,
    },
    nextDate: {
      iso: null as string | null,
    },
    cost: null as number | null,
    type: null as string | null,
    free: {
      minCost: null as number | null,
    },
  },
  pickup: {
    iso: null as string | null,
  },
  merge: {
    isShow: false as boolean,
    products: null as ProductType[] | null,
    isFetchingProducts: false as boolean,
    specification: null as Record<string, SpecificationItemType> | null,
  },
  paymentMethods: null as ShippingPaymentMethodType[] | null,
  print: {
    enabled: false as boolean,
    isLoading: false as boolean,
    products: [] as ProductPrintType[] | [],
    samples: [] as ProductPrintType[] | [],
    isActive: false as boolean,
    lastLink: null as string | null,
  },
  hasProductsInAuth: false as boolean,
  multiAddedToCart: {
    isSuccess: false as boolean,
    isFetching: false as boolean,
    isFinish: false as boolean,
  },
  status: null as CART_STATUS | null,
  paymentError: null as ApiError | null,
}

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload
    },
    setSpecification(
      state,
      action: PayloadAction<SpecificationItemType[] | null>,
    ) {
      if (action.payload !== null) {
        let specification: Record<string, SpecificationItemType> = {}

        if (!!state.specification) {
          specification = { ...state.specification }
        }

        action.payload
          .sort((a, b) => {
            if (!a.parent) return -1
            if (!b.parent) return 1
            return 0
          })
          .map((p) => {
            if (p.uuid !== undefined) {
              // распределение обычных товаров
              // и товаров, являющихся дочерними комплекта
              if (!!p.parent) {
                if (specification[p.parent] !== undefined) {
                  specification[p.parent]["child"] = {
                    ...(specification[p.parent]?.child || {}),
                    ...{ [p.uuid]: p },
                  }
                }
              } else {
                specification[p.uuid] = { ...p }
              }
            }
          })

        state.specification = specification
      } else {
        state.specification = null
      }
    },
    updateSpecification(state, action: PayloadAction<SpecificationItemType>) {
      const { kit: payloadKit, ...payload } = action.payload

      if (!payload.uuid) {
        return
      }
      if (state.specification === null) {
        state.specification = {}
      }

      if (!!payload.parent) {
        if (!!state.specification[payload.parent].child) {
          ;(state.specification[payload.parent].child || {})[payload.uuid] =
            payload
        }
      } else {
        state.specification[payload.uuid] = {
          ...state.specification[payload.uuid],
          ...payload,
        }

        if (!!state.specification[payload.uuid].child) {
          Object.keys(state.specification[payload.uuid].child || {}).map(
            (key) => {
              if (state.specification !== null) {
                const child = state.specification[payload.uuid || ""].child
                if (!!child && !!child[key]) {
                  if (payload.quantity !== undefined) {
                    child[key].quantity = payload.quantity
                  }
                  child[key].isRemoved = payload.isRemoved
                }
              }
            },
          )
        } else {
          if (payloadKit !== undefined && payloadKit.length > 0) {
            const childMaked = {}
            for (const kitId of payloadKit) {
              childMaked[kitId] = {
                uuid: kitId,
                quantity: payload.quantity,
              }
            }
            state.specification[payload.uuid]["child"] = childMaked
          }
        }
      }
    },
    updateProductPriceUnit(
      state,
      action: PayloadAction<{ uuid: string; priceUnit: number }>,
    ) {
      if (state.datasource !== null) {
        if (state.datasource[action.payload.uuid]) {
          state.datasource[action.payload.uuid].price = action.payload.priceUnit
        }
      }
    },
    removeProductSpecification(state, action: PayloadAction<{ uuid: string }>) {
      if (state.specification !== null) {
        if (!!state.specification[action.payload.uuid]) {
          delete state.specification[action.payload.uuid]
        }
      }
    },
    setTotalCost(state, action: PayloadAction<number>) {
      state.totalCost = action.payload
    },
    setProductCost(state, action: PayloadAction<number>) {
      state.productCost = action.payload
    },
    clearCart(state) {
      state.totalCost = 0
      state.productCost = 0
      state.specification = {}
      state.datasource = null
      state.token = null
      setCartTokenStorage(null)
    },
    setPromocode(state, action: PayloadAction<string | null>) {
      state.promocode = action.payload
    },
    setDiscount(state, action: PayloadAction<number>) {
      state.discount = action.payload
    },
    setShippingCost(state, { payload }: PayloadAction<number | null>) {
      state.shipping.cost = payload
    },
    addProductsFetching(state, action: PayloadAction<string>) {
      state.productsFetching = [...state.productsFetching, action.payload]
    },
    removeProductsFetching(state, action: PayloadAction<string>) {
      state.productsFetching = state.productsFetching.filter(
        (id) => id !== action.payload,
      )
    },
    setOrder(state, action: PayloadAction<OrderThankType | null>) {
      state.order = action.payload
    },
    setIsInit(state, action: PayloadAction<boolean>) {
      state.isInit = action.payload
    },
    setNextShippingDate(
      state,
      { payload }: PayloadAction<string | Date | null>,
    ) {
      state.shipping.nextDate = getDateToStore(payload)
    },
    setMinShippingDate(
      state,
      { payload }: PayloadAction<string | Date | null>,
    ) {
      state.shipping.minDate = getDateToStore(payload)
    },
    setMergeProducts(state, action: PayloadAction<ProductType[] | null>) {
      state.merge.products = action.payload
    },
    setMergeSpecification(
      state,
      action: PayloadAction<Record<string, SpecificationItemType> | null>,
    ) {
      state.merge.specification = action.payload
    },
    appendDataSource: (state, action: PayloadAction<ProductType[] | null>) => {
      if (action.payload === null) {
        return
      }
      const datasource = {}
      for (const prod of action.payload) {
        datasource[prod.uuid || ""] = { ...prod }
      }
      state.datasource = { ...(state.datasource || {}), ...datasource }
    },
    setFreeShipping: (
      state,
      { payload }: PayloadAction<FreeShippingReturnType>,
    ) => {
      if (payload === null) {
        state.shipping.free.minCost = null
      } else {
        state.shipping.free.minCost = payload.order_min_cost
      }
    },
    setPaymentMethods: (
      state,
      { payload }: PayloadAction<ShippingPaymentMethodType[] | null>,
    ) => {
      state.paymentMethods = payload
    },
    setPrintProducts: (
      state,
      {
        payload,
      }: PayloadAction<{
        specification: Record<string, SpecificationItemType> | null
        products: Record<string, ProductWithChildType> | null
        samples: Record<string, ProductWithChildType> | null
      } | null>,
    ) => {
      if (payload === null) {
        state.print.products = []
        state.print.samples = []
      } else {
        const { specification, products, samples } = payload
        let p: ProductPrintType[]
        let s: ProductPrintType[]

        if (!specification) {
          p = []
          s = []
        } else {
          p = configPrintProducts({
            products,
            specification,
          })
          s = configPrintSamples({ samples, specification })
        }

        state.print.products = p
        state.print.samples = s
      }
    },
    setPrintEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.print.enabled = payload
    },
    setPrintIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.print.isLoading = payload
    },
    setPickupDate: (
      state,
      { payload }: PayloadAction<string | Date | null>,
    ) => {
      state.pickup = getDateToStore(payload)
    },
    setIsFetchingProductsMerge: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.merge.isFetchingProducts = payload
    },
    setIsShowMerge: (state, { payload }: PayloadAction<boolean>) => {
      state.merge.isShow = payload
    },
    setMultiAddedToCartFetching: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.multiAddedToCart.isFetching = payload
    },
    setMultiAddedToCartSuccess: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.multiAddedToCart.isSuccess = payload
    },
    setMultiAddedToCartFinish: (state, { payload }: PayloadAction<boolean>) => {
      state.multiAddedToCart.isFinish = payload
    },
    setPrintLastLink: (state, { payload }: PayloadAction<null | string>) => {
      state.print.lastLink = payload
    },
    setPrintIsActive: (state, { payload }: PayloadAction<boolean>) => {
      state.print.isActive = payload
    },
    setShippingType: (state, { payload }: PayloadAction<string | null>) => {
      state.shipping.type = payload
    },
    setStatus: (state, { payload }: PayloadAction<CART_STATUS | null>) => {
      state.status = payload
    },
    setPaymentError: (state, { payload }: PayloadAction<ApiError | null>) => {
      state.paymentError = payload
    },
  },
})

export const {
  setPromocode,
  setDiscount,
  setTotalCost,
  setToken,
  setSpecification,
  updateSpecification,
  clearCart,
  removeProductSpecification,
  updateProductPriceUnit,
  addProductsFetching,
  removeProductsFetching,
  setOrder,
  setNextShippingDate,
  setMinShippingDate,
  setMergeProducts,
  setMergeSpecification,
  appendDataSource,
  setShippingCost,
  setFreeShipping,
  setPaymentMethods,
  setPrintProducts,
  setPrintEnabled,
  setPrintIsLoading,
  setIsInit,
  setPickupDate,
  setIsShowMerge,
  setMultiAddedToCartFinish,
  setMultiAddedToCartSuccess,
  setMultiAddedToCartFetching,
  setPrintLastLink,
  setPrintIsActive,
  setShippingType,
  setStatus,
  setProductCost,
  setIsFetchingProductsMerge,
  setPaymentError,
} = cartSlice.actions
