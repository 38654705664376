import { useCallback, useState } from "react"
import { useMutation } from "react-query"
import { ApiError } from "../../contracts"
import { useAppDispatch, useAppSelector } from "./redux"
import { fetchApplyPromocode, fetchCancelPromocode } from "@/api/promocodeAPI"
import {
  setDiscount,
  setPromocode,
  setTotalCost,
} from "@/store/reducers/cartSlice"
import { MESSAGE_ERROR_DEFAULT } from "@/utils/constants"

type UsePromocodeReturnType = {
  isFetching: boolean
  apply: (code: string) => void
  cancel: () => void
  error: string | null
  promocode: string | null
  discount: number
  updatePromocode: (code: string | null) => void
  updateDiscount: (discount?: number | null) => void
}

type UsePromocodePropsType = {
  cart: string | null
}

export const usePromocode = ({
  cart,
}: UsePromocodePropsType): UsePromocodeReturnType => {
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const { promocode, discount } = useAppSelector((state) => state.cart)
  const [error, setError] = useState<string | null>(null)
  const dispatch = useAppDispatch()

  const updateDiscount = useCallback(
    (discount?: number | null) => {
      dispatch(setDiscount(discount || 0))
    },
    [dispatch],
  )
  const updatePromocode = useCallback(
    (promocode: string | null) => {
      dispatch(setPromocode(promocode))
    },
    [dispatch],
  )

  const applyMutation = useMutation(fetchApplyPromocode, {
    onMutate: () => {
      setError(null)
      setIsFetching(true)
    },
    onSuccess: (response, variables) => {
      setError(null)
      updatePromocode(variables.code || null)
      updateDiscount(response.discount)
      if (response.total_cost !== undefined) {
        dispatch(setTotalCost(response.total_cost))
      }
      setIsFetching(false)
    },
    onError: (error: ApiError) => {
      console.log("error ", error?.message || MESSAGE_ERROR_DEFAULT)
      setError(error?.message || MESSAGE_ERROR_DEFAULT)
      setIsFetching(false)
    },
  })

  const cancelMutation = useMutation(fetchCancelPromocode, {
    onMutate: () => {
      setError(null)
      setIsFetching(true)
    },
    onSuccess: (response) => {
      setError(null)
      updatePromocode(null)
      updateDiscount(0)
      if (response.total_cost !== undefined) {
        dispatch(setTotalCost(response.total_cost))
      }
      setIsFetching(false)
    },
    onError: (error: ApiError) => {
      console.log("error ", error?.message || MESSAGE_ERROR_DEFAULT)
      setError(error?.message || MESSAGE_ERROR_DEFAULT)
      setIsFetching(false)
    },
  })

  const applyHandle = useCallback(
    (code: string) => {
      if (cart !== null) {
        applyMutation.mutate({
          cart: cart,
          code: code,
        })
      }
    },
    [applyMutation, cart],
  )

  const cancelHandle = useCallback(() => {
    if (cart !== null) {
      cancelMutation.mutate({
        cart: cart,
      })
    }
  }, [cancelMutation, cart])

  return {
    isFetching,
    apply: applyHandle,
    cancel: cancelHandle,
    promocode: promocode,
    discount: discount,
    updatePromocode: updatePromocode,
    updateDiscount: updateDiscount,
    error,
  }
}
