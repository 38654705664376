import { cx } from "@linaria/core";
import { FC } from "react";
import { cssTag } from "@/components/Tags/StyledTags";
import { Button } from "@/ui/Button/Button";
import { ButtonProps } from "@/ui/Button/types";
const TagDefault: FC<Omit<ButtonProps, "ref">> = ({
  children,
  className,
  ...props
}) => {
  return <Button className={cx(cssTag, className)} {...props}>
      {children}
    </Button>;
};
export { TagDefault };