import { RenderElementPropsType } from "@/ui/Select/types"
import { Typography } from "@/ui/Typography/Typography"

export const renderElement = ({
  item = {
    value: "",
    name: "",
  },
  isVisibleLayout = false,
}: RenderElementPropsType) => {
  const { layout, name } = item ?? {}
  return (
    <>
      {isVisibleLayout && !!layout ? (
        layout
      ) : (
        <Typography variant="span">{name}</Typography>
      )}
    </>
  )
}
