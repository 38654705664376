import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { User } from "@/hooks/auth"
import type { LastOrderReturnType, PayerListItemType } from "@/types"
import { AddressType, ResponseCustomerDataList } from "../../../contracts"

const initialState = {
  isInit: false as boolean | false,
  isAuth: false as boolean | false,
  isLoading: true as boolean,
  addresses: null as AddressType[] | null,
  payers: null as PayerListItemType[] | null,
  user: null as Required<User> | null,
  priceList: {
    indeterminate: [] as string[] | [],
    selected: [] as string[] | [],
  },
  lastOrder: null as LastOrderReturnType | null,
  customer: null as ResponseCustomerDataList | null,
}
export const accountSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setUserProfile(state, { payload }: PayloadAction<User | null>) {
      if (payload !== null) {
        const {
          email = null,
          accessToken = null,
          fio = null,
          number = null,
          refreshToken = null,
          isAdmin = false,
          phone = null,
          cart = null,
        } = payload
        state.user = {
          email,
          accessToken,
          phone,
          cart,
          refreshToken,
          fio,
          isAdmin,
          number: number,
        }
      } else {
        state.user = payload
      }
    },
    setIsAuth(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload
    },
    setIsInit(state, action: PayloadAction<boolean>) {
      state.isInit = action.payload
    },
    setAddresses(state, action: PayloadAction<AddressType[] | null>) {
      state.addresses = action.payload
    },
    setPayers(state, action: PayloadAction<PayerListItemType[] | null>) {
      state.payers = action.payload
    },
    setCategoriesPriceListSelected: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.priceList.selected = action.payload
    },
    setLastOrder: (
      state,
      action: PayloadAction<null | LastOrderReturnType>,
    ) => {
      state.lastOrder =
        action.payload !== null ? { ...action.payload, replacement: 3 } : null
    },
    setCustomer: (
      state,
      action: PayloadAction<null | ResponseCustomerDataList>,
    ) => {
      state.customer = action.payload
    },
  },
})

export const {
  setPayers,
  setIsInit,
  setUserProfile,
  setIsAuth,
  setLastOrder,
  setCategoriesPriceListSelected,
  setCustomer,
  setIsLoading,
} = accountSlice.actions
