import { ServerResponse } from "http"
import { SpecificationItemType } from "@/types"
import {
  cookies,
  getEntityBasePath,
  getExpireOneYear,
} from "@/utils/common/helpers"
import { ROUTES } from "@/utils/constants"
import { COOKIE_CART_TOKEN_KEY } from "./constants"
import { ProductSpecificationType } from "../../../contracts/contracts"

const getCartTokenStorage = (): string | null => {
  const token = cookies.get(COOKIE_CART_TOKEN_KEY)
  return token || null
}

const setCartTokenStorage = (
  token: string | null,
  res?: ServerResponse,
): void => {
  if (token !== null) {
    if (res !== undefined) {
      res.setHeader(
        "Set-Cookie",
        `${COOKIE_CART_TOKEN_KEY}=${encodeURIComponent(
          JSON.stringify(token),
        )}; Path=/; Expires=${getExpireOneYear().toUTCString()}`,
      )
    } else {
      cookies.set(COOKIE_CART_TOKEN_KEY, JSON.stringify(token), {
        path: "/",
        expires: getExpireOneYear(),
      })
    }
  } else {
    if (res !== undefined) {
      res.setHeader(
        "Set-Cookie",
        `${COOKIE_CART_TOKEN_KEY}=null; Path=/; Max-Age=0`,
      )
    } else {
      cookies.remove(COOKIE_CART_TOKEN_KEY, {
        path: "/",
      })
    }
  }
}

const formatProductsToSpecification = ({
  products,
  specification,
}: {
  products: ProductSpecificationType[]
  specification: Record<string, SpecificationItemType> | null
}) => {
  const t: Record<string, ProductSpecificationType> = {}
  for (const { uuid, sample: s = 0, quantity: q = 0 } of products) {
    if (uuid === undefined) {
      continue
    }
    let sample = s
    let quantity = q

    if (!!specification && specification[uuid]) {
      sample += specification[uuid].sample || 0
      quantity += specification[uuid].quantity || 0
    }

    t[uuid] = {
      uuid: uuid,
      sample: sample,
      quantity: quantity,
    }
  }

  return t
}

// сохраняем массив ids товаров которые есть в корзине
// + проходимся по дочерним комплекта
const getEntityCartIds = (
  specification: Record<string, SpecificationItemType> | null,
): string[] => {
  if (specification === null) {
    return []
  }

  let uuids: string[] = []

  Object.entries(specification).map(([key, spec]) => {
    uuids = [...uuids, key]
    if (!!spec && !!spec.child) {
      uuids = [...uuids, ...Object.keys(spec.child)]
    }
  })

  return uuids.reduce((uniq: string[], item) => {
    return uniq.includes(item) ? uniq : [...uniq, item]
  }, [])
}
const getProductQty = (itemSpec: SpecificationItemType) =>
  !!itemSpec?.isRemoved ? 0 : itemSpec.quantity || 0
const getSampleQty = (itemSpec: SpecificationItemType) =>
  !!itemSpec?.isSampleRemoved ? 0 : itemSpec.sample || 0

export const getThankYouPath = (cart: string, isAbsolute = false): string =>
  getEntityBasePath(`${ROUTES.thank}?order=${cart}`, isAbsolute)

export const chunkArray = <A>(array: A[], chunkSize: number) => {
  const chunks = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

export {
  formatProductsToSpecification,
  getCartTokenStorage,
  setCartTokenStorage,
  getEntityCartIds,
  getProductQty,
  getSampleQty,
}
