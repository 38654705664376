const USE_SHIPPINGS_DEFAULT_PROPS = undefined as unknown as {
  product?: {
    totalQty: number
    currentCount?: number
    fastQty: number
  }
  withLocation?: boolean
}

const enum ShippingMethod {
  PICKUP = "pickup",
  EXPRESS = "express",
  COMPANY = "company",
  COURIER = "courier",
}

export { USE_SHIPPINGS_DEFAULT_PROPS, ShippingMethod }
