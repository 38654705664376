import {
  RequestOrderSave,
  RequestShippingCost,
  ResponseCustomerDataList,
  ShopResponse,
  V1CheckoutShippingMethodsListParams,
} from "../../contracts/contracts"
import { FrontDeliveryMethod } from "../../contracts/src/api/shippingMethods"
import { ShopType } from "../hooks/shops/types"
import {
  FetcherBasePropsType,
  get,
  getAbsolutePath,
  post,
  put,
} from "../service/fetcher"
import { LastOrderReturnType, ReplacementListItem } from "../types/types"

export const fetchLastOrder = (): Promise<LastOrderReturnType> =>
  get("/checkout/last-order", true)

export const fetchCustomerData = (): Promise<ResponseCustomerDataList> =>
  get("/checkout/customer-data", true)

export const fetchShippingMethods = (
  data: V1CheckoutShippingMethodsListParams,
): Promise<FrontDeliveryMethod[]> =>
  get(`/checkout/shipping-methods?regions=${data.regions}`, true)

export type ShippingPaymentMethodType = {
  uid: string
  name?: string
  weight: number
}
export type ShippingCostReturnType = {
  shipping_cost?: number
  payment_methods?: ShippingPaymentMethodType[]
}

export const fetchShippingCost = (
  data: RequestShippingCost,
): Promise<ShippingCostReturnType> =>
  post<ShippingCostReturnType, RequestShippingCost>(
    `/checkout/shipping-cost`,
    data,
    true,
  )

export const fetchShops = (data?: FetcherBasePropsType): Promise<ShopType[]> =>
  get(`${getAbsolutePath(data?.server)}/shops`)
export const fetchShopDetail = ({
  id,
  server,
}: FetcherBasePropsType & { id: string }): Promise<ShopResponse> =>
  get(`${getAbsolutePath(server)}/shops/${id}`)

export const fetchReplacements = (): Promise<ReplacementListItem[]> =>
  get("/checkout/replacements", true)

export const fetchSaveOrder = ({
  cart,
  ...data
}: { cart: string } & Omit<RequestOrderSave, "replacement"> & {
    replacement: number | null
  }): Promise<{ number: string }> =>
  put(`/checkout/order-save/${cart}`, data, true)
