import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import { NOTIFICATION_KIND } from "@/hooks/notification/constants"

const initialState = {
  notifications: [] as {
    index: number
    kind: NOTIFICATION_KIND
    image?: string
    list?: string
    t?: NodeJS.Timeout
  }[],
}

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    pushNotification(
      state,
      {
        payload: { kind, t, image, list },
      }: PayloadAction<{
        kind: NOTIFICATION_KIND
        image?: string
        list?: string
        t?: NodeJS.Timeout
      }>,
    ) {
      state.notifications.push({
        index: state.notifications.length,
        kind,
        image,
        list,
        t,
      })
    },
    replaceNotification(
      state,
      {
        payload: { index, kind, image, t },
      }: PayloadAction<{
        index: number
        kind: NOTIFICATION_KIND
        image?: string
        list?: string
        t?: NodeJS.Timeout
      }>,
    ) {
      state.notifications[index] = {
        index,
        kind,
        image,
        t,
      }
    },
    hideNotification(state, { payload }: PayloadAction<{ index: number }>) {
      const index = state.notifications.findIndex(
        ({ index }) => index === payload.index,
      )

      if (index != null) {
        state.notifications.splice(index, 1)
      }
    },
  },
})

export const { pushNotification, hideNotification, replaceNotification } =
  notificationsSlice.actions
