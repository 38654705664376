import { ButtonSize, ButtonSizeAlias } from "@/ui/Button/constants"

export const getBoxSize = (size = ButtonSize[ButtonSizeAlias.MEDIUM]) => {
  return `
  width: ${size}px;
  min-width: ${size}px;
  height: ${size}px;
  min-height: ${size}px;
  `
}
