import { type FC } from "react"
import { useAppSelector } from "@/hooks/redux"
import { Notification } from "./Notification"
import { StyledNotifications } from "./StyledNotifications"

export const Notifications: FC = () => {
  const notifications = useAppSelector(
    (state) => state.notifications.notifications,
  )

  if (!notifications.length) {
    return null
  }

  return (
    <StyledNotifications>
      {notifications.map(({ index, kind, image, list }) => (
        <Notification
          key={index}
          index={index}
          kind={kind}
          image={image}
          list={list}
        />
      ))}
    </StyledNotifications>
  )
}
