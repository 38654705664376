import { deleteFetch, get, getAbsolutePath, post } from "@/service/fetcher"
import type { FilterType } from "@/types"
import {
  V1FavoritesDeleteDeletePayload,
  V1FavoritesGroupsCreatePayload,
  V1FavoritesGroupsDeletePayload,
  V1FavoritesListParams,
  V1FavoritesShareListParams,
} from "../../contracts"

export type Group = {
  uid: string
  name: string
  quantity: number
}

type AddToFavoritesParams = {
  products: string
  group?: string
}

export type FavoritesResponseType = {
  filters: FilterType[]
  products: { group: string; products: string[] }[]
  priceRange: { min: number; max: number }
}

export const fetchFavorites = (request?: V1FavoritesListParams) => {
  let params = ""

  if (!!request) {
    const searchParams = new URLSearchParams()

    Object.entries(request).map(([key, val]) => {
      if (!val) {
        return
      }
      searchParams.set(String(key), String(val))
    })

    params = searchParams.toString()
  }

  return get<FavoritesResponseType>(
    `/favorites${!!params ? `?${params}` : ""}`,
    true,
  )
}

export const fetchShare = async ({
  group,
  server = false,
}: V1FavoritesShareListParams & { server?: boolean }) => {
  const searchParams = new URLSearchParams()

  searchParams.set("group", `${group}`)

  const url = `${getAbsolutePath(server)}/favorites/share?${searchParams}`

  return get<{
    owner: string
    products: string[]
  }>(url, false)
}

export const fetchFavoritesGroups = async (): Promise<Group[]> => {
  return get<Group[]>("/favorites/groups", true)
}

export const fetchAddToFavorites = (
  params: AddToFavoritesParams,
): Promise<null> => {
  return post<null, AddToFavoritesParams>("/favorites/add", params, true)
}

export const fetchRemoveFromFavorites = (
  params: V1FavoritesDeleteDeletePayload,
): Promise<null> => {
  return deleteFetch<null, V1FavoritesDeleteDeletePayload>(
    "/favorites/delete",
    params,
    true,
  )
}

export const fetchAddFavoritesGroup = (
  params: V1FavoritesGroupsCreatePayload,
) => {
  return post("/favorites/groups", params, true)
}

export const fetchRemoveFavoritesGroup = (
  params: V1FavoritesGroupsDeletePayload,
) => {
  return deleteFetch<null, V1FavoritesGroupsDeletePayload>(
    "/favorites/groups",
    params,
    true,
  )
}

export const fetchRemoveAllFavorites = (): Promise<null> =>
  deleteFetch("/favorites/delete-all", null, true)
