import { useContext, useMemo } from "react"
import { FavoritesContext } from "@/hooks/favorites/context"
import { ContextPropsType } from "@/hooks/favorites/types"

export const useFavorites = (
  productsUUID?: string,
): Omit<ContextPropsType, "fetchingUUIds"> & {
  isFavorites: boolean
  isFetching: boolean
} => {
  const favoritesContext = useContext(FavoritesContext)

  if (favoritesContext === null) {
    throw new Error("Favorites context have to be provided")
  }

  const isFavorites = useMemo(
    () =>
      (favoritesContext.favoritesKeys || []).some(
        (item) => item === productsUUID,
      ),
    [favoritesContext.favoritesKeys, productsUUID],
  )
  const isFetching = useMemo(
    () =>
      (favoritesContext.fetchingUUIds || []).some(
        (item) => item === productsUUID,
      ),
    [favoritesContext.fetchingUUIds, productsUUID],
  )

  return {
    ...favoritesContext,
    isFavorites,
    isFetching,
  }
}
