import type { FC } from "react";
import dynamic from "next/dynamic";
import { cx } from "@linaria/core";
import { Header } from "./Header";
import { HistoryOrdersPageRecommends } from "@/components/LeadHit";
import { LazyHydrateContainer } from "@/hoc/LazyHydrate";
import { useCart } from "@/hooks/cart/cart";
import { useCompares } from "@/hooks/compares";
import { useFavorites } from "@/hooks/favorites";
import { useAppSelector } from "@/hooks/redux";
import { BaseLayout } from "@/layouts/Base/BaseLayout";
import { cssLayoutWithAutoComplete, PageWrapper } from "@/styles/utils/Utils";
import { ComponentLoader } from "@/ui/Loaders/ComponentLoader";
const OfferToLogInPopup = dynamic(() => import("@/components/Products/parts/OfferToLogInPopup").then(({
  OfferToLogInPopup
}) => OfferToLogInPopup), {
  ssr: false
});
const WatchedRecently = dynamic(() => import("@/components/Products/WatchedRecently").then(mod => mod.WatchedRecently), {
  ssr: false,
  loading: () => <ComponentLoader />
});
const Footer = dynamic(() => import("./Footer/Footer").then(mod => mod.Footer));
export const DefaultLayout: FC<{
  historyOrdersPageRecommends?: boolean;
}> = ({
  historyOrdersPageRecommends = false,
  children,
  ...rest
}) => {
  const isShowSearchAutoComplete = useAppSelector(({
    search
  }) => search.autoComplete.isShow);
  const {
    isShowToLogin: isShowToLoginFavorites,
    hideToLogin: hideToLoginFavorites
  } = useFavorites();
  const {
    isShowToLogin: isShowToLoginCompares,
    hideToLogin: hideToLoginCompares
  } = useCompares();
  const {
    isShowToLogin: isShowToLoginCart,
    hideToLogin: hideToLoginCart
  } = useCart();
  return <BaseLayout>
      <PageWrapper {...rest} id="pageRoot" className={cx(isShowSearchAutoComplete && cssLayoutWithAutoComplete)}>
        <Header />

        {children}

        <OfferToLogInPopup isShow={isShowToLoginFavorites} title="Товар добавлен в Избранное" text="Войдите в профиль, чтобы сохранить список. Не сохраненный список будет очищен через 7 дней." onHide={hideToLoginFavorites} />

        <OfferToLogInPopup isShow={isShowToLoginCompares} title="Товар добавлен к сравнению" text="Войдите в профиль, чтобы сохранить сравнения. Не сохраненные сравнения будут очищены через 7 дней." onHide={hideToLoginCompares} />

        <OfferToLogInPopup isShow={isShowToLoginCart} title="Товар добавлен в корзину" text="Войдите в профиль, чтобы сохранить корзину. Не сохраненная корзина будет очищена через 7 дней." onHide={hideToLoginCart} />

        {historyOrdersPageRecommends && <LazyHydrateContainer>
            <HistoryOrdersPageRecommends />
          </LazyHydrateContainer>}

        <LazyHydrateContainer>
          <WatchedRecently />
        </LazyHydrateContainer>

        <LazyHydrateContainer>
          <Footer />
        </LazyHydrateContainer>
      </PageWrapper>
    </BaseLayout>;
};