import { useCallback, useEffect } from "react"
import {
  FeedbackTranslit,
  toBoldFormat,
  toMessageFormatPayload,
  toPayloadFormatUser,
  useUserPayload,
} from "@/hooks/feedback"
import { useAppDispatch, useAppSelector } from "@/hooks/redux"
import {
  getHasSendInstallFromStorage,
  getIsInstalledPWAFromStorage,
  sendMessageInstalledPWA,
  setHasSendInstallToStorage,
  setIsInstalledPWAToStorage,
  setLastPromptPWAToStorage,
} from "@/hooks/usePWA/helpers"
import { setIsShowPromptPWA } from "@/store/reducers/appSlice"
import { PLATFORM } from "@/utils/constants"
import { getIsStandalone, getPlatformDevice } from "@/utils/navigator"

export const usePWA = () => {
  const dispatch = useAppDispatch()
  const { isInit } = useAppSelector((state) => state.profile)
  const { location } = useAppSelector((state) => state.app)
  const isShowPrompt = useAppSelector((state) => state.app.pwa.isShowPrompt)

  const { getUserLogPayload } = useUserPayload()

  const sendLogMessage = useCallback(() => {
    if (!isInit) {
      return
    }

    const userString = toPayloadFormatUser(getUserLogPayload())

    const locationString = [location?.city_full, location?.region_full]
      .filter((l) => !!l)
      .join(", ")

    sendMessageInstalledPWA({
      text: toMessageFormatPayload({
        hash: "pwaInstalled",
        topic: "Установка PWA",
        payload: [
          {
            label: "Платформа",
            value: getPlatformDevice(),
          },
          {
            label: "Местоположение",
            value: locationString,
          },
        ],
        customMessage: `${toBoldFormat(
          `\n${FeedbackTranslit.user}:`,
        )}\n${userString}`,
      }),
    })
  }, [getUserLogPayload, isInit, location?.city_full, location?.region_full])

  useEffect(() => {
    if (getIsInstalledPWAFromStorage()) {
      return
    }

    const install = (withLog = true) => {
      dispatch(setIsShowPromptPWA(false))
      setIsInstalledPWAToStorage(true)
      setLastPromptPWAToStorage(null)
      if (withLog) {
        setHasSendInstallToStorage(true)
        // sendLogMessage()
      }
    }

    const beforeInstall = () => {
      dispatch(setIsShowPromptPWA(true))
      setIsInstalledPWAToStorage(false)
      setHasSendInstallToStorage(false)
    }

    const platform = getPlatformDevice()
    const isIOS = platform === PLATFORM.IOS

    // Пользователь в настоящее время просматривает PWA
    // значит оно установлено
    if (getIsStandalone()) {
      install(isIOS ? !getHasSendInstallFromStorage() : true)
    } else {
      // если не находимся в автономном режиме (standalon)
      // и это IOS - значит у него нет событий beforeinstallprompt
      // нужно вручную вызвать окно, предлагающее установку
      if (isIOS) {
        beforeInstall()
      }

      const onAppInstallHandle = () => {
        console.log("[onAppInstallHandle]")
        install()
      }

      const onBeforeInstallPromptHandle = () => {
        console.log("[onBeforeInstallPromptHandle]")
        beforeInstall()
      }

      //Пользователь перемещается в браузере
      window.addEventListener(
        "beforeinstallprompt",
        onBeforeInstallPromptHandle,
      )
      window.addEventListener("appinstalled", onAppInstallHandle)
    }
  }, [dispatch])

  return {
    sendLogMessage,
    isShowPrompt,
  }
}
