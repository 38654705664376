import axios, { AxiosError } from "axios"
import { BASE_URL, BASE_VERSION_URL } from "@/utils/constants"

export const httpClient = axios.create({
  baseURL: `${BASE_URL}${BASE_VERSION_URL}`,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
  },
})

httpClient.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  async (error: AxiosError) => {
    // Any status codes that falls outside the range of 4xx cause this function to trigger

    const dataError =
      typeof error.response?.data === "string"
        ? { message: error.response?.data }
        : error.response?.data ?? {}

    return Promise.reject({
      status: error.response?.status,
      ...dataError,
    })
  },
)
