import React, { FC, memo } from "react"
import { renderElement } from "@/ui/Select/helpers"
import { SelectedHintBody, StyledSelectedHint } from "@/ui/Select/StyledSelect"
import { SelectItemsType } from "@/ui/Select/types"
import { Typography } from "@/ui/Typography/Typography"

const SelectedHint: FC<{
  itemSelected?: SelectItemsType
}> = memo(({ itemSelected }) => {
  if (!itemSelected) {
    return null
  }
  return (
    <StyledSelectedHint>
      <Typography variant={"p12"}>
        <b>Выбран: </b>
      </Typography>
      <SelectedHintBody>
        {renderElement({
          item: itemSelected,
          isVisibleLayout: true,
        })}
      </SelectedHintBody>
    </StyledSelectedHint>
  )
})

SelectedHint.displayName = "SelectedHint"

export { SelectedHint }
