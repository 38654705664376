import { cx } from "@linaria/core";
import { useSwipeable } from "react-swipeable";
import { Dialog, DialogBackdrop, DialogDisclosure, DisclosureProps, Portal, useDialogState } from "reakit";
import { cloneElement, createContext, type FC, type FunctionComponentElement, memo, MutableRefObject, type ReactNode, useCallback, useEffect, useRef, useState } from "react";
import * as React from "react";
import { useWindowSize } from "@/hooks/useWindowSize";
import { cssIsSticky } from "@/layouts/Default/Header/StyledHeader";
import { getBreakpointVal } from "@/styles/utils/Utils";
import { breakpoints } from "@/styles/utils/vars";
import { CloseModeType } from "@/types";
import { COEF, DELTAY_PERCENT_ON_CLOSE, MODAL_OPACITY_OVERLAY_DEFAULT } from "@/ui/Modal/constants";
import { scrollBodyDisable, scrollBodyEnable } from "@/utils/common/helpers";
import { cssButtonModalClose, cssDialog, cssDialogBackdrop, cssTransitionEnter, cssTransitionLeave, Header, Heading, StyledDialogContent, StyledHeaderDialogContent, StyledOverlay } from "./StyledModal";
import { Button } from "../Button/Button";
import { Typography } from "../Typography/Typography";
export type ModalDefaultPropsType = {
  variant?: "rounded-0" | "full" | "rounded-100" | "rounded-50" | "rounded-70";
  title?: string;
  disclosure?: FunctionComponentElement<unknown>;
  onClose?: () => void;
  isShowModal?: boolean;
  closeMode?: CloseModeType;
  hideOnClickOutside?: boolean;
  isModal?: boolean;
  children?: ReactNode;
  classNameBackdrop?: string;
  isStickyTitle?: boolean;
};
export const ModalContext = createContext<null | {
  hide: () => void;
  baseId: string;
  bodyRef: MutableRefObject<HTMLDivElement | null>;
}>(null);
const getPercentSwipingY = (y = 0) => Math.ceil(y * 100 / window.innerHeight);
export const Modal: FC<ModalDefaultPropsType> = memo(({
  title,
  variant = "rounded-0",
  disclosure,
  children,
  onClose,
  isShowModal,
  closeMode = "hide",
  hideOnClickOutside = false,
  classNameBackdrop,
  isStickyTitle = true
}) => {
  const dialogState = useDialogState({
    animated: true,
    modal: true,
    visible: isShowModal
  });
  const elementRef = useRef<HTMLDivElement | null>(null);
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const {
    width
  } = useWindowSize();
  const previousVisibleRef = useRef(dialogState.visible);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const isResponsive = width !== undefined && width <= getBreakpointVal(breakpoints.md);
  const lastTranslateRef = useRef<number | null>(null);

  // прозрачность оверлея в процентах, меняющаяся
  // когда свайпают диалог вниз для закрытия
  const [opacityOverlay, setOpacityOverlay] = useState<number | null>(null);
  const setTransformEl = (translate: number | null) => {
    const bodyElement = dialogRef.current;
    if (!bodyElement) {
      return;
    }
    if (translate === null) {
      setOpacityOverlay(null);
      lastTranslateRef.current = null;
      bodyElement.style.transform = "";
      return;
    }
    const opacity = (100 - getPercentSwipingY(translate)) / 100;
    setOpacityOverlay(opacity - opacity * 0.2);
    bodyElement.style.transform = `translateY(${translate}px)`;
  };
  const calcTranslateY = (deltaY = 0, coef = 0) => {
    const val = Math.ceil(deltaY) + (lastTranslateRef.current || 0);
    return val + val * (coef / 100);
  };
  const handlers = useSwipeable({
    trackMouse: false,
    trackTouch: true,
    preventScrollOnSwipe: false,
    onSwipeStart: eventData => {
      console.log("onSwipeStart ", eventData);
    },
    onSwipedDown: eventData => {
      console.log("User Swiped!", eventData);
      const {
        deltaY
      } = eventData;
      const bodyElement = dialogRef.current;
      if (!bodyElement) {
        return;
      }
      if (bodyElement.getBoundingClientRect().y > 0) {
        // на сколько процентов от экрана свайпнули диалог
        const deltaYPercent = getPercentSwipingY(bodyElement.getBoundingClientRect().y);
        lastTranslateRef.current = calcTranslateY(deltaY, COEF);
        if (deltaYPercent >= DELTAY_PERCENT_ON_CLOSE) {
          dialogState.hide();
        } else {
          setTransformEl(null);
        }
      } else {
        setTransformEl(null);
      }
    },
    onSwiping: eventData => {
      // console.log("onSwiping!", eventData)
      const {
        deltaY,
        dir
      } = eventData;
      const bodyElement = dialogRef.current;
      if (!bodyElement) {
        return;
      }
      if (bodyElement.getBoundingClientRect().y > 0) {
        if (dir === "Down") {
          setTransformEl(calcTranslateY(deltaY, COEF));
        }
      }
    }
  });
  const onScrollHandle = useCallback(() => {
    if (!isStickyTitle) {
      return;
    }
    if (dialogState.animating) {
      return;
    }
    const offsetTop = bodyRef.current?.getBoundingClientRect().top || 0;
    setIsSticky(offsetTop < 10);
  }, [dialogState.animating, isStickyTitle]);
  const [transition, setTransition] = useState<"leave" | "enter" | null>(null);
  const raf = React.useRef(0);
  const isShow = closeMode === "hide" || dialogState.visible || dialogState.animating;
  const transitionClassName = cx(transition === "enter" && cssTransitionEnter, transition === "leave" && cssTransitionLeave);

  // Двойной RAF необходим, чтобы у браузера было достаточно времени для отрисовки
  // стили по умолчанию перед обработкой атрибута `data-enter`. В противном случае
  // это не будет считаться переходом.
  useEffect(() => {
    raf.current = window.requestAnimationFrame(() => {
      raf.current = window.requestAnimationFrame(() => {
        if (dialogState.visible) {
          setTransition("enter");
        } else if (dialogState.animating) {
          setTransition("leave");
        } else {
          setTransition(null);
        }
      });
    });
    return () => window.cancelAnimationFrame(raf.current);
  }, [dialogState.animating, dialogState.visible]);
  useEffect(() => {
    if (dialogState.visible !== previousVisibleRef.current) {
      if (!dialogState.visible) {
        scrollBodyEnable();
        setTimeout(() => {
          setTransformEl(null);
        }, 50);
        !!onClose && onClose();
      } else {
        scrollBodyDisable(elementRef.current || undefined);
      }
    }
    previousVisibleRef.current = dialogState.visible;
  }, [dialogState.visible]);
  useEffect(() => {
    if (dialogState.visible || dialogState.animating) {
      const handleScroll = () => {
        requestAnimationFrame(onScrollHandle);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [dialogState.animating, dialogState.visible, onScrollHandle]);
  useEffect(() => {
    if (isShowModal !== undefined) {
      if (isShowModal) {
        dialogState.show();
      } else {
        dialogState.hide();
      }
    }
  }, [isShowModal]);
  return <ModalContext.Provider value={{
    hide: dialogState.hide,
    baseId: dialogState.baseId,
    bodyRef: bodyRef
  }}>
        {!!disclosure && <DialogDisclosure {...dialogState} ref={disclosure.ref} {...disclosure.props || {}}>
            {(disclosureProps: DisclosureProps) => cloneElement(disclosure, disclosureProps)}
          </DialogDisclosure>}
        <>
          {isShow && <>
              <Portal>
                <StyledOverlay className={transitionClassName} style={opacityOverlay !== null ? {
            backgroundColor: `hsl(0deg 0% 0% / ${opacityOverlay > MODAL_OPACITY_OVERLAY_DEFAULT ? MODAL_OPACITY_OVERLAY_DEFAULT : opacityOverlay})`
          } : undefined} />
              </Portal>
              <DialogBackdrop {...dialogState} data-variant={variant} role="alertdialog" className={cx(cssDialogBackdrop, isSticky && cssIsSticky, transitionClassName, classNameBackdrop)} {...handlers} onScroll={onScrollHandle}>
                <Dialog {...dialogState} preventBodyScroll={false} aria-label="dialog" tabIndex={0} unstable_finalFocusRef={elementRef} unstable_autoFocusOnShow={false} unstable_autoFocusOnHide={false} hideOnClickOutside={hideOnClickOutside} className={cssDialog} ref={dialogRef}>
                  <StyledDialogContent ref={bodyRef}>
                    {isResponsive && <StyledHeaderDialogContent ref={headerRef} style={{
                width: `${width - 30}px`
              }}></StyledHeaderDialogContent>}
                    <Header>
                      {title && <Heading>
                          <Typography variant={"h3"}>{title}</Typography>
                        </Heading>}
                      <Button variant={"box"} icon={"X"} className={cssButtonModalClose} onClick={dialogState.hide} />
                    </Header>
                    {children}
                  </StyledDialogContent>
                </Dialog>
              </DialogBackdrop>
            </>}
        </>
      </ModalContext.Provider>;
});
Modal.displayName = "Modal";