import type { BaseHTMLAttributes, FC } from "react"
import { StyledPushCounter } from "@/components/PushCounter/StyledPushCounter"
import { LIMIT_PUSH_COUNTER_DEFAULT } from "@/utils/constants"

const PushCounter: FC<
  BaseHTMLAttributes<HTMLSpanElement> & {
    qty: number | null
  }
> = ({ qty, children, ...props }) => {
  if (!(!!qty && qty > 0)) {
    return null
  }
  return (
    <StyledPushCounter {...props}>
      {qty > LIMIT_PUSH_COUNTER_DEFAULT
        ? `${LIMIT_PUSH_COUNTER_DEFAULT}+`
        : qty}
      {children}
    </StyledPushCounter>
  )
}

export default PushCounter
