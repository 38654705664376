export const CODE_PHONE = "+7"

export const FORMAT_PHONE = `${CODE_PHONE} (###) ### ##-##`

export const RULE_REQUIRED = {
  required: {
    value: true,
    message: "Поле обязательно для заполнения",
  },
}

export const FULL_PURE_PHONE_LENGTH = 10
export const RULE_VALIDATE = {
  validate: (phone?: string): string | boolean => {
    return !!(phone || "") && (phone || "").length !== FULL_PURE_PHONE_LENGTH
      ? "Неверный формат номера"
      : true
  },
}

export const RULES_PHONE_VALIDATE = {
  ...RULE_REQUIRED,
  ...RULE_VALIDATE,
}

export const getPhoneWithCode = (phone?: string): string =>
  !phone
    ? ""
    : phone.includes(CODE_PHONE)
    ? `${phone}`
    : `${CODE_PHONE}${phone}`

export const getPhoneWithOutCode = (phone?: string): string | undefined =>
  !!phone ? phone.toString().replace(CODE_PHONE, "") : phone
