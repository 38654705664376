import { OrderType, ProductDetailListType } from "../../../contracts"
import { ProductWithChildType } from "@/components/Products/types"
import { ORDER_COLLAPSED_LOCAL_STORAGE_KEY } from "@/hooks/order/constants"
import { SpecificationItemType } from "@/types"
import { ROUTES } from "@/utils/constants"
import {
  compareSpecificationProducts,
  cookies,
  getExpiresByHour,
} from "@/utils/common/helpers"

export const createSpecificationOrder = (
  payload: OrderType["products"] | null,
) => {
  if (!payload) {
    return null
  }

  const products = [...payload]

  const specification: Record<string, SpecificationItemType> = {}

  products
    .sort((a, b) => {
      if (!a.parent) return -1
      if (!b.parent) return 1
      return 0
    })
    .forEach((p) => {
      const { parent = null, uuid, quantity } = p
      if (!!uuid) {
        // распределение обычных товаров
        // и товаров, являющихся дочерними комплекта
        if (!!quantity && !!parent) {
          if (!!specification[parent] && !!specification[parent]["child"]) {
            specification[parent]["child"] = {
              ...specification[parent]["child"],
              ...{ [uuid]: p },
            }
          }
        } else {
          specification[uuid] = { ...p, parent: parent ?? null }
        }
      }
    })

  return specification
}

export const createProductsSamplesOrder = (
  products: ProductDetailListType | null,
  orderSpecification: Record<string, SpecificationItemType> | null,
): {
  products: Record<string, ProductWithChildType> | null
  samples: Record<string, ProductWithChildType> | null
} => {
  let compared = null
  if (products !== null) {
    compared = {}
    for (const item of products) {
      compared[item.uuid || ""] = { ...item }
    }
  }
  return compareSpecificationProducts(orderSpecification, compared)
}

export const getOrderHistoryPath = (uid: string) =>
  `${ROUTES.account}${ROUTES.historyAccount}/${uid}`

export const getExpiresLastOrderCollapsed = () => getExpiresByHour(1)

export const setLastOrderCollapsedToStorage = (uuid: string | null) => {
  if (uuid === null) {
    cookies.remove(ORDER_COLLAPSED_LOCAL_STORAGE_KEY)
  } else {
    cookies.set(ORDER_COLLAPSED_LOCAL_STORAGE_KEY, JSON.stringify(uuid), {
      path: "/",
      expires: getExpiresLastOrderCollapsed(),
    })
  }
}

export const getLastOrderCollapsedFromStorage = (): string | null => {
  const data = cookies.get(ORDER_COLLAPSED_LOCAL_STORAGE_KEY)
  return !!data ? (data as string) : null
}

export const getAllowedTimePayment = ({
  createAt,
  endDate,
}: {
  createAt: string
  // лимит времени в течении которого можно оплатить заказ
  // в милисекундах
  endDate: number
}) => {
  // если заказ был создан максимум 10мин назад
  const pastTime = new Date().getTime() - new Date(createAt).getTime()
  return pastTime > 0 && pastTime < endDate
}
