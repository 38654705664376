import { cookies } from "@/utils/common/helpers"

const getTokenLH = () => cookies.get("_lhtm_u")

export const fetchMain = () => {
  const url = buildLeadhitURL({
    clid: `${process.env.NEXT_PUBLIC_LEADHIT_ID}`,
    leadUid: getTokenLH(),
    serviceName: "top_offers",
  })

  return getLeadhit<{ offer_id: string }[]>(url.toString())
}

export const fetchCart = () => {
  const url = buildLeadhitURL({
    clid: `${process.env.NEXT_PUBLIC_LEADHIT_ID}`,
    leadUid: getTokenLH(),
    serviceName: "cart_upsale",
  })

  return getLeadhit<{ offer_id: string }[]>(url.toString())
}

export const fetchHistoryOrders = () => {
  const url = buildLeadhitURL({
    clid: `${process.env.NEXT_PUBLIC_LEADHIT_ID}`,
    leadUid: getTokenLH(),
    serviceName: "top_offers",
    source: "view",
  })

  return getLeadhit<{ offer_id: string }[]>(url.toString())
}

export const fetchNotFound = () => {
  const url = buildLeadhitURL({
    clid: `${process.env.NEXT_PUBLIC_LEADHIT_ID}`,
    leadUid: getTokenLH(),
    serviceName: "top_offers",
  })

  return getLeadhit<{ offer_id: string }[]>(url.toString())
}

export const fetchDetailProduct = ({ urlList }: { urlList: string[] }) => {
  const url = buildLeadhitURL({
    clid: `${process.env.NEXT_PUBLIC_LEADHIT_ID}`,
    leadUid: getTokenLH(),
    serviceName: "main_service",
    source: "request",
    urlList,
  })

  return getLeadhit<{ offer_id: string }[]>(url.toString())
}

export const buildLeadhitURL = ({
  clid,
  serviceName,
  leadUid,
  source,
  offerUrl,
  urlList,
  searchQuery,
  leadHistoryDaysCount,
  categoryId,
  stockId,
}: {
  clid?: string
  serviceName?: "top_offers" | "main_service" | "cart_upsale"
  leadUid?: string
  source?: "cart" | "view" | "request"
  offerUrl?: string
  urlList?: string[] | URL[]
  searchQuery?: string
  leadHistoryDaysCount?: number
  categoryId?: string
  stockId?: string
}) => {
  const url = new URL("/get", "https://rs.leadhit.io")

  if (!!clid) {
    url.searchParams.set("clid", clid)
  }

  if (!!serviceName) {
    url.searchParams.set("service_name", serviceName)
  }

  if (!!leadUid) {
    url.searchParams.set("lead_uid", leadUid)
  }

  if (!!source) {
    url.searchParams.set("source", source)
  }

  if (!!offerUrl) {
    url.searchParams.set("offer_url", offerUrl)
  }

  if (!!urlList && urlList.length > 0) {
    for (const u of urlList) {
      url.searchParams.append("url_list", `${u}`)
    }
  }

  if (!!searchQuery) {
    url.searchParams.set("search_query", searchQuery)
  }

  if (!!leadHistoryDaysCount) {
    url.searchParams.set("lead_history_days_count", `${leadHistoryDaysCount}`)
  }

  if (!!categoryId) {
    url.searchParams.set("category_id", categoryId)
  }

  if (!!stockId) {
    url.searchParams.set("stock_id", stockId)
  }

  return url
}

export const getLeadhit = <R>(url: string) =>
  fetchLeadhit<R>(url, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })

export const fetchLeadhit = <R>(url: string, options: RequestInit) =>
  fetch(url, options).then((resp) => resp.json() as Promise<R>)
