import {
  BUSINESS_AREA_VALUE_DEFAULT,
  CHECKOUT_SAVED_LOCAL_STORAGE_KEY,
} from "@/hooks/checkout/constants"
import { LocalStorageCheckoutType } from "@/utils/common/helpers"

export const setCheckoutToStorage = (
  data: Omit<
    LocalStorageCheckoutType,
    "shipping_date" | "comment" | "dataPrivacyAgreement"
  > | null,
): void => {
  if (typeof localStorage === "undefined" || !localStorage) {
    return
  }
  if (data === null) {
    localStorage.removeItem(CHECKOUT_SAVED_LOCAL_STORAGE_KEY)
  } else {
    localStorage.setItem(CHECKOUT_SAVED_LOCAL_STORAGE_KEY, JSON.stringify(data))
  }
}
export const getCheckoutFromStorage = (): LocalStorageCheckoutType | null => {
  if (typeof localStorage === "undefined" || !localStorage) {
    return null
  }
  const data = localStorage.getItem(CHECKOUT_SAVED_LOCAL_STORAGE_KEY)
  return !!data ? (JSON.parse(data) as LocalStorageCheckoutType) : null
}

export const getIsBusinessAreaCustom = (bussiness_area?: string) =>
  bussiness_area === BUSINESS_AREA_VALUE_DEFAULT
