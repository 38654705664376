import { FavoritesResponseType } from "@/api/favoritesAPI"
import { FAVORITES_LOCAL_STORAGE_KEY } from "@/hooks/favorites/constants"
import { INITIAL_SORT } from "@/store/reducers/favoritesSlice"
import { QueryFavoritesStringType } from "@/types"
import { getUnique } from "@/utils/common/helpers"

export const getFavoritesStorage = (): string[] | null => {
  const favoritesKeys = localStorage.getItem(FAVORITES_LOCAL_STORAGE_KEY)
  return !!favoritesKeys ? JSON.parse(favoritesKeys) : null
}
export const setFavoritesStorage = (favoritesKeys: string[] | null) => {
  if (favoritesKeys === null) {
    return
  }

  if (favoritesKeys.length < 1) {
    localStorage.removeItem(FAVORITES_LOCAL_STORAGE_KEY)
    return
  }

  localStorage.setItem(
    FAVORITES_LOCAL_STORAGE_KEY,
    JSON.stringify(favoritesKeys),
  )
}

export const normalizeSearchParamsFavorites = ({
  query: { params = "", sortby = INITIAL_SORT, price = "", group = "" },
}: {
  query: QueryFavoritesStringType
}): Required<QueryFavoritesStringType> => ({
  params,
  sortby,
  price,
  group,
})

export function getFavoritesGeneralProductsFormatted(
  this:
    | {
        initialGroups?: Record<string, string[]>
        initialKeys?: string[]
      }
    | undefined,
  { products }: Pick<FavoritesResponseType, "products">,
) {
  const groupsProductsIds: Record<string, string[]> = this?.initialGroups || {}
  let idsAllProducts: string[] = this?.initialKeys || []
  for (const { products: uuidProducts, group } of products) {
    idsAllProducts = [...idsAllProducts, ...uuidProducts]
    groupsProductsIds[group] = uuidProducts
  }

  return {
    keys: getUnique(idsAllProducts),
    groupsProductsIds: groupsProductsIds,
  }
}

export const getDefaultFromGroups = (
  groups?:
    | {
        name: string
        uid: string
        quantity: number
      }[]
    | null,
) => (!!groups ? groups.find(({ name }) => name === "Без названия") : undefined)
